import {
  AddCircleOutlineTwoTone,
  ArrowDropDown,
  ErrorTwoTone,
  EventTwoTone,
  LocationOnTwoTone,
  PersonTwoTone,
  ReplayTwoTone,
} from '@mui/icons-material';
import {
  Alert,
  FormLabel,
  IconButton,
  ListSubheader,
  MenuItem,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import zip from 'lodash/zip';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';

import { AllPaymentMethodsExhaustedRetryCycleMetadata } from '@headway/api/models/AllPaymentMethodsExhaustedRetryCycleMetadata';
import { BillingType } from '@headway/api/models/BillingType';
import { GetMatchingProviderFrontEndCarrierRequest } from '@headway/api/models/GetMatchingProviderFrontEndCarrierRequest';
import { InsuranceReadinessIssueHasNoTelehealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueHasNoTelehealthBenefits';
import { InsuranceReadinessIssueOutOfNetworkType } from '@headway/api/models/InsuranceReadinessIssueOutOfNetwork';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType } from '@headway/api/models/PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle';
import { PaymentMethodReadinessIssuePreAuthChargeFailedType } from '@headway/api/models/PaymentMethodReadinessIssuePreAuthChargeFailed';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { StateInsuranceCarrierRead } from '@headway/api/models/StateInsuranceCarrierRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';
import { RecurringUpdateApplyTo } from '@headway/shared/events/constants';
import {
  FAILED_PAYMENTS_M1,
  MAX_SESSIONS_EXPERIENCE,
  MULTI_STATE_CREDENTIALING_BETA,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierBatchQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierBatchQuery';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useProviderUserFreezes } from '@headway/shared/hooks/useProviderUserFreezes';
import { useUserAppointmentReadiness } from '@headway/shared/hooks/useUserAppointmentReadiness';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import {
  hasApproachingRemainingSessions,
  hasNoRemainingSessionsUnreadiness,
  hasRemainingSessions,
} from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { getSelfPaynesses } from '@headway/shared/utils/selfPay';
import { Radio, Tooltip } from '@headway/ui';
import { Button } from '@headway/ui/Button';
import {
  FieldAutocomplete,
  FieldControl,
  FieldDatePicker,
  FieldErrorText,
  FieldInputLabel,
  FieldRadioGroup,
  FieldSelect,
  FormIconRow,
} from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';
import { theme } from '@headway/ui/theme';

import { useFindProviderEvents } from 'hooks/useFindProviderEvents';
import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddress } from 'hooks/usePatientAddress';
import { usePatientAddresses } from 'hooks/usePatientAddresses';
import { useProviderPrice } from 'hooks/useProviderPrice';
import { PatientsContext } from 'providers/PatientsProvider';
import { getFreezeMessage, isEveryFreezeOON } from 'utils/freeze';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';
import { shouldShowMSCGuidanceCards } from 'utils/msc';
import { InsuranceIneligibilityExplainerModal } from 'views/Clients/InsuranceIneligibilityExplainerModal';
import { WaivedSessionsRemainingGuidanceCard } from 'views/Clients/WaivedSessionsRemainingGuidanceCard';
import { AddPatientModal as LegacyAddPatientModal } from 'views/Patients/AddPatientModal';
import {
  isProviderNotCredentialedInAnyPatientState,
  isProviderNotCredentialedInSpecificAddress,
} from 'views/Patients/utils/patientInsuranceStatus';

import PatientAddressDropdown from '../components/AppointmentConfirmation/Forms/FormComponents/PatientAddressDropdown';
import { NotCredentialedInAnyPatientStateWarning } from '../components/AppointmentDetail/AppointmentDetailGuidanceCards/NotCredentialedInAnyPatientStateWarning';
import { NotCredentialedInStateError } from '../components/AppointmentDetail/AppointmentDetailGuidanceCards/NotCredentialedInStateError';
import { FirstPaymentEligibleGuidanceCard } from '../components/FirstPaymentEligibleGuidanceCard';
import {
  dateToUSFormat,
  getDefaultProviderAddressIdForEvent,
  getMinScheduleDate,
  getRecurrenceString,
} from '../events/util/events';
import {
  EstimatedLiveDatesWithCarrierMap,
  EstimatedLiveDateWithCarrier,
} from '../LiveDateCalculator';
import { CalendarSlot } from '../utils/Calendar';
import { RecurrenceType } from '../utils/constants';
import {
  getDayOccurrenceOfMonth,
  getDayOccurrenceOfMonthString,
} from '../utils/momentUtils';
import { getCalendarRangeQueryKeyArgs } from '../utils/queries';
import { FieldDurationSelect } from './FieldDurationSelect';
import { FieldFiveMinuteIntervalSelect } from './FieldFiveMinuteIntervalSelect';

function patientAndMinDateToAutocompleteOption(
  patient: UserRead,
  minDate?: Date
) {
  return {
    display: formatPatientName(patient, {
      appendPronouns: true,
    }),
    group: minDate ? '' : 'Clients not able to be scheduled',
    ...patient,
  };
}

// for new patients, we need to fetch matchingProviderFrontEndCarrier because they won't be in the
// patientsMatchingProviderFrontEndCarriers map
async function newPatientToAutocompleteOption(
  patient: UserRead,
  provider: ProviderRead,
  stateInsuranceCarriers: StateInsuranceCarrierRead[],
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap,
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier
) {
  const matchingProviderFrontEndCarrier =
    await ProviderApi.getMatchingProviderFrontEndCarrier(provider.id, {
      patient_user_id: patient.id,
    });
  const minDate = getMinScheduleDate(
    ProviderEventType.APPOINTMENT,
    patient,
    provider,
    stateInsuranceCarriers,
    estimatedLiveDates,
    minEstimatedLiveDate,
    matchingProviderFrontEndCarrier
  );
  return patientAndMinDateToAutocompleteOption(patient, minDate);
}

const renderGroup = (params: any) => [
  <Tooltip title="Clients are not able to be scheduled if they have an insurance that you do not have a live or estimated live date for.">
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>
  </Tooltip>,
  params.children,
];

const appointmentScheduleSchema = (minDate?: Date) => {
  return Yup.object().shape({
    providerAddressId: Yup.string().required('Session location is required.'),
    patient: Yup.object()
      .nullable()
      .test(
        'patient-required',
        'Client is required.',
        (value) => !!(value as UserRead)?.id
      ),
    startDate: minDate
      ? Yup.date().required().min(minDate)
      : Yup.date().required(),
  });
};

export interface AppointmentScheduleFormSubmitValues {
  providerAddressId: number | null;
  appointmentLocationPatientAddressId?: number | null;
  patientUserId?: number;
  recurrence?: string;
  recurringUpdateApplyTo: RecurringUpdateApplyTo;
  startDate: string;
  endDate: string;
  telehealth: boolean;
  timeZone: string;
  type: ProviderEventType;
}

const intakeCallScheduleSchema = Yup.object().shape({
  patient: Yup.object()
    .nullable()
    .test(
      'patient-required',
      'Client is required.',
      (value) => !!(value as UserRead)?.id
    ),
});

interface IneligbilityBannerProps {
  patient: UserRead;
  provider: ProviderRead;
  isIneligibilityExplainerModalOpen: boolean;
  ineligibilityBannerOnClick: () => void;
  ineligibilityExplainerModalOnClose: () => void;
  isTelehealthAppointment: boolean;
  userAppointmentReadiness?: UserAppointmentReadiness;
}

const IneligibilityBanner: React.FC<IneligbilityBannerProps> = ({
  patient,
  provider,
  isIneligibilityExplainerModalOpen,
  ineligibilityBannerOnClick,
  ineligibilityExplainerModalOnClose,
  isTelehealthAppointment,
  userAppointmentReadiness,
}) => {
  const maxSessionsExperience = useFlag(MAX_SESSIONS_EXPERIENCE, false);
  const patientIssues = userAppointmentReadiness?.insurance || [];
  const isOON = patientIssues.find(
    (issue) =>
      issue.type === InsuranceReadinessIssueOutOfNetworkType.OUT_OF_NETWORK
  );
  const hasNoTelehealthBenefits = patientIssues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueHasNoTelehealthBenefitsType.HAS_NO_TELEHEALTH_BENEFITS
  );

  const hasNoRemainingSessions = hasNoRemainingSessionsUnreadiness(
    userAppointmentReadiness
  );

  const hasSessions = hasRemainingSessions(userAppointmentReadiness);

  const approachingSessionLimit = hasApproachingRemainingSessions(
    userAppointmentReadiness
  );

  return (
    <React.Fragment>
      {isOON ? (
        <>
          <div className={'pb-3'}>
            <GuidanceCard layout={'vertical'} variant="error">
              <BodyText>
                {patient.firstName}'s plan is not eligible for in-network care
                on Headway.{' '}
                <Link onClick={ineligibilityBannerOnClick}>
                  See more details and what you can do.
                </Link>
              </BodyText>
            </GuidanceCard>
          </div>
          <InsuranceIneligibilityExplainerModal
            client={patient}
            provider={provider}
            open={isIneligibilityExplainerModalOpen}
            onClose={ineligibilityExplainerModalOnClose}
            issues={userAppointmentReadiness?.insurance || []}
          />
        </>
      ) : null}
      {hasNoTelehealthBenefits && isTelehealthAppointment ? (
        <>
          <div className={'pb-3'}>
            <GuidanceCard layout={'vertical'} variant="error">
              <BodyText>
                {patient.firstName}'s plan doesn’t cover telehealth sessions.
                Consider switching to an in-person session, or move this client
                to private pay to continue with telehealth.
              </BodyText>
              <Link onClick={ineligibilityBannerOnClick}>See more</Link>
            </GuidanceCard>
          </div>
          <InsuranceIneligibilityExplainerModal
            client={patient}
            provider={provider}
            open={isIneligibilityExplainerModalOpen}
            onClose={ineligibilityExplainerModalOnClose}
            issues={userAppointmentReadiness?.insurance || []}
          />
        </>
      ) : null}
      {hasNoRemainingSessions && maxSessionsExperience ? (
        <>
          <div className={'pb-3'}>
            <GuidanceCard layout={'vertical'} variant="error">
              <BodyText>
                Your client has no remaining sessions for this plan year. If
                they'd like to attend more, ask them if they'd consider using
                private pay.
              </BodyText>
            </GuidanceCard>
          </div>
          <InsuranceIneligibilityExplainerModal
            client={patient}
            provider={provider}
            open={isIneligibilityExplainerModalOpen}
            onClose={ineligibilityExplainerModalOnClose}
            issues={userAppointmentReadiness?.insurance || []}
          />
        </>
      ) : null}
      {approachingSessionLimit && maxSessionsExperience ? (
        <>
          <div className={'pb-3'}>
            <GuidanceCard layout={'vertical'} variant="warning">
              <BodyText>
                Your client only has a few sessions remaining covered by their
                plan. Once these run out, they can keep attending sessions in
                this plan year if they're open to private pay.
              </BodyText>
            </GuidanceCard>
          </div>
          <InsuranceIneligibilityExplainerModal
            client={patient}
            provider={provider}
            open={isIneligibilityExplainerModalOpen}
            onClose={ineligibilityExplainerModalOnClose}
            issues={userAppointmentReadiness?.insurance || []}
          />
        </>
      ) : null}
      {hasSessions && maxSessionsExperience ? (
        <>
          <div className={'pb-3'}>
            <GuidanceCard layout={'vertical'} variant="warning">
              <BodyText>
                Your client has about{' '}
                {
                  patient.activeUserInsurance?.latestEligibilityLookup
                    ?.remainingCoveredSessions
                }{' '}
                sessions remaining for this plan year. If they'd like to attend
                more sessions in a single year, ask them if they'd consider
                using private pay once they exceed the maximum.
              </BodyText>
            </GuidanceCard>
          </div>
          <InsuranceIneligibilityExplainerModal
            client={patient}
            provider={provider}
            open={isIneligibilityExplainerModalOpen}
            onClose={ineligibilityExplainerModalOnClose}
            issues={userAppointmentReadiness?.insurance || []}
          />
        </>
      ) : null}
    </React.Fragment>
  );
};

export interface AppointmentScheduleFormProps {
  event: CalendarSlot | ProviderEventRead;
  provider: ProviderRead;
  timeZone: string;
  submitLabel: string;
  isRescheduling: boolean;
  onSubmit: (val: AppointmentScheduleFormSubmitValues) => Promise<void>;
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier;
  maxEstimatedLiveDate: EstimatedLiveDateWithCarrier;
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap;
  stateInsuranceCarriers: StateInsuranceCarrierRead[];
  eventIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  >;
}

export const AppointmentScheduleForm: React.FC<
  React.PropsWithChildren<AppointmentScheduleFormProps>
> = ({
  event,
  provider,
  timeZone,
  isRescheduling,
  onSubmit,
  minEstimatedLiveDate,
  estimatedLiveDates,
  stateInsuranceCarriers,
  eventIdsToMatchingProviderFrontEndCarriers,
}) => {
  const { patients, patientsById, reload } = React.useContext(PatientsContext);
  const { providerAddresses } = React.useContext(ProviderAddressContext);
  const [isPatientModalOpen, setPatientModalOpen] = React.useState(false);
  const [
    isIneligibilityExplainerModalOpen,
    setIsIneligibilityExplainerModalOpen,
  ] = React.useState(false);

  const isIroncladBlockAppointmentConfirmation = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );

  const { freezeReasonsByUser } = useProviderUserFreezes(provider.id);

  const isIntakeCall = event.type === ProviderEventType.INTAKE_CALL;
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const {
    isMSCGuardrailWarning,
    isMSCGuardrailRestriction,
    restrictionDate: mscGuardrailRestrictionDate,
  } = useMSCGuardrail();
  const showMSCGuidanceCards = shouldShowMSCGuidanceCards(
    isMSCEnabled,
    isMSCGuardrailWarning,
    isMSCGuardrailRestriction
  );
  const isFailedPaymentsM1Enabled = useFlag(FAILED_PAYMENTS_M1, false);

  const visiblePatients = useMemo(() => {
    return patients?.filter(
      (patient) =>
        !patient.providerPatients?.find(
          (providerPatient) => providerPatient.providerId === provider.id
        )?.hidden
    );
  }, [patients, provider]);

  const { data: patientsMatchingProviderFrontEndCarriers } =
    useMatchingProviderFrontEndCarrierBatchQuery(
      {
        providerId: provider.id,
        patientRequests: visiblePatients
          ? visiblePatients.map((p) => ({
              patientUserId: p.id,
              appointmentId:
                event.patientUserId === p.id && 'providerAppointment' in event
                  ? event.providerAppointment?.id
                  : undefined,
              useAllProviderStatesForScheduling: true,
            }))
          : ([] as GetMatchingProviderFrontEndCarrierRequest[]),
      },
      // if we're rescheduling, we already have the data we need in eventIdsToMatchingProviderFrontEndCarriers
      { enabled: !isRescheduling }
    );
  let patientIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  >;
  if (isRescheduling) {
    const matchingPFEC =
      'id' in event && event.id
        ? eventIdsToMatchingProviderFrontEndCarriers.get(event.id)
        : undefined;
    patientIdsToMatchingProviderFrontEndCarriers = event?.patientUserId
      ? new Map([[event.patientUserId, matchingPFEC]])
      : new Map();
  } else {
    patientIdsToMatchingProviderFrontEndCarriers =
      visiblePatients && patientsMatchingProviderFrontEndCarriers
        ? new Map(
            zip(
              visiblePatients.map((p) => p.id),
              patientsMatchingProviderFrontEndCarriers
            )
          )
        : new Map();
  }

  const getMinScheduleDateForPatient = (
    patient: UserRead | undefined,
    matchingProviderFrontEndCarrier?: ProviderFrontEndCarrierRead | null
  ): Date | undefined => {
    return getMinScheduleDate(
      ProviderEventType.APPOINTMENT,
      patient,
      provider,
      stateInsuranceCarriers,
      estimatedLiveDates,
      minEstimatedLiveDate,
      matchingProviderFrontEndCarrier !== undefined
        ? matchingProviderFrontEndCarrier
        : patient
        ? patientIdsToMatchingProviderFrontEndCarriers.get(patient.id) ?? null
        : null
    );
  };

  // need to define this up here but set it after useFormik because it's used in validationSchema but the
  // value that's set depends on formik values
  let minLiveDateForAppointment: Date | undefined = undefined;

  const validationSchema = isIntakeCall
    ? intakeCallScheduleSchema
    : Yup.lazy(() => {
        // use the calculated min live date for the proposed appointment, which implicitly uses the
        // matching PFEC for the appointment, taking into account telehealth and address
        return appointmentScheduleSchema(minLiveDateForAppointment);
      });

  const initialPatient =
    patientsById && event?.patientUserId
      ? patientsById[event?.patientUserId]
      : undefined;

  const initialRecurrence =
    (event as ProviderEventRead).recurrence ?? undefined;

  const initialPatientAddressId =
    (event as ProviderEventRead).providerAppointment
      ?.appointmentLocationPatientAddressId ?? undefined;

  const initialValues: Yup.InferType<typeof validationSchema> = {
    ...event,
    type: event.type,
    patient: initialPatient
      ? patientAndMinDateToAutocompleteOption(
          initialPatient,
          getMinScheduleDateForPatient(initialPatient)
        )
      : undefined,
    providerAddressId: event
      ? getDefaultProviderAddressIdForEvent(
          event as unknown as ProviderEventRead,
          providerAddresses
        )
      : null,
    appointmentLocationPatientAddressId: initialPatientAddressId || null,
    recurrence: initialRecurrence ?? RecurrenceType.DOES_NOT_REPEAT,
    recurringUpdateApplyTo: RecurringUpdateApplyTo.THIS_EVENT,
    startDate: moment(event.startDate!).toISOString(),
    startTime: moment(event.startDate!).toISOString(),
    endDate: moment(event.endDate!).toISOString(),
  };

  const selfPaynessesByUser = getSelfPaynesses(
    Object.values(patientsById || {}),
    provider.id
  );
  const frozenUserIds = new Set(
    Object.keys(freezeReasonsByUser)
      .filter((userId) => {
        const freezeReasons = freezeReasonsByUser[userId];
        const isSelfPay = selfPaynessesByUser[userId];
        return (
          freezeReasons !== undefined &&
          freezeReasons.length > 0 &&
          !(isSelfPay && isEveryFreezeOON(freezeReasons))
        );
      })
      .map(Number)
  );

  const getCombinedStartAndEndDate = (
    startDate: string,
    endDate: string,
    startTime: string
  ) => {
    const duration = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .asMinutes();
    const combinedStartDate = new Date(startDate);
    combinedStartDate.setHours(new Date(startTime).getHours());
    combinedStartDate.setMinutes(new Date(startTime).getMinutes());

    return {
      combinedStartDate: moment(combinedStartDate),
      combinedEndDate: moment(combinedStartDate).add(duration, 'minutes'),
    };
  };

  const formik = useFormik<typeof initialValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: (rawValues) => {
      const { combinedStartDate, combinedEndDate } = getCombinedStartAndEndDate(
        rawValues.startDate,
        rawValues.endDate,
        rawValues.startTime
      );

      const values = {
        // convert the form representation of address and telehealth
        ...(rawValues.providerAddressId === -1
          ? {
              providerAddressId: null,
              appointmentLocationPatientAddressId:
                rawValues.appointmentLocationPatientAddressId !== -1
                  ? rawValues.appointmentLocationPatientAddressId
                  : null,
              telehealth: true,
            }
          : {
              providerAddressId: rawValues.providerAddressId,
              appointmentLocationPatientAddressId: null,
              telehealth: false,
            }),
        type: rawValues.type,
        timeZone: timeZone,
        startDate: combinedStartDate.toISOString(),
        endDate: combinedEndDate.toISOString(),
        recurrence:
          isRescheduling && initialRecurrence
            ? initialRecurrence
            : getRecurrenceString(
                (rawValues.recurrence as RecurrenceType) ??
                  RecurrenceType.DOES_NOT_REPEAT,
                combinedStartDate.toISOString(),
                timeZone
              ),
        patientUserId: rawValues.patient?.id,
        recurringUpdateApplyTo: rawValues.recurringUpdateApplyTo,
      } as AppointmentScheduleFormSubmitValues;
      if (!isRescheduling) {
        trackEvent({
          name: 'Add Session Button Clicked',
          properties: {
            providerId: provider.id,
            patientUserId: rawValues.patient?.id,
          },
        });
      }
      onSubmit(values);
    },
  });
  const { values, setFieldValue, isSubmitting, errors, validateForm } = formik;
  const isTelehealthAppointment = values.providerAddressId === -1;

  // Get selected patient address if exists
  const { data: patientAddress } = usePatientAddress(
    {
      addressId: values.appointmentLocationPatientAddressId,
    },
    {
      enabled: values.appointmentLocationPatientAddressId !== -1,
    }
  );

  // Get selected in person address if exists
  const inPersonApptAddressState = providerAddresses.find(
    (address) => address.id === values.providerAddressId
  )?.state;

  const appointmentState = isTelehealthAppointment
    ? patientAddress?.state
    : inPersonApptAddressState
    ? (abbreviationToStateEnum[inPersonApptAddressState] as UnitedStates)
    : undefined;

  const { insuranceStatus, isLoading: isInsuranceStatusLoading } =
    useInsuranceStatus(
      values.patient,
      values.patient?.activeUserInsurance,
      isTelehealthAppointment,
      appointmentState
    );
  const insuranceStatusWithCheckAppointmentStateResult = useInsuranceStatus(
    values.patient,
    values.patient?.activeUserInsurance,
    isTelehealthAppointment,
    appointmentState,
    true
  );
  const insuranceStatusWithCheckAppointmentState =
    isMSCGuardrailWarning &&
    !isInsuranceStatusLoading &&
    insuranceStatus == PatientInsuranceOrEAPStatus.IN_NETWORK
      ? insuranceStatusWithCheckAppointmentStateResult.insuranceStatus
      : undefined;

  const { data: userAppointmentReadiness } = useUserAppointmentReadiness({
    userId: values.patient?.id,
    providerId: provider.id,
  });
  const paymentMethodIssues = userAppointmentReadiness?.paymentMethod || [];
  const patientPaymentMethodsAreNotVerified = paymentMethodIssues.find(
    (issue) =>
      issue.type ===
      PaymentMethodReadinessIssuePreAuthChargeFailedType.PRE_AUTH_CHARGE_FAILED
  );
  const allPatientPaymentMethodsExhaustedRetryCycle = paymentMethodIssues.find(
    (issue) =>
      issue.type ===
      PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE
  );

  const sessionConfirmationDateOfAppointmentInTerminalFailure = (
    allPatientPaymentMethodsExhaustedRetryCycle?.metadata as AllPaymentMethodsExhaustedRetryCycleMetadata
  )?.sessionConfirmationDate;

  const shouldShowNoVerifiedPaymentMethodsGuidanceCard =
    patientPaymentMethodsAreNotVerified && isFailedPaymentsM1Enabled;
  const shouldShowPatientHasPreviouslyUnpaidSessionsGuidanceCard =
    allPatientPaymentMethodsExhaustedRetryCycle &&
    sessionConfirmationDateOfAppointmentInTerminalFailure &&
    isFailedPaymentsM1Enabled;

  const {
    // this is more accurate than the one from patientIdsToMatchingProviderFrontEndCarriers because it uses
    // state and telehealth info. e.g. can be null (out of network) if a wrong state is selected
    data: matchingProviderFrontEndCarrierForAppointment,
    isFetching: isFetchingMatchingProviderFrontEndCarrierForAppointment,
  } = useMatchingProviderFrontEndCarrierQuery(
    {
      providerId: provider.id,
      patientUserId: values.patient?.id,
      isTelehealthAppointment,
      appointmentState,
    },
    { enabled: !!values.patient?.id }
  );

  // we need to re-validate after re-fetching matchingProviderFrontEndCarrierForAppointment because min schedule date
  // in validation depends on it
  useEffect(() => {
    if (!isFetchingMatchingProviderFrontEndCarrierForAppointment) {
      validateForm();
    }
  }, [isFetchingMatchingProviderFrontEndCarrierForAppointment]);

  // Get all patient addresses
  const { data: patientAddresses } = usePatientAddresses({
    patientId: values.patient?.id,
  });

  minLiveDateForAppointment = getMinScheduleDateForPatient(
    values.patient,
    matchingProviderFrontEndCarrierForAppointment
  );

  const isNotCredentialedInAnyPatientState =
    isProviderNotCredentialedInAnyPatientState(
      insuranceStatus,
      patientAddresses,
      isTelehealthAppointment,
      appointmentState,
      showMSCGuidanceCards
    ) ||
    isProviderNotCredentialedInAnyPatientState(
      insuranceStatusWithCheckAppointmentState,
      patientAddresses,
      isTelehealthAppointment,
      appointmentState,
      showMSCGuidanceCards
    );
  const isNotCredentialedInSelectedAddressWithoutForcedCheckApptState =
    isProviderNotCredentialedInSpecificAddress(
      insuranceStatus,
      isTelehealthAppointment,
      appointmentState
    );
  const isNotCredentialedInSelectedAddressWithForcedCheckApptState =
    isProviderNotCredentialedInSpecificAddress(
      insuranceStatusWithCheckAppointmentState,
      isTelehealthAppointment,
      appointmentState
    );
  const isPendingCredentialingWithNoDateForSelectedAddress =
    !!appointmentState &&
    insuranceStatus ===
      PatientInsuranceOrEAPStatus.IN_NETWORK_PENDING_CREDENTIALING &&
    !minLiveDateForAppointment;

  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;

  const isInOldDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;

  const selectedPatientFirstName = formatPatientName(values.patient, {
    firstNameOnly: true,
  });

  const { data: providerPriceData, isLoading: isProviderPriceLoading } =
    useProviderPrice(
      { provider, client: values.patient },
      { enabled: isInOldDataPrelimPricing }
    );
  const presumptiveAccumulatorApplied =
    !isProviderPriceLoading &&
    (providerPriceData?.priceCalculationInfo?.presumptiveAccumulators?.length ??
      0) > 0;

  const { combinedStartDate, combinedEndDate } = getCombinedStartAndEndDate(
    values.startDate,
    values.endDate,
    values.startTime
  );
  const startOfDay = combinedStartDate.clone().startOf('day');
  const endOfDay = combinedStartDate.clone().endOf('day');

  const { data: sameDayUncancelledAppointments } = useFindProviderEvents(
    {
      ...getCalendarRangeQueryKeyArgs(
        provider.id,
        startOfDay.toDate(),
        endOfDay.toDate()
      ),
      event_types: [ProviderEventType.APPOINTMENT],
      appointment_statuses: [
        ProviderAppointmentStatus.DETAILS_CONFIRMED,
        ProviderAppointmentStatus.SCHEDULED,
      ],
    },
    { select: (result) => result.data }
  );

  const isCurrentTimeOverlapping = (sameDayUncancelledAppointments || []).some(
    ({ startDate, endDate }) =>
      (combinedStartDate.isSameOrBefore(startDate) &&
        combinedEndDate.isSameOrAfter(endDate)) ||
      (combinedStartDate.isSameOrAfter(startDate) &&
        combinedEndDate.isSameOrBefore(endDate))
  );

  const isInsuranceAppointment =
    'providerAppointment' in event
      ? event.providerAppointment?.billingType === BillingType.INSURANCE
      : !selfPaynessesByUser[values?.patient?.id];

  const shouldBlockProviderIfApptOlderThanThirtyDays =
    shouldBlockProviderWithIroncladAgreement(
      isIroncladBlockAppointmentConfirmation,
      new Date(values.startDate)
    ) && event.type === ProviderEventType.APPOINTMENT;

  const shouldDisplayPrelimPriceBanner =
    !isRescheduling &&
    values.patient &&
    (isInNoDataPrelimPricing ||
      (isInOldDataPrelimPricing && !isProviderPriceLoading));

  useEffect(() => {
    if (shouldDisplayPrelimPriceBanner) {
      trackPageView({
        name: 'Verification In Progress Banner Viewed',
        properties: {
          copyVariant: '',
          patientUserId: values.patient.id,
          providerId: provider.id,
          userInsuranceId: `${values.patient.activeUserInsuranceId}`,
          prelimPricingType:
            values.patient.activeUserInsurance.prelimPricingType,
          presumptiveAccumulatorsApplied:
            providerPriceData?.priceCalculationInfo?.presumptiveAccumulators ??
            [],
        },
      });
    }
  }, [shouldDisplayPrelimPriceBanner, values.patient?.id, provider.id]);

  return (
    <FormikProvider value={formik}>
      <React.Fragment>
        <SafeFormikForm>
          <React.Fragment>
            {shouldBlockProviderIfApptOlderThanThirtyDays && (
              <div className={'mb-4'}>
                <GuidanceCard layout={'vertical'} variant="error">
                  To ensure you are paid in a timely manner, we only allow
                  confirming session details for 30 days
                </GuidanceCard>
              </div>
            )}
            {values.patient ? (
              <IneligibilityBanner
                patient={values.patient}
                provider={provider}
                isIneligibilityExplainerModalOpen={
                  isIneligibilityExplainerModalOpen
                }
                ineligibilityExplainerModalOnClose={() =>
                  setIsIneligibilityExplainerModalOpen(false)
                }
                ineligibilityBannerOnClick={() =>
                  setIsIneligibilityExplainerModalOpen(true)
                }
                isTelehealthAppointment={isTelehealthAppointment}
                userAppointmentReadiness={userAppointmentReadiness}
              />
            ) : null}
            <FirstPaymentEligibleGuidanceCard
              patient={values.patient}
              providerId={provider.id}
            />
            {!isRescheduling ? (
              <FormIconRow icon={PersonTwoTone}>
                <div className={'flex w-full'}>
                  <FieldControl name="patient" fullWidth={true}>
                    <FieldAutocomplete
                      label="Client"
                      options={visiblePatients
                        ?.map((patient) =>
                          patientAndMinDateToAutocompleteOption(
                            patient,
                            getMinScheduleDateForPatient(patient)
                          )
                        )
                        .sort((a, b) => -b.group.localeCompare(a.group))}
                      renderOption={(props: any, option: any) => (
                        <li {...props} key={option.id}>
                          {option.display}
                        </li>
                      )}
                      getOptionLabel={(option: any) => option.display || ''}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      groupBy={(option: any) => option.group}
                      getOptionDisabled={(option: any) => !!option.group}
                      disableClearable={true}
                      renderGroup={renderGroup}
                      data-testid="addSessionPatientField"
                    />
                    <FieldErrorText />
                  </FieldControl>
                  <Tooltip title="Add new client" className={'flex-grow-0'}>
                    <IconButton
                      onClick={() => setPatientModalOpen(true)}
                      size="large"
                    >
                      <AddCircleOutlineTwoTone />
                    </IconButton>
                  </Tooltip>
                </div>
              </FormIconRow>
            ) : null}

            <FormIconRow icon={EventTwoTone} spacing={theme.space.sm}>
              <FieldControl name="startDate" fullWidth={true}>
                <FieldDatePicker
                  label="Start date"
                  margin="normal"
                  inputFormat="MMM Do, YYYY"
                  disableMaskedInput
                  components={{
                    OpenPickerIcon: ArrowDropDown,
                  }}
                />
              </FieldControl>
              <FieldControl name="startTime" fullWidth={true}>
                <FieldInputLabel>Start time</FieldInputLabel>
                <FieldFiveMinuteIntervalSelect data-testid="appointmentScheduleStartTimeField" />
              </FieldControl>
              <FieldControl name="endDate" fullWidth={true}>
                <FieldInputLabel>Duration</FieldInputLabel>
                <FieldDurationSelect startDate={values.startDate} />
              </FieldControl>
            </FormIconRow>
            {isCurrentTimeOverlapping && !isRescheduling && !isIntakeCall && (
              <div className={'mb-4 ml-11'}>
                <GuidanceCard layout={'vertical'} variant="warning">
                  <BodyText>
                    <strong>Conflicting session during this time: </strong>
                    You can only confirm sessions at this time if all
                    overlapping sessions are coded as group therapy (90853).
                  </BodyText>
                </GuidanceCard>
              </div>
            )}
          </React.Fragment>
          {errors.startDate && minLiveDateForAppointment && (
            <Alert
              color="error"
              variant="outlined"
              icon={<ErrorTwoTone />}
              className={'mb-4'}
            >
              {`Schedule a session ${
                values.patient
                  ? `with ${formatPatientName(values.patient, {
                      firstNameOnly: true,
                    })} `
                  : ` `
              }on or after your go-live date with ${
                values.patient?.activeUserInsurance
                  ?.billingFrontEndCarrierName ?? 'your first insurance carrier'
              } on ${dateToUSFormat(
                minLiveDateForAppointment
              )}. On that date, you'll officially be set up so we can take over the billing and admin work.`}
            </Alert>
          )}

          {!isIntakeCall && (
            <FormIconRow icon={LocationOnTwoTone}>
              <FieldControl name="providerAddressId" fullWidth={true}>
                <FieldInputLabel>Location</FieldInputLabel>
                <FieldSelect>
                  {providerAddresses
                    .filter(
                      (address) => !!address.streetLine1 && address.isActive
                    )
                    .map((address) => (
                      <MenuItem value={address.id} key={address.id}>
                        {address.streetLine1},{' '}
                        {address.streetLine2 ? `${address.streetLine2},` : ''}{' '}
                        {startCase(address.city?.toLowerCase())},{' '}
                        {address.state} {address.zipCode}
                      </MenuItem>
                    ))}
                  <MenuItem value={-1} key={-1}>
                    Virtual (Telehealth)
                  </MenuItem>
                </FieldSelect>
                <FieldErrorText />
              </FieldControl>
            </FormIconRow>
          )}
          {showMSCGuidanceCards &&
            // Is there a selected patient
            !!values.patient &&
            // Is this a virtual appointment
            isTelehealthAppointment &&
            isInsuranceAppointment && (
              <PatientAddressDropdown patient={values.patient} />
            )}
          {isInsuranceAppointment && isNotCredentialedInAnyPatientState ? (
            <div className={'mb-3 ml-12'}>
              <NotCredentialedInAnyPatientStateWarning
                patient={values.patient}
                noPatientAddressOnFile={patientAddresses?.length === 0}
                isMSCGuardrailWarning={isMSCGuardrailWarning}
                mscGuardrailRestrictionDate={mscGuardrailRestrictionDate}
              />
            </div>
          ) : isInsuranceAppointment &&
            appointmentState &&
            (isNotCredentialedInSelectedAddressWithoutForcedCheckApptState ||
              isNotCredentialedInSelectedAddressWithForcedCheckApptState ||
              isPendingCredentialingWithNoDateForSelectedAddress) ? (
            <div className={'mb-3 ml-12'}>
              <NotCredentialedInStateError
                patient={values.patient}
                state={appointmentState}
                isMSCGuardrailWarning={isMSCGuardrailWarning}
                mscGuardrailRestrictionDate={mscGuardrailRestrictionDate}
              />
            </div>
          ) : shouldShowNoVerifiedPaymentMethodsGuidanceCard ? (
            <div className={'mb-4'}>
              <GuidanceCard layout={'vertical'} variant={'error'}>
                <div className={'hlx-typography-body'}>
                  {selectedPatientFirstName} does not have a valid payment
                  method on file. Please make sure one is added or you will not
                  be able to confirm any sessions for this client.
                </div>
              </GuidanceCard>
            </div>
          ) : shouldShowPatientHasPreviouslyUnpaidSessionsGuidanceCard ? (
            <div className={'mb-4'}>
              <GuidanceCard layout={'vertical'} variant={'error'}>
                <div className={'hlx-typography-body'}>
                  {selectedPatientFirstName} has previously unpaid sessions that
                  need to be resolved before you can confirm any of their
                  sessions held after{' '}
                  {moment(
                    sessionConfirmationDateOfAppointmentInTerminalFailure
                  ).format('MMM D, YYYY')}
                  . Please ask them to log in to their Billing page to pay the
                  balance or add a new payment method.
                </div>
              </GuidanceCard>
            </div>
          ) : (
            !isRescheduling &&
            values.patient &&
            (isInNoDataPrelimPricing ||
              (isInOldDataPrelimPricing && !isProviderPriceLoading)) && (
              <div className={'mb-4'}>
                <GuidanceCard layout={'vertical'} variant="warning">
                  <BodyText>
                    <div className={'mb-2'}>
                      Reminder:{' '}
                      {values.patient?.activeUserInsurance
                        ?.frontEndCarrierName ||
                        `${selectedPatientFirstName}’s insurer`}{' '}
                      is working to get us the information we need to verify{' '}
                      {selectedPatientFirstName}’s benefits. If you book a
                      session, they’ll pay{' '}
                      {isInOldDataPrelimPricing &&
                      !presumptiveAccumulatorApplied
                        ? 'their most recent'
                        : 'full'}{' '}
                      cost{' '}
                      {isInOldDataPrelimPricing && presumptiveAccumulatorApplied
                        ? '(due to their deductible or out-of-pocket max likely resetting)'
                        : isInOldDataPrelimPricing
                        ? '(adjusted by CPT code)'
                        : '(depending on the CPT code)'}
                      ,{' '}
                      {isInOldDataPrelimPricing &&
                      !presumptiveAccumulatorApplied
                        ? 'with a refund or second bill later if'
                        : 'with a refund later if'}{' '}
                      {values.patient?.activeUserInsurance
                        ?.frontEndCarrierName || 'their insurer'}{' '}
                      confirms a{' '}
                      {isInOldDataPrelimPricing &&
                      !presumptiveAccumulatorApplied
                        ? 'different'
                        : 'lower'}{' '}
                      final cost.
                    </div>
                    <Link
                      href="https://help.headway.co/hc/en-us/articles/21767328647828"
                      target="_blank"
                    >
                      Learn more
                    </Link>
                  </BodyText>
                </GuidanceCard>
              </div>
            )
          )}

          {!isRescheduling && !isIntakeCall ? (
            <FormIconRow icon={ReplayTwoTone}>
              <FieldControl name="recurrence" fullWidth={true}>
                <FieldInputLabel>Recurrence</FieldInputLabel>
                <FieldSelect data-testid="appointmentScheduleRecurrenceField">
                  <MenuItem value={RecurrenceType.DOES_NOT_REPEAT}>
                    Does not repeat
                  </MenuItem>
                  <MenuItem
                    value={RecurrenceType.WEEKLY}
                    data-testid="appointmentScheduleRecurrenceWeeklyOption"
                  >
                    Weekly on {moment(values.startDate).format('dddd')}
                  </MenuItem>
                  <MenuItem value={RecurrenceType.BIWEEKLY}>
                    Every other week on{' '}
                    {moment(values.startDate).format('dddd')}
                  </MenuItem>
                  <MenuItem value={RecurrenceType.MONTHLY}>
                    Monthly on the{' '}
                    {getDayOccurrenceOfMonthString(values.startDate)}{' '}
                    {moment(values.startDate).format('dddd')}
                  </MenuItem>
                  {getDayOccurrenceOfMonth(values.startDate) === 4 &&
                    moment(values.startDate).date() + 7 >
                      moment(values.startDate).daysInMonth() && (
                      <MenuItem value={RecurrenceType.MONTHLY_LAST}>
                        Monthly on the last{' '}
                        {moment(values.startDate).format('dddd')}
                      </MenuItem>
                    )}
                  <MenuItem value={RecurrenceType.EVERY_THREE_MONTHS}>
                    Every 3 months on the{' '}
                    {getDayOccurrenceOfMonthString(values.startDate)}{' '}
                    {moment(values.startDate).format('dddd')}
                  </MenuItem>
                  {getDayOccurrenceOfMonth(values.startDate) === 4 &&
                    moment(values.startDate).date() + 7 >
                      moment(values.startDate).daysInMonth() && (
                      <MenuItem value={RecurrenceType.EVERY_THREE_MONTHS_LAST}>
                        Every 3 months on the last{' '}
                        {moment(values.startDate).format('dddd')}
                      </MenuItem>
                    )}
                </FieldSelect>
                <FieldErrorText />
              </FieldControl>
            </FormIconRow>
          ) : null}
          {isRescheduling &&
          values.recurrence !== RecurrenceType.DOES_NOT_REPEAT ? (
            <FormIconRow icon={ReplayTwoTone}>
              <FieldControl name="recurringUpdateApplyTo" fullWidth={true}>
                <FormLabel className={'mt-2'}>
                  Which sessions should this rescheduling apply to?
                </FormLabel>
                <FieldRadioGroup>
                  <Radio
                    value={RecurringUpdateApplyTo.THIS_EVENT}
                    label="Only this session"
                    data-testid="appointmentScheduleThisSessionOption"
                  />
                  <Radio
                    value={RecurringUpdateApplyTo.FOLLOWING_EVENTS}
                    label="This and all following sessions"
                    data-testid="appointmentScheduleAllSessionsOption"
                  />
                </FieldRadioGroup>
              </FieldControl>
            </FormIconRow>
          ) : null}
          {frozenUserIds.has(values.patient?.id) && (
            <GuidanceCard layout={'vertical'} variant="error">
              <div>
                {getFreezeMessage(
                  values.patient
                    ? freezeReasonsByUser[values.patient.id]
                    : [UserFreezeReason.OTHER],
                  values.patient
                )}{' '}
                {values.patient && (
                  <a href={`/clients/${values.patient?.id}/billing#pricing`}>
                    See details
                  </a>
                )}
              </div>
            </GuidanceCard>
          )}
          {values.patient && !frozenUserIds.has(values.patient?.id) && (
            <WaivedSessionsRemainingGuidanceCard patient={values.patient} />
          )}
          <div className={'mt-8 flex flex-row-reverse justify-between'}>
            <Button
              color="primary"
              type="submit"
              size="large"
              variant="contained"
              disabled={
                !!Object.keys(errors).length ||
                isSubmitting ||
                (isRescheduling && isEqual(values, initialValues)) ||
                shouldBlockProviderIfApptOlderThanThirtyDays ||
                !!isNotCredentialedInSelectedAddressWithoutForcedCheckApptState ||
                isPendingCredentialingWithNoDateForSelectedAddress
              }
              data-testid="appointmentScheduleSubmitButton"
            >
              {isRescheduling ? 'Update' : 'Add'}
            </Button>
          </div>
        </SafeFormikForm>
        {isPatientModalOpen ? (
          <LegacyAddPatientModal
            open={true}
            onClose={() => {
              setPatientModalOpen(false);
            }}
            onPatientAdded={() => {
              reload();
            }}
            onComplete={async (created) => {
              await reload();
              const autocompleteOption = await newPatientToAutocompleteOption(
                created,
                provider,
                stateInsuranceCarriers,
                estimatedLiveDates,
                minEstimatedLiveDate
              );
              setFieldValue('patient', autocompleteOption);
              setPatientModalOpen(false);
            }}
            provider={provider}
          />
        ) : null}
      </React.Fragment>
    </FormikProvider>
  );
};
