import React from 'react';

import { UserApi } from '@headway/api/resources/UserApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { Button } from '@headway/helix/Button';
import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { Divider } from '@headway/helix/Divider';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { Link } from '@headway/helix/Link';
import { PageHeader } from '@headway/helix/PageHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { AddPerson } from '@headway/icons/dist/AddPerson';
import { Aetna } from '@headway/icons/dist/Aetna';
import { Anthem } from '@headway/icons/dist/Anthem';
import { Bcbs } from '@headway/icons/dist/Bcbs';
import { Money } from '@headway/icons/dist/Money';
import { NewCigna } from '@headway/icons/dist/NewCigna';
import { Unitedhealthcare } from '@headway/icons/dist/Unitedhealthcare';
import { WavingHand } from '@headway/icons/dist/WavingHand';
import referralImg from '@headway/shared/assets/img/product/referral.jpeg';
import {
  REFERRAL_SOURCE,
  REFERRED_BONUS_AMOUNT,
} from '@headway/shared/constants/referrals';
import { useQuery } from '@headway/shared/react-query';

import {
  createOrFetchExtoleToken,
  ExtoleDataResponse,
  ExtoleUserResponse,
  fetchExtoleUserData,
  IEmailDetails,
  trackCTAEvents,
  useReferralProgramIncentiveAmount,
} from '../hooks/extole';
import ReferralShareSideSheet from './ReferralShareSideSheet';
import ReferralSideSheet from './ReferralSideSheet';
import ReferralTrackerSideSheet from './ReferralTrackerSideSheet';

const LINKEDIN_CONNECTION_HREF =
  'https://www.linkedin.com/search/results/people/?geoUrn=%5B%22103644278%22%5D&network=%5B%22F%22%2C%22S%22%2C%22O%22%5D&origin=FACETED_SEARCH&sid=tP9&titleFreeText=therapist%20OR%20psychologist%20OR%20psychiatrist%20OR%20psychiatric%20NP';
/*************** THESE ARE JUST PLACEHOLDERS *******************/
const WHO_TO_REFER_LINK = 'https://www.example.com';
const ACCEPTED_LICENSE_LINK = 'https://www.example.com';
/***************************************************************/

const ReferralProgramHeader = ({
  ReferralAccountComponent: ReferralAccountComponent,
  imgComponent: ImgComponent = 'img',
  shareableLink,
  advocateCode,
  campaignId,
  programLabel,
  extoleAccessToken,
  hasExtoleProfile,
  emailDetails,
}: {
  AuthStore: any;
  imgComponent?: React.FC<React.PropsWithChildren<any>> | 'img';
  ReferralAccountComponent: React.FC<React.PropsWithChildren<any>>;
  shareableLink?: string;
  advocateCode?: string;
  campaignId?: string;
  programLabel?: string;
  extoleAccessToken?: string;
  hasExtoleProfile: boolean;
  emailDetails?: IEmailDetails;
}) => {
  const isTrackable = hasExtoleProfile && extoleAccessToken && programLabel;
  const isReadyToRefer =
    isTrackable && advocateCode && campaignId && shareableLink;
  return (
    <div className="header w-full ">
      <div className="grid w-full grid-cols-[2fr_1fr] justify-between gap-12">
        <div>
          <div>
            <div className="hlx-typography-list-heading">
              Refer a provider: You get ${useReferralProgramIncentiveAmount()},
              they get ${REFERRED_BONUS_AMOUNT}
            </div>
            <BrandText>Invite a provider to join a rewarding network</BrandText>
            <br />
            <div className="hlx-typography-list-heading flex flex-col gap-1 pb-4 pt-4">
              <span>
                Invite a provider to join the largest network of therapists and
                psychiatrists that accept insurance in the country.
              </span>
              <Link
                href={LINKEDIN_CONNECTION_HREF}
                target="_blank"
                rel="noopener"
                onClick={() => {
                  isTrackable &&
                    trackCTAEvents({
                      type: 'clicked',
                      token: extoleAccessToken,
                      location: 'linkedin_connection_href',
                      source: REFERRAL_SOURCE,
                      programLabel,
                    });
                }}
              >
                Don’t know who to refer?
              </Link>
            </div>
          </div>
          {isReadyToRefer ? (
            <>
              <div className="flex flex-col space-y-2 pt-4">
                <ReferralSideSheet
                  advocateCode={advocateCode}
                  accessToken={extoleAccessToken}
                  campaignId={campaignId}
                  programLabel={programLabel}
                />
                <ReferralShareSideSheet
                  shareLink={shareableLink}
                  advocateCode={advocateCode}
                  accessToken={extoleAccessToken}
                  campaignId={campaignId}
                  programLabel={programLabel}
                  emailDetails={emailDetails}
                />
              </div>
            </>
          ) : (
            <ReferralAccountComponent
              extoleAccessToken={extoleAccessToken}
              programLabel={programLabel}
            />
          )}
        </div>
        <div className="min-w-452 min-h-495 flex w-full items-center justify-center overflow-hidden">
          <ImgComponent
            // in Patient the sigmundPicture import is StaticImageData which
            // can't be assigned to an img src. All usage in Patient is expected
            // to pass an imgComponent that knows how to handle StaticImageData.
            // @ts-ignore
            src={referralImg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const ReferralHowItWorks = ({
  extoleAccessToken,
  programLabel,
}: {
  extoleAccessToken?: string;
  programLabel?: string;
}) => {
  const hasExtoleProfile = extoleAccessToken && programLabel;
  return (
    <div className="my-12 w-full">
      <PageHeader>How it works</PageHeader>
      <div key={1} className="my-4 w-full">
        <div className="flex flex-row space-x-3">
          <div className="bg-system-lightGray flex h-10 w-10 items-center justify-center rounded-full p-4">
            <AddPerson
              className="shrink-0"
              width="20"
              height="20"
              fill="black"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <div className="flex flex-row gap-1">
              <BodyText>You refer a provider</BodyText>
              {/* {item.badge && <Badge variant="positive">{item.badge}</Badge>} */}
            </div>
            <SubBodyText color="gray">
              Refer licensed mental health providers by inviting them directly
              or by sharing your unique referral link on social media.{' '}
              <Link
                href={WHO_TO_REFER_LINK}
                target="_blank"
                rel="noopener"
                onClick={() => {
                  hasExtoleProfile &&
                    trackCTAEvents({
                      type: 'clicked',
                      token: extoleAccessToken,
                      location: 'who_to_refer_link',
                      source: REFERRAL_SOURCE,
                      programLabel,
                    });
                }}
              >
                Learn more about who to refer.
              </Link>
            </SubBodyText>
          </div>
        </div>
      </div>
      <div key={2} className="my-4 w-full">
        <div className="flex flex-row space-x-3">
          <div className="bg-system-lightGray flex h-10 w-10 items-center justify-center rounded-full p-4">
            <WavingHand
              className="shrink-0"
              width="20"
              height="20"
              fill="black"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <div className="flex flex-row gap-1">
              <BodyText>We reach out</BodyText>
            </div>
            <SubBodyText color="gray">
              We'll reach out to your referral to schedule an introduction
              meeting.
            </SubBodyText>
          </div>
        </div>
      </div>
      <div key={3} className="my-4 w-full">
        <div className="flex flex-row space-x-3">
          <div className="bg-system-lightGray flex h-10 w-10 items-center justify-center rounded-full p-4">
            <Money className="shrink-0" width="20" height="20" fill="black" />
          </div>
          <div className="flex flex-col space-y-1">
            <div className="flex flex-row gap-1">
              <BodyText>
                You get ${useReferralProgramIncentiveAmount()}, they get $
                {REFERRED_BONUS_AMOUNT}
              </BodyText>
            </div>
            <SubBodyText color="gray">
              You'll receive ${useReferralProgramIncentiveAmount()} after your
              referral submits their credentialing intake form. They’ll get $
              {REFERRED_BONUS_AMOUNT} after they confirm their first session on
              Headway.
            </SubBodyText>
          </div>
        </div>
      </div>
    </div>
  );
};

const insuranceCoverages = [
  {
    imgSrc: Unitedhealthcare,
    alt: 'United Healthcare',
  },
  {
    imgSrc: Bcbs,
    alt: 'BlueCross BlueShield',
    height: 35,
  },
  {
    imgSrc: Aetna,
    alt: 'Aetna',
    height: 30,
  },
  {
    imgSrc: NewCigna,
    alt: 'Cigna',
  },
  {
    imgSrc: Anthem,
    alt: 'Anthem',
    height: 30,
  },
];

const InsuranceCoverage = () => {
  return (
    <div className="my-8 w-full">
      <Divider autoSpacing={false} />
      <div className="mb-4 grid grid-cols-1">
        <div className="flex justify-center py-4">
          We accept most major insurances
        </div>
        <div
          className={
            'flex flex-wrap items-center justify-center gap-x-[60px] gap-y-5 whitespace-nowrap'
          }
          css={{
            ...theme.typography.list,
            fontHeight: theme.typography.sectionHeader.lineHeight,
            fontWeight: theme.fontWeight.regular,
          }}
        >
          {insuranceCoverages.map((coverage, index) => {
            return (
              <div
                key={index}
                className="flex max-h-20 items-center justify-center p-2"
              >
                <coverage.imgSrc height={coverage.height || 40} fill="black" />
              </div>
            );
          })}
          <span className="text-center">+more</span>
        </div>
      </div>
      <Divider autoSpacing={false} />
    </div>
  );
};

const WhoToRefer = ({
  extoleAccessToken,
  programLabel,
}: {
  extoleAccessToken: string;
  programLabel: string;
}) => {
  const listOfLicenses = [
    'Nurse Practitioner (NP)',
    'Doctor of Osteopathic Medicine (DO)',
    'Licensed Clinical Social Worker (LCSW)',
    'Licensed Marriage/Family Therapist (LMFT)',
    'Medical Doctor (MD)',
    'Clinical Psychologist (CP)',
    'Licensed Psychologist (LP)',
  ];
  return (
    <div className="my-12 w-full space-y-6">
      <div className="flex flex-col space-y-5">
        <PageHeader>Who to refer</PageHeader>
        <div
          css={{
            fontSize: theme.typography.list.fontSize,
            lineHeight: theme.typography.sectionHeader.lineHeight,
            fontWeight: theme.fontWeight.regular,
          }}
        >
          Refer licensed mental health providers who want to start working with
          insurance. Common licenses we accept are:
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center border p-4">
        {listOfLicenses.map((license, index) => {
          return (
            <div
              key={index}
              className="flex-shrink-0 p-4"
              css={{
                ...theme.typography.brand.section,
                color: theme.color.system.green,
              }}
            >
              {license}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <Button
          type="button"
          variant="secondary"
          size="large"
          onPress={() => {
            trackCTAEvents({
              type: 'clicked',
              token: extoleAccessToken,
              location: 'accepted_license_link',
              source: REFERRAL_SOURCE,
              programLabel,
            });
          }}
        >
          See a comprehensive list of accepted licenses by state ↗
        </Button>
      </div>
    </div>
  );
};

const YourReferrals = ({
  userReferralDetails,
  extoleAccessToken,
  programLabel,
}: {
  userReferralDetails?: ExtoleUserResponse;
  extoleAccessToken: string;
  programLabel: string;
}) => {
  let pendingRedemption = 0;
  let totalEarned = 0;
  let totalSignups = 0;

  if (userReferralDetails) {
    userReferralDetails.rewards.forEach((reward) => {
      if (reward.state === 'pending') {
        pendingRedemption += Number(reward.faceValue || 0);
      } else {
        totalEarned += Number(reward.faceValue || 0);
      }
    });

    totalSignups = userReferralDetails.friends.filter(
      (friend) => friend.status === 'Signed Up'
    ).length;
  }

  const ReferralStats = [
    {
      title: 'Shared referrals',
      value: `${userReferralDetails?.friends.length || 0}`,
    },
    {
      title: <GlossaryTerm term="Sign ups" />,
      value: `${totalSignups}`,
    },
    {
      title: 'Earned',
      value: `${totalEarned}`,
      note: (
        <>
          ${pendingRedemption} <GlossaryTerm term="Pending redemption" />
        </>
      ),
    },
  ];

  return (
    <div className="my-12 w-full space-y-6">
      <Divider autoSpacing={true} />
      <div className="flex flex-row items-center justify-between align-top">
        <h2>
          <PageHeader>Your referrals</PageHeader>
        </h2>
        {/* <ReferralTrackerSideSheet
          referralList={userReferralDetails?.friends}
          extoleAccessToken={extoleAccessToken}
          programLabel={programLabel}
        /> */}
      </div>
      <div className="flex space-x-4">
        {ReferralStats.map((stat, index) => {
          return (
            <div className="flex-1 p-4" key={index}>
              <BodyText>{stat.title}</BodyText>
              <div
                css={{
                  fontSize: '38px',
                  lineHeight: '50.54px',
                  fontWeight: '500',
                }}
              >
                {stat.value}
              </div>
              {stat.note && <BodyText color="gray">{stat.note}</BodyText>}
            </div>
          );
        })}
      </div>
      <Divider autoSpacing={true} />
    </div>
  );
};

const ReferralFaqs = ({
  extoleAccessToken,
  programLabel,
}: {
  extoleAccessToken: string;
  programLabel: string;
}) => {
  return (
    <div>
      <PageHeader>Frequently asked questions</PageHeader>
      <ul className="my-6 flex flex-col justify-start pl-0">
        <CollapsibleDisclosure size="large" label="How do I refer?">
          <div className="pt-3">
            <BodyText>
              <div className="flex flex-col gap-1">
                <p>There are 2 ways to refer:</p>
                <ol className="list-inside list-decimal pl-0">
                  <li>
                    <strong>Invite a provider directly</strong>
                    <p className="pl-4">
                      If you know a provider who is ready to join, invite a them
                      directly by submitting their contact information, and our
                      team will reach out to them directly to schedule an intro
                      meeting.
                    </p>
                  </li>
                  <li>
                    <strong>Share your unique referral link</strong>
                    <p className="pl-4">
                      Share your referral link with a provider or with an
                      audience on social media, and providers can sign up at
                      their own pace.
                    </p>
                  </li>
                </ol>
              </div>
            </BodyText>
          </div>
        </CollapsibleDisclosure>
        <Divider />
        <CollapsibleDisclosure size="large" label="Who should I refer?">
          <div className="pt-3">
            <BodyText>
              <div className="flex flex-col gap-1">
                <p>
                  Refer licensed mental health providers. We work with Master's
                  Level Therapists, Clinical Psychologists, and Psychiatric Care
                  Practitioners
                </p>
                <p>
                  <Link
                    href={ACCEPTED_LICENSE_LINK}
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      trackCTAEvents({
                        type: 'clicked',
                        token: extoleAccessToken,
                        location: 'accepted_license_link',
                        source: REFERRAL_SOURCE,
                        programLabel,
                      });
                    }}
                  >
                    View a comprehensive list of accepted licenses by state.
                  </Link>
                </p>
              </div>
            </BodyText>
          </div>
        </CollapsibleDisclosure>
        <Divider />
        <CollapsibleDisclosure
          size="large"
          label="What is considered a successful referral?"
        >
          <div className="pt-3">
            <BodyText>
              You’ll qualify for a reward when the provider you refer submits
              our credentialing intake form to start the insurance paneling
              process.
            </BodyText>
          </div>
        </CollapsibleDisclosure>
        <Divider />
        <CollapsibleDisclosure
          size="large"
          label="How and when will I receive my reward?"
        >
          <div className="pt-3">
            <BodyText>
              Once you qualify for a reward, you will receive a link in your
              email to redeem your reward through Paypal within [X days].
            </BodyText>
          </div>
        </CollapsibleDisclosure>
        <Divider />
        <CollapsibleDisclosure
          size="large"
          label="Why did my referral get rejected?"
        >
          <div className="pt-3">
            <BodyText>
              <div className="flex flex-col gap-1">
                <p>
                  Referrals may be rejected for a variety of reasons. They may
                  be rejected:
                </p>
                <ol className="ml-4 list-decimal pl-0">
                  <li>
                    If they don’t have the necessary professional credentials:
                    They must meet the insurer requirements to get credentialed.
                    These requirements include licensure, board certifications,
                    and prescriber registrations.
                  </li>
                  <li>
                    Your referral has already joined Headway as a provider.
                  </li>
                  <li>
                    Your referral was referred by someone else before you.
                  </li>
                </ol>
              </div>
            </BodyText>
          </div>
        </CollapsibleDisclosure>
        <Divider />
        <CollapsibleDisclosure
          size="large"
          label="What happened to my old referral link?"
        >
          <div className="pt-3">
            <BodyText>
              We introduced new links in order to make it possible to track your
              referral statuses, but your old referral links are still active!
              You’ll be able to track statuses and earn rewards for referrals
              submitted through those old links.
            </BodyText>
          </div>
        </CollapsibleDisclosure>
      </ul>
    </div>
  );
};
interface ReferProviderProps {
  AuthStore?: any;
  imgComponent?: React.FC<React.PropsWithChildren<any>> | 'img';
  ReferralAccountComponent: React.FC<React.PropsWithChildren<any>>;
}

export const ReferralProgram = ({
  AuthStore,
  imgComponent: ImgComponent = 'img',
  ReferralAccountComponent,
}: ReferProviderProps) => {
  const user = AuthStore.user;
  const referralPortalQuery = useQuery(['referralPortal', user.id], () =>
    UserApi.getReferralPortal(user.id)
  );
  const extoleJwt = referralPortalQuery.data?.accessToken;

  const extoleAccessTokenQuery = useQuery(
    ['extoleAccessToken', extoleJwt],
    async () => await createOrFetchExtoleToken({ user, jwt: extoleJwt! }),
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      enabled: Boolean(extoleJwt),
      refetchOnWindowFocus: false,
    }
  );

  const extoleAccessToken = extoleAccessTokenQuery.data?.access_token;

  const query = useQuery(
    ['userReferralData', extoleAccessToken],
    async () => {
      const response = await fetchExtoleUserData(extoleAccessToken!);
      return response?.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      enabled: Boolean(extoleAccessToken),
      refetchOnWindowFocus: false,
    }
  );

  let userData: ExtoleDataResponse | undefined;
  if (!userData && query.isSuccess) {
    userData = query.data;
  }

  const hasExtoleProfile = Boolean(userData?.me?.email);
  const emailDetails = userData?.sharing?.email;

  return (
    <React.Fragment>
      <div className="mx-auto max-w-[1200px] px-6 pt-12">
        <ReferralProgramHeader
          AuthStore={AuthStore}
          imgComponent={ImgComponent}
          ReferralAccountComponent={ReferralAccountComponent}
          hasExtoleProfile={hasExtoleProfile}
          shareableLink={userData?.me?.shareable_link}
          advocateCode={userData?.me?.advocate_code}
          campaignId={userData?.campaign_id}
          programLabel={userData?.program_label}
          extoleAccessToken={extoleAccessToken}
          emailDetails={emailDetails}
        />
        {!hasExtoleProfile && <InsuranceCoverage />}
        <ReferralHowItWorks />
        {userData?.program_label &&
          extoleAccessToken &&
          (hasExtoleProfile ? (
            <>
              <YourReferrals
                userReferralDetails={userData!.me}
                extoleAccessToken={extoleAccessToken}
                programLabel={userData.program_label}
              />
              <ReferralFaqs
                extoleAccessToken={extoleAccessToken}
                programLabel={userData.program_label}
              />
            </>
          ) : (
            <WhoToRefer
              extoleAccessToken={extoleAccessToken}
              programLabel={userData.program_label}
            />
          ))}
      </div>
    </React.Fragment>
  );
};
