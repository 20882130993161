import sortBy from 'lodash/sortBy';
import { useState } from 'react';
import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
} from '~/legacy/mutations/utils';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { ListRow } from '@headway/helix/List';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { SearchField } from '@headway/helix/SearchField';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  getProviderDisplayFirstAndLast,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';
import useFuzzy from '@headway/ui/form/useFuzzy';

import {
  useGroupPracticeProviders,
  useGroupPracticeProvidersCache,
} from 'hooks/useGroupPracticeProviders';
import { useAuthStore } from 'stores/AuthStore';
import { useSetSelectedProvider } from 'utils/practice';

import { ProviderAvatar } from './ProviderAvatar';

export const ManageTeamModal = ({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean;
  onDismiss: () => void;
}) => {
  const AuthStore = useAuthStore();
  const { data: providers } = useGroupPracticeProviders(
    {
      groupPracticeId: AuthStore.user?.group_practice?.id,
      query: { is_active: true },
    },
    {
      select: (providers) =>
        sortBy(providers, (provider) =>
          getProviderDisplayFirstAndLast(provider)
        ),
    }
  );
  const [searchQuery, setSearchQuery] = useState('');

  const { search } = useFuzzy(providers || [], {
    keys: ['displayFirstName', 'displayLastName', 'prenomial', 'email'],
  });
  const filteredProviders =
    searchQuery && providers ? search(searchQuery) : providers;

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} title="Manage team">
      <ModalContent>
        <SearchField
          placeholder="Search providers"
          onChange={(value: string) => setSearchQuery(value)}
        />
        {filteredProviders?.length ? (
          <ul className="mt-5 p-0">
            {filteredProviders.map((provider: ProviderRead) => (
              <ProviderListRow
                key={provider.id}
                provider={provider}
                onDismiss={onDismiss}
              />
            ))}
          </ul>
        ) : searchQuery ? (
          <div className="mt-5 text-center">
            No providers found. Try adjusting your search.
          </div>
        ) : null}
      </ModalContent>
      <ModalFooter>
        <Button onPress={onDismiss}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

const ProviderListRow = ({
  provider,
  onDismiss,
}: {
  provider: ProviderRead;
  onDismiss: () => void;
}) => {
  const AuthStore = useAuthStore();
  const groupPracticeId = AuthStore.user!.group_practice!.id;
  const setSelectedProvider = useSetSelectedProvider();
  const handleProviderClick = () => {
    setSelectedProvider(provider);
    onDismiss();
  };
  const groupPracticeProvidersCache = useGroupPracticeProvidersCache();

  const toggleArchivedMutation = useMutationWithSideEffects(
    ({ isArchived }: { isArchived: boolean }) =>
      GroupPracticeApi.updateProviderIsArchived(
        groupPracticeId,
        provider.id,
        isArchived
      ),
    {
      sideEffects: new SideEffectsBuilder<
        ProviderRead,
        unknown,
        { isArchived: boolean }
      >().addOptimisticUpdate(
        groupPracticeProvidersCache,
        () => ({
          groupPracticeId,
          query: { is_active: true },
        }),
        ({ isArchived }, current) => {
          if (!current) {
            return undefined;
          }
          return current.map((p) =>
            p.id === provider.id ? { ...p, isArchived } : p
          );
        }
      ),
    }
  );

  return (
    <ListRow key={provider.id}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <ProviderAvatar provider={provider} size="medium" />
          <div className="flex-col">
            <div className="flex items-center gap-2">
              <Button variant="link" onPress={handleProviderClick}>
                {getProviderDisplayFirstAndLastWithPrenomial(provider)}
              </Button>
              {provider.isArchived && <Badge variant="neutral">Archived</Badge>}
            </div>
            <SubBodyText>{provider.email}</SubBodyText>
          </div>
        </div>
        <Button
          variant="secondary"
          onPress={() =>
            toggleArchivedMutation.mutate({
              isArchived: !provider.isArchived,
            })
          }
        >
          {provider.isArchived ? 'Unarchive' : 'Archive'}
        </Button>
      </div>
    </ListRow>
  );
};
