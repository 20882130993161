import { pdf } from '@react-pdf/renderer';
import { useCallback } from 'react';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';

import { AllAssessmentsPdf } from './AllAssessmentsPdf';

type AllAssessmentPdfParams = {
  client: UserRead;
  provider: ProviderRead;
};

export const useAllAssessmentsPdf = () =>
  useCallback(async (params: AllAssessmentPdfParams) => {
    const assessments = [
      // to be replaced with querying actual assessment data
      // https://app.shortcut.com/findheadway/story/309202
      { id: 123 } as PatientAssessmentRead,
    ];

    const renderedPdf = pdf(
      <AllAssessmentsPdf assessments={assessments} {...params} />
    );

    const blob = await renderedPdf.toBlob();
    return blob;
  }, []);
