import { Document, Page, PageProps, View } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

import { registerPdfFonts } from '../fonts';
import { combinePdfStyles, pdfStyles } from '../style';
import { PdfLogo } from './PdfLogo';
import { PdfText } from './typography';

const PdfFooter = () => {
  return (
    <View fixed style={{ alignItems: 'flex-end' }}>
      <PdfText render={({ pageNumber }) => `Page ${pageNumber}`} />
    </View>
  );
};

export const PdfPage = ({
  children,
  style = {},
}: PropsWithChildren<PageProps>) => (
  <Page style={combinePdfStyles(pdfStyles.page, style)}>
    <PdfLogo />
    <View style={{ flexGrow: 1, flexDirection: 'column', gap: 16 }}>
      {children}
    </View>
    <PdfFooter />
  </Page>
);

export const PdfDocument = ({ children }: PropsWithChildren) => {
  registerPdfFonts();

  return <Document>{children}</Document>;
};
