import clsx from 'clsx';
import React from 'react';
import { mergeProps, useButton } from 'react-aria';
import { useToggleState } from 'react-stately';

import { BodyText, BodyTextProps } from './BodyText';
import { IconChevronDown } from './icons/ChevronDown';
import { IconChevronRight } from './icons/ChevronRight';
import { SubBodyText, SubBodyTextProps } from './SubBodyText';
import { useInteraction } from './useInteraction';

export interface CollapsibleDisclosureProps {
  children: React.ReactNode;
  label: string;
  size?: 'small' | 'large';
  expanded?: boolean;
  defaultExpanded?: boolean;
  onToggle?(expanded: boolean): void;
}

export const CollapsibleDisclosure = (props: CollapsibleDisclosureProps) => {
  const state = useToggleState({
    defaultSelected: props.defaultExpanded,
    isSelected: props.expanded,
    onChange: props.onToggle,
  });
  const ref = React.useRef(null);
  const { focusProps, isFocusVisible } = useInteraction({
    disabled: false,
  });

  const labelSize = props.size;
  let DefaultLabelFont: React.ComponentType<BodyTextProps | SubBodyTextProps> =
    SubBodyText;
  if (labelSize === 'large') {
    DefaultLabelFont = BodyText as React.ComponentType<
      BodyTextProps | SubBodyTextProps
    >;
  }

  const { buttonProps } = useButton(
    {
      ...props,
      onPress: state.toggle,
    },
    ref
  );

  return (
    <div className="hlx-collapsible-disclosure">
      <button
        ref={ref}
        className={clsx('hlx-collapsible-disclosure-title', {
          'focus-ring': isFocusVisible,
        })}
        aria-expanded={state.isSelected ? true : false}
        {...mergeProps(focusProps, buttonProps)}
      >
        <span>
          <DefaultLabelFont>
            <strong>{props.label}</strong>
          </DefaultLabelFont>
        </span>
        {state.isSelected ? <IconChevronDown /> : <IconChevronRight />}
      </button>
      {state.isSelected && <div>{props.children}</div>}
    </div>
  );
};
