import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseSessionDetailsConfirmabilityKeyArgs {
  providerEventVirtualId: string | undefined;
}
export type UseSessionDetailsConfirmabilityQueryKey = [
  string,
  string | undefined,
];

export const getUseSessionDetailsConfirmabilityKey = ({
  providerEventVirtualId,
}: UseSessionDetailsConfirmabilityKeyArgs): UseSessionDetailsConfirmabilityQueryKey => [
  'session-details-confirmability',
  providerEventVirtualId,
];

const {
  useSingleQuery: useSessionDetailsConfirmability,
  useListQuery: useSessionDetailsConfirmabilityList,
} = createBasicApiHooks(
  getUseSessionDetailsConfirmabilityKey,
  ({ providerEventVirtualId }) =>
    ProviderEventApi.getSessionDetailsConfirmability(providerEventVirtualId!),
  ({ providerEventVirtualId }) => {
    return !!providerEventVirtualId;
  },
  () => 'Failed to fetch session details confirmability'
);

export { useSessionDetailsConfirmability, useSessionDetailsConfirmabilityList };
