import { PdfDocument } from '~/legacy/pdf/components/layout';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';

import { AssessmentResultPdfHeaderProps } from './AssessmentResultPdfHeader';
import { AssessmentResultPdfPage } from './AssessmentResultPdfPage';

type AllAssessmentsPdfProps = AssessmentResultPdfHeaderProps & {
  assessments: PatientAssessmentRead[];
};

export const AllAssessmentsPdf = ({
  assessments,
  ...headerProps
}: AllAssessmentsPdfProps) => {
  return (
    <PdfDocument>
      {assessments.map((assessment) => (
        <AssessmentResultPdfPage
          key={assessment.id}
          assessment={assessment}
          {...headerProps}
        />
      ))}
    </PdfDocument>
  );
};
