import { useCallback } from 'react';

export const useDownloadBlob = () =>
  useCallback((blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);

    const phantomDownloadLink = document.createElement('a');
    phantomDownloadLink.href = url;
    phantomDownloadLink.download = filename;

    document.body.appendChild(phantomDownloadLink);
    phantomDownloadLink.click();

    document.body.removeChild(phantomDownloadLink);
    URL.revokeObjectURL(url);
  }, []);
