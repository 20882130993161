import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { VerifiableName } from '@headway/api/models/VerifiableName';
import '@headway/api/resources/ProviderApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { VerifiableApi } from '@headway/api/resources/VerifiableApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import {
  EinField,
  PhoneNumberField,
  SocialSecurityField,
} from '@headway/helix/MaskedTextField';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import countries from '@headway/shared/constants/countries';
import { YUP_PHONE_MATCH } from '@headway/shared/constants/format';
import {
  GENDER_STATUS_TO_DISPLAY_NAMES,
  GENDERS_TO_DISPLAY_NAMES,
  shouldIncludeProviderGender,
} from '@headway/shared/constants/gender';
import statesToDisplayNames, {
  unitedStatesAbbreviations,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  ethnicities,
  NOT_HISPANIC_OR_LATINX,
  races,
  split_ethnicities,
} from '@headway/shared/utils/providerQuestionnaire';
import { Modal } from '@headway/ui/Modal';

import { states } from '../../../../utils/states';
import { OnBeforeSubmitError } from '../../OnBeforeSubmitErrorCard';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { CAQH_WEBSITE_URL } from '../../utils/intakeQuestionnaireConstants';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { PersonalInformationHeader } from './PersonalInformationHeader';

const PersonalInformationStep = ({
  initialValues,
  formikHelpers,
  showConfirmationModal,
  setShowConfirmationModal,
  onStepComplete,
}: CustomComponentProps) => {
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    if (initialValues.stateOfResidency) {
      loadCounties(initialValues.stateOfResidency).then((counties) => {
        setCounties(counties);
      });
    }
  }, [initialValues.stateOfResidency, setCounties]);

  async function onStateOfResidencyChange(state: string) {
    const counties = await loadCounties(state);
    if (!counties) {
      formikHelpers.setFieldValue('countyOfResidency', states[state]);
    } else if (counties.length === 1) {
      formikHelpers.setFieldValue('countyOfResidency', counties[0]);
    } else {
      formikHelpers.setFieldValue('countyOfResidency', '');
    }
    setCounties(counties);
  }

  const hispanicLatinxMigration = useFlag('hispanicLatinxMigration', false);

  const { setFieldValue, values } = formikHelpers;

  const doesAddressMismatchWithCaqhHomeAddress = !!(
    initialValues.caqhHomeAddress &&
    ((initialValues.caqhHomeAddress.state &&
      values.stateOfResidency &&
      initialValues.caqhHomeAddress.state !== values.stateOfResidency) ||
      (initialValues.caqhHomeAddress.county &&
        values.countyOfResidency &&
        initialValues.caqhHomeAddress.county !== values.countyOfResidency) ||
      (initialValues.caqhHomeAddress.zip &&
        values.zip &&
        initialValues.caqhHomeAddress.zip !== values.zip))
  );

  function onMultiEthnicityChange(values: Set<string>) {
    const existingValues = formikHelpers.values.ethnicity;

    if (typeof existingValues === 'string') {
      throw new Error('shouldnt be in this state');
    }
    if (
      values.has(NOT_HISPANIC_OR_LATINX) &&
      !existingValues?.includes(NOT_HISPANIC_OR_LATINX)
    ) {
      formikHelpers.setFieldValue('ethnicity', [NOT_HISPANIC_OR_LATINX]);
    } else {
      values.delete(NOT_HISPANIC_OR_LATINX);
      formikHelpers.setFieldValue('ethnicity', [...values]);
    }
  }

  async function loadCounties(state: string) {
    try {
      const { default: counties } = await import(
        `../../../../constants/counties/${state.toLowerCase()}.json`
      );
      return counties;
    } catch {
      return [];
    }
  }

  const NameMismatchConfirmationModal = () => (
    <Modal
      title="Confirm CAQH Name"
      open={showConfirmationModal}
      onClose={() => setShowConfirmationModal(false)}
    >
      <div
        css={{
          marginBottom: theme.spacing.x5,
        }}
      >
        <span>
          It appears that your legal name in CAQH does not match the name listed
          in the NPI registry. Please{' '}
          <Link href={CAQH_WEBSITE_URL} target="_blank" rel="noreferrer">
            <b>update your name in CAQH</b>
          </Link>{' '}
          to reflect what is listed in the NPI registry and your license in
          order to avoid credentialing errors in the future.
        </span>
      </div>
      <div>
        <strong>NPI Registry:</strong>
      </div>
      <div
        css={{
          marginBottom: theme.spacing.x3,
        }}
      >
        <span>
          {initialValues.npiFirstName} {initialValues.npiLastName}
        </span>
      </div>
      <div>
        <strong>CAQH:</strong>
      </div>
      <div
        css={{
          marginBottom: theme.spacing.x3,
        }}
      >
        <span>
          {initialValues.firstName} {initialValues.lastName}
        </span>
      </div>
      <CheckboxGroup name="confirmNameCheckboxGroup">
        <Checkbox value="confirmName">
          I affirm that I have synced these names
        </Checkbox>
      </CheckboxGroup>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.spacing.x4,
          gap: theme.spacing.x2,
        }}
      >
        <Button
          variant="secondary"
          onPress={() => setShowConfirmationModal(false)}
        >
          Cancel
        </Button>
        <Button
          onPress={() => {
            setShowConfirmationModal(false);
            onStepComplete(formikHelpers.values);
          }}
        >
          Confirm and Continue
        </Button>
      </div>
    </Modal>
  );

  return (
    <>
      <NameMismatchConfirmationModal />
      <PersonalInformationHeader initialValues={initialValues} />
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: '24px',
        }}
      >
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x1,
            width: '60%',
          }}
        >
          <SectionHeader>Home address</SectionHeader>
        </div>
        <FormControl component={TextField} name={'street1'} label="Address" />
        <FormControl
          component={TextField}
          name={'street2'}
          label="Apartment, Suite, etc."
        />
        <FormControl component={TextField} name={'city'} label="City" />
        <FormControl
          component={Select}
          name="countyOfResidency"
          label="County of Residency"
          selectionMode="single"
          menuWidth="stretch"
        >
          {counties.map((county) => (
            <Item key={county}>{county}</Item>
          ))}
        </FormControl>
        <FormControl
          component={Select}
          name={'state'}
          label="State"
          selectionMode="single"
          menuWidth="stretch"
          searchable={true}
          onSelectionChange={async (states: Set<string>) => {
            const state = states.values().next().value;
            const stateAbbrev =
              unitedStatesAbbreviations[state as UnitedStates];
            // for data compatibility, keep setting stateOfResidency even though
            // there's no longer a separate dropdown
            setFieldValue('stateOfResidency', stateAbbrev);
            await onStateOfResidencyChange(stateAbbrev);
          }}
        >
          {Object.entries(statesToDisplayNames).map((entry) => {
            return <Item key={entry[0]}>{entry[1]}</Item>;
          })}
        </FormControl>
        <FormControl component={TextField} name={'zip'} label="ZIP Code" />
        {doesAddressMismatchWithCaqhHomeAddress && (
          <GuidanceCard variant="neutral">
            <div>
              <p>
                Looks like this address is different from the one we found in
                your CAQH account. Make sure your CAQH account is updated
                because insurers rely on CAQH as a source of truth during the
                credentialing process.
              </p>
              <Link
                href={`${CAQH_WEBSITE_URL}/PersonalInfo`}
                target="_blank"
                rel="noreferrer"
              >
                <b>Update address in CAQH</b>
              </Link>
            </div>
          </GuidanceCard>
        )}
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x1,
            width: '60%',
          }}
        >
          <SectionHeader>Additional information</SectionHeader>
        </div>
        <FormControl component={Checkbox} name="confirmAllNamesListed">
          I confirm I have listed all names under which I have been known by
          reference, licensing and/or educational institutions.
        </FormControl>
        <FormControl
          component={Select}
          name="gender"
          label="Gender"
          selectionMode="single"
          menuWidth="stretch"
        >
          {Object.entries(GENDERS_TO_DISPLAY_NAMES)
            .filter(([key, _]) => shouldIncludeProviderGender(key))
            .map(([key, val]) => (
              <Item key={key}>{val}</Item>
            ))}
        </FormControl>
        <FormControl
          component={Select}
          name="genderStatus"
          label="Gender Status"
          selectionMode="single"
          menuWidth="stretch"
        >
          {Object.entries(GENDER_STATUS_TO_DISPLAY_NAMES).map(([key, val]) => (
            <Item key={key}>{val}</Item>
          ))}
        </FormControl>
        <FormControl
          component={Select}
          name="citizenship"
          label="Citizenship"
          selectionMode="multiple"
          menuWidth="stretch"
        >
          {countries.map((country) => (
            <Item key={country.name}>{country.name}</Item>
          ))}
        </FormControl>
        {hispanicLatinxMigration ? (
          <FormControl
            component={Select}
            name="ethnicity"
            label="Ethnicity"
            selectionMode="multiple"
            menuWidth="stretch"
            onSelectionChange={(ethnicity: Set<string>) => {
              onMultiEthnicityChange(ethnicity);
            }}
          >
            {split_ethnicities.map((ethnicity) => (
              <Item key={ethnicity}>{ethnicity}</Item>
            ))}
          </FormControl>
        ) : (
          <FormControl
            component={Select}
            name="ethnicity"
            label="Ethnicity"
            selectionMode="single"
            menuWidth="stretch"
          >
            {ethnicities.map((ethnicity) => (
              <Item key={ethnicity}>{ethnicity}</Item>
            ))}
          </FormControl>
        )}
        <FormControl
          component={Select}
          name="race"
          label="Race"
          selectionMode="multiple"
          menuWidth="stretch"
        >
          {races.map((race) => (
            <Item key={race}>{race}</Item>
          ))}
        </FormControl>
        <FormControl
          component={PhoneNumberField}
          name={'personalPhone'}
          label="Personal Phone Number"
          helpText="You should enter the number that Headway can call to contact you."
        />
        <FormControl
          component={PhoneNumberField}
          name={'confidentialVoicemail'}
          label="Confidential Voicemail Number"
          helpText={
            <>
              If you do not have a confidential voice mailbox, please find
              resources{' '}
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://findheadway.zendesk.com/hc/en-us/articles/7290516629396"
              >
                here.
              </Link>
            </>
          }
        />
        <FormControl
          component={Checkbox}
          name="confidentialVoicemailAcknowledgement"
        >
          I agree to maintain and monitor a confidential voice mailbox for
          Headway clients, with a message disclosing that I do not provide
          emergency services and including appropriate crisis resources or
          protocol. I agree to communicate emergency contact and business hours
          expectations with my client upon meeting.
        </FormControl>
        <FormControl
          component={SocialSecurityField}
          name={'ssn'}
          label="Social Security Number"
          helpText="To get you credentialed, we ask for your social security number to help verify identity. Please know Headway will never share this information outside of credentialing applications."
        />
        <PhoneNumberField name="testEin" />
        <FormControl
          component={EinField}
          name={'ein'}
          label="EIN"
          optionalityText="Optional"
          helpText="An EIN is a tax number used for business with the format XX-XXXXXXX. It's different from an SSN."
        />
      </div>
    </>
  );
};

const updateVerifiableName = async (
  provider: ProviderRead,
  values: ProviderQuestionnaireRawData
) => {
  const shouldUpdateAliases =
    values.aliases?.length &&
    values.aliases?.every((alias) => !!alias.firstName && !!alias.lastName);
  if (!provider?.verifiableId) {
    return;
  }

  await VerifiableApi.patchVerifiableProvider(provider.verifiableId, {
    aliases: shouldUpdateAliases
      ? (values.aliases as VerifiableName[])
      : undefined,
    ...(values.firstName && { firstName: values.firstName }),
    ...(values.lastName && { lastName: values.lastName }),
  });
};
const stepConfig: QuestionnaireV2Step = {
  title: 'Personal Information',
  description:
    'In this section we ask that you share contact and location details.',
  recredDescription: 'Please verify the following fields:',
  Component: PersonalInformationStep,
  onBeforeSubmit: async (
    values,
    setBeforeSubmitError,
    setShowConfirmationModal,
    context
  ) => {
    const { provider } = context;
    updateVerifiableName(provider, values);

    if (
      provider.firstName !== values.firstName ||
      provider.middleName !== values.middleName ||
      provider.lastName !== values.lastName ||
      provider.suffix !== values.suffix ||
      provider.displayFirstName !== values.firstName ||
      provider.displayLastName !== values.lastName
    ) {
      await ProviderApi.updateProvider(provider.id, {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        suffix: values.suffix,
        displayFirstName: values.firstName,
        displayLastName: values.lastName,
      });
    }

    // Clear EIN value if same as SSN
    if (
      (values.ein?.match(/\d+/g) || []).join('') ===
      (values.ssn?.match(/\d+/g) || []).join('')
    ) {
      values.ein = '';
    }

    if (
      !!values.npiFirstName &&
      !!values.npiLastName &&
      (values.npiFirstName.toLowerCase() !== values.firstName?.toLowerCase() ||
        values.npiLastName.toLowerCase() !== values.lastName?.toLowerCase())
    ) {
      setShowConfirmationModal(true);
      return false;
    }

    // Validate that the SSN is non-empty and in the format XXX-XX-XXXX.
    // Note that we're doing this validation here rather than via formik because we only want to do
    // this validation on this step of the form rather than on every step. This is because we will
    // clear the SSN field from the UI after the user navigates to a different step so that we're
    // not exposing PHI in the frontend. The user will have to re-enter the SSN if they want to
    // submit this step again.
    const regex = new RegExp(/^(\d{3}-?\d{2}-?\d{4})$/);
    if (!values.ssn || !regex.test(values.ssn)) {
      setBeforeSubmitError(OnBeforeSubmitError.SsnInvalidError);
      return false;
    }

    return true;
  },
  getFormMeta: ({ providerQuestionnaire, hispanicLatinxMigration }) => {
    const validationSchema = Yup.object().shape({
      confirmAllNamesListed: Yup.boolean().oneOf(
        [true],
        'This question is required.'
      ),
      gender: Yup.string().required('Gender is required.'),
      genderStatus: Yup.string(),
      race: Yup.array().of(Yup.string()).required('Race is required.'),
      ethnicity: hispanicLatinxMigration
        ? Yup.array().of(Yup.string()).required('Ethnicity is required.')
        : Yup.string().required('Ethnicity is required.'),
      citizenship: Yup.array()
        .of(Yup.string())
        .required('Citizenship is required.'),
      stateOfResidency: Yup.string().required(
        'State of Residency is required.'
      ),
      countyOfResidency: Yup.string().required(
        'County of Residency is required.'
      ),
      personalPhone: Yup.string()
        .required('Personal phone number is required.')
        .matches(
          YUP_PHONE_MATCH,
          'Make sure the phone number follows this format: (###) ###-####.'
        ),
      confidentialVoicemail: Yup.string()
        .required('Practice phone number is required.')
        .matches(
          YUP_PHONE_MATCH,
          'Make sure the phone number follows this format: (###) ###-####.'
        )
        .test(
          'isPhone',
          'Monitored voicemail number is required.',
          (value) =>
            !!value && (value.match(/\d+/g) || []).join('').length === 10
        ),
      confidentialVoicemailAcknowledgement: Yup.boolean().oneOf(
        [true],
        'You must read and agree to the statement above.'
      ),
      ein: Yup.string()
        .test(
          'empty-check',
          'Make sure the ein number follows this format: XX-XXXXXXX.',
          (ein) => {
            // .matches([regex]) allows an empty string to pass
            // However, for some reason, calling schema.validate
            // on an empty string causes a ValidationError, so
            // we're now manually checking for an empty string
            if (!ein) return true;
            const regex = new RegExp(/^[0-9]\d?-\d{7}$/);
            return regex.test(ein);
          }
        )
        .nullable(),
      street1: Yup.string().required('Address is required.'),
      city: Yup.string().required('City is required.'),
      state: Yup.string().required('State is required.'),
      zip: Yup.string()
        .required('ZIP code is required.')
        .matches(/^\d+$/, 'Make sure the ZIP code consists of digits only.'),
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default stepConfig;

export const SsnInvalidError = () => (
  <GuidanceCard variant="error" layout="vertical">
    <BodyText>
      Please make sure the ssn number follows this format: XXX-XX-XXXX.
    </BodyText>
  </GuidanceCard>
);
