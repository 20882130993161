import { Avatar, Button, Divider, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useProviderById } from '~/legacy/hooks/useProviderById';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';
import { formatPatientName } from '@headway/shared/utils/patient';
import { theme } from '@headway/ui/theme';

import {
  hasContractAccess,
  hasRateAccess,
  isAdminImpersonatingProviderUser,
} from 'utils/access';

interface AccountMenuProps {
  user: any;
  impersonatingUser: any;
  provider: any;
  logout: () => void;
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  user,
  impersonatingUser,
  provider,
  logout,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Fetch the provider associated with the user, if that's not the selected provider.
  const { data: nonSelectedProvider } = useProviderById(
    {
      providerId: user.provider_id,
    },
    {
      enabled: !!user.provider_id && user.provider_id !== provider?.id,
    }
  );

  const userProvider =
    user.provider_id === provider?.id ? provider : nonSelectedProvider;

  const location = useLocation();
  const navigate = useNavigate();
  const isImpersonator = isAdminImpersonatingProviderUser(
    user,
    impersonatingUser
  );

  return (
    <React.Fragment>
      <Button
        color="gray"
        css={{
          borderRadius: '50%',
          padding: 0,
          minWidth: 0,
        }}
        aria-controls="user-menu"
        aria-haspopup="true"
        data-testid="myAccountButton"
        {...rest}
      >
        <Avatar
          src={transformCloudflareImg({
            src: userProvider?.photoUrl,
            width: 400,
          })}
          css={{
            border: `2px solid ${theme.color.primaryBackground}`,
            ':hover': {
              border: `2px solid ${theme.color.primary}`,
            },
            background: theme.color.primaryBackground,
            color: theme.color.primary,
            fontSize: theme.fontSize.base,
          }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          {user.display_first_name && user.display_last_name
            ? `${user.display_first_name[0]}${user.display_last_name[0]}`
            : user.first_name && user.last_name
            ? `${user.first_name[0]}${user.last_name[0]}`
            : null}
        </Avatar>
      </Button>
      <Menu
        id="user-menu"
        keepMounted={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        css={{
          '.MuiList-root': {
            paddingTop: 0,
          },
        }}
      >
        <div css={{ padding: theme.space.base }}>
          {formatPatientName(user) ? (
            <div
              css={{
                fontFamily: theme.fontFamily.canelaText,
                fontSize: theme.fontSize.base,
                color: theme.color.primary,
                marginBottom: theme.space.xs,
              }}
            >
              {formatPatientName(user)}
            </div>
          ) : null}
          <div
            css={{
              fontFamily: theme.fontFamily.postGrotesk,
            }}
          >
            {user.email}
          </div>
        </div>
        <Divider css={{ marginBottom: theme.space.xs }} />
        {isAdminImpersonatingProviderUser(user, impersonatingUser) &&
          (location.pathname === '/credentials' ? (
            !!provider.providerLicenseState.questionnaireCompletedOn && (
              <MenuItem component={Link} to="/insurance-status">
                Return to Dashboard
              </MenuItem>
            )
          ) : (
            <MenuItem component={Link} to="/credentials">
              View Intake Application
            </MenuItem>
          ))}
        {hasContractAccess(provider, user) ? (
          <MenuItem component={Link} to="/legal">
            Rates & Agreement
          </MenuItem>
        ) : hasRateAccess(provider, user) ? (
          <MenuItem component={Link} to="/legal">
            Rates
          </MenuItem>
        ) : null}
        <MenuItem
          component={(props: any) => (
            <a {...props} target="_blank" rel="noreferrer" />
          )}
          href={`${process.env.REACT_APP_MAIN_URL}/legal/policies`}
        >
          Policies
        </MenuItem>
        <Divider css={{ margin: `${theme.space.xs} 0` }} />
        <MenuItem
          component={(props: any) => (
            <a {...props} target="_blank" rel="noreferrer" />
          )}
          href="https://findheadway.zendesk.com/hc/en-us"
        >
          Help Center
        </MenuItem>
        {isImpersonator && (
          <MenuItem
            onClick={async () => {
              await AuthApi.undoImpersonateUser();

              // changes won't propagate properly unless we fully reload
              navigate('/auth/login');
              window.location.reload();
            }}
          >
            Stop Spoofing
          </MenuItem>
        )}
        <MenuItem
          component={(props: any) => <a {...props} target="_blank" />}
          href={`${process.env.REACT_APP_MAIN_URL}/contact?tags=sigmund_menu_entrypoint`}
        >
          Contact Us
        </MenuItem>
        <MenuItem onClick={logout}>Log Out</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
