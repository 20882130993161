import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  ProviderAppointmentReferralStatus,
  useClientReferralStatus,
} from '~/legacy/hooks/useClientReferralStatus';
import { getMissingPatientSchedulingInfo } from '~/legacy/utils/patient';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientMissingSchedulingInfoType } from '@headway/api/models/PatientMissingSchedulingInfoType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { ListRow } from '@headway/helix/List';
import { ListHeader } from '@headway/helix/ListHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { PROVIDER_REFERRAL_MANAGEMENT } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { formatPatientName } from '@headway/shared/utils/patient';

import { ClientAvatar } from './ClientAvatar';
import { ClientVerificationChip } from './ClientVerificationChip';
import { ClientWelcomeEmail } from './ClientWelcomeEmail';

interface ClientListRowProps {
  client: UserRead;
  claimReadiness?: UserClaimReadinessResponse;
  billingType: BillingType;
  isArchived?: boolean;
  handleToggleArchiveClick: () => void;
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
  page: number;
  provider: ProviderRead;
  search?: string;
}

export const ClientListRow = ({
  client,
  claimReadiness,
  billingType,
  isArchived,
  handleToggleArchiveClick,
  freezeReasonsByUser,
  page,
  provider,
  search,
}: ClientListRowProps) => {
  const providerReferralManagementFlagEnabled = useFlag(
    PROVIDER_REFERRAL_MANAGEMENT,
    false
  );

  const { data: clientReferralStatus } = useClientReferralStatus({
    providerId: provider.id,
    clientId: client.id,
  });

  const fullName = formatPatientName(client, {
    appendLegalName: true,
  });

  const clientNeedsInviteEmail = useMemo(() => {
    // when PRM flag is on and the user is a pending referral client,
    // providers should not see the invite email CTA
    if (
      providerReferralManagementFlagEnabled &&
      clientReferralStatus === ProviderAppointmentReferralStatus.PENDING
    ) {
      return false;
    }

    return (
      !client.isVerified ||
      getMissingPatientSchedulingInfo(client, billingType, claimReadiness).some(
        (missingInfo) =>
          [
            PatientMissingSchedulingInfoType.BILLING,
            PatientMissingSchedulingInfoType.FORMS,
            PatientMissingSchedulingInfoType.INSURANCE,
          ].find((requiredInfo) => requiredInfo === missingInfo)
      )
    );
  }, [
    providerReferralManagementFlagEnabled,
    clientReferralStatus,
    client,
    billingType,
    claimReadiness,
  ]);

  return (
    <ListRow>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
          }}
        >
          <ClientAvatar client={client} size="medium" />
          <div css={{ marginLeft: theme.spacing.x4, ...theme.stack.vertical }}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <div css={{ marginRight: theme.spacing.x2 }}>
                <Link
                  to={`/clients/${client.id}`}
                  component={RouterLink}
                  elementType="a"
                  data-testid={`client-${client.id}-link`}
                >
                  <ListHeader>{fullName}</ListHeader>
                </Link>
              </div>
              <ClientVerificationChip
                claimReadiness={claimReadiness}
                client={client}
                billingType={billingType}
                freezeReasonsByUser={freezeReasonsByUser}
                provider={provider}
              />
            </div>

            <SubBodyText>
              {`${client.email}${client.phone ? ` · ${client.phone}` : ''}`}
            </SubBodyText>
          </div>
        </div>
        <Button variant="secondary" onPress={handleToggleArchiveClick}>
          {isArchived ? 'Unarchive' : 'Archive'}
        </Button>
      </div>
      {clientNeedsInviteEmail ? (
        <div css={{ marginTop: theme.spacing.x3 }}>
          <ClientWelcomeEmail
            client={client}
            isArchivedPatient={isArchived}
            page={page}
            search={search}
          />
        </div>
      ) : null}
    </ListRow>
  );
};
