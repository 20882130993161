import { Global } from '@emotion/react';
import moment from 'moment-timezone';
import React, { ComponentType } from 'react';
import {
  Calendar as BigCalendar,
  CalendarProps as BigCalendarProps,
  momentLocalizer,
  stringOrDate,
  View,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { FeedbackSystemQuestionnaireRead } from '@headway/api/models/FeedbackSystemQuestionnaireRead';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventRecurrenceUpdateResponse } from '@headway/api/models/ProviderEventRecurrenceUpdateResponse';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { StateInsuranceCarrierRead } from '@headway/api/models/StateInsuranceCarrierRead';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import {
  RecurringUpdateApplyTo,
  RecurringUpdateType,
} from '@headway/shared/events/constants';
import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';
import { PROVIDER_REFERRAL_MANAGEMENT } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { theme } from '@headway/ui/theme';

import { Message, MessagesApi } from 'api/MessagesApi';
import { verifyInsuranceBenefitsProviderRedirectUrl } from 'constants/contactForm';
import { useMatchingProviderFrontendCarriersForEvents } from 'hooks/useMatchingProviderFrontendCarriersForEvents';
import { useProviderEventCache } from 'hooks/useProviderEvent';
import { useSelectedEvent } from 'hooks/useSelectedEvent';
import {
  UpdateProviderEventMutationArgs,
  UpdateRecurringInstanceMutationArgs,
  useDeleteProviderEventMutation,
  useDeleteRecurringInstanceAndAllFollowingMutation,
  useUpdateProviderEventMutation,
  useUpdateRecurringInstanceAndAllFollowingMutation,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';
import { useAuthStore } from 'stores/AuthStore';
import { useUiStore } from 'stores/UiStore';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';

import { CalendarContext } from './CalendarContext';
import {
  calendarStyles,
  eventPropGetter,
  managedAvailabilityStyle,
} from './calendarStyles';
import { InitialEventToDisplay } from './CalendarView';
import { AppointmentCancelModal } from './components/AppointmentCancelModal';
import { AppointmentConfirmation } from './components/AppointmentConfirmation/AppointmentConfirmation';
import { AppointmentDetail } from './components/AppointmentDetail/AppointmentDetail';
import { AppointmentFeedbackModal } from './components/AppointmentFeedbackModal';
import { AppointmentScheduleModal } from './components/AppointmentScheduleModal';
import { AppointmentToIntakeCallModal } from './components/AppointmentToIntakeCallModal';
import { AppointmentUndoConfirmedSessionModal } from './components/AppointmentUndoConfirmedSessionModal';
import { DuplicateAppointmentWarningModal } from './components/DuplicateAppointmentWarningModal';
import { ExternalUnavailabilityDetail } from './components/ExternalUnavailabilityDetail';
import { NotifyPatientConfirmationModal } from './components/NotifyPatientConfirmationModal';
import { PreventDragDropAppointmentModal } from './components/PreventDragDropAppointmentModal';
import { RecurringEventConfirmationModal } from './components/RecurringEventConfirmationModal';
import { ScheduleEventMenu } from './components/ScheduleEventMenu';
import { ScheduleUnavailabilityModal } from './components/ScheduleUnavailabilityModal';
import { TreatmentPlanAdoptionModal } from './components/TreatmentPlanAdoptionModal';
import { UnavailabilityDetail } from './components/UnavailabilityDetail';
import { deriveHWMAEvents } from './events';
import {
  getMinScheduleDate,
  isAppointment,
  isAvailability,
  isDuplicateAppointment,
  isExternalEvent,
  isIntakeCall,
  isUnavailability,
} from './events/util/events';
import {
  EstimatedLiveDatesWithCarrierMap,
  EstimatedLiveDateWithCarrier,
} from './LiveDateCalculator';
import { CalendarEvent } from './rbcComponents/CalendarEvent';
import { CalendarMonthDateHeader } from './rbcComponents/CalendarMonthDateHeader';
import { CalendarThreeDayWeek } from './rbcComponents/CalendarThreeDayWeek';
import { CalendarTimeGutterHeader } from './rbcComponents/CalendarTimeGutterHeader';
import { CalendarToolbar } from './rbcComponents/CalendarToolbar';
import { CalendarWeekHeader } from './rbcComponents/CalendarWeekHeader';
import {
  CalendarSlot,
  PatientBookedMessages,
  ProviderCalendarEvent,
} from './utils/Calendar';
import {
  useDeleteProviderEventMutationSideEffectsForCalendar,
  useDeleteRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar,
  useUpdateProviderEventSideEffectsForCalendar,
  useUpdateRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar,
} from './utils/queries';

const DragAndDropCalendar = withDragAndDrop<ProviderCalendarEvent, any>(
  BigCalendar as ComponentType<BigCalendarProps<ProviderCalendarEvent>>
);
const localizer = momentLocalizer(moment);

const HIDE_CANCELLED_FILTER_LS_KEY = 'HIDE_CANCELLED_FILTER_LS_KEY';
const localStorageHideCancelledAppointments = JSON.parse(
  window.localStorage.getItem(HIDE_CANCELLED_FILTER_LS_KEY) ?? 'false'
);

const CALENDAR_EXCLUSIONS_FILTER_LS_KEY = 'CALENDAR_EXCLUSIONS_FILTER_LS_KEY';
const localStorageCalendarExclusions = JSON.parse(
  window.localStorage.getItem(CALENDAR_EXCLUSIONS_FILTER_LS_KEY) ?? '[]'
);

interface CalendarProps {
  initialEventToDisplay?: InitialEventToDisplay;
  initialIsConfirmDetailsOpen: boolean;
  initialIsProgressNoteOnly: boolean;
  initialIsAppointmentToIntakeCallOpen: boolean;
  initialIsCancelOpen: boolean;
  initialIsRescheduling: boolean;

  expandedEvents: ProviderEventRead[];

  stateInsuranceCarriers: StateInsuranceCarrierRead[];
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap;
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier;
  maxEstimatedLiveDate: EstimatedLiveDateWithCarrier;

  viewDateRangeStart: Date;
  viewDateRangeEnd: Date;

  handleRangeChange: (
    range:
      | Date[]
      | {
          start: stringOrDate;
          end: stringOrDate;
        },
    view: View | undefined
  ) => void;
  handleViewChange: (view: View) => void;

  calendars: ProviderCalendarRead[];
  view: View;
  mobileView: boolean;
  scrollToTime: Date;
  timezone: string;

  patientsById: Record<number, UserRead>;
  patientMessages: PatientBookedMessages;

  carriersById: { [index: string]: FrontEndCarrierRead };
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
}

export const Calendar = ({
  initialEventToDisplay,
  initialIsConfirmDetailsOpen,
  initialIsProgressNoteOnly,
  initialIsAppointmentToIntakeCallOpen,
  initialIsCancelOpen,
  initialIsRescheduling,

  expandedEvents,

  stateInsuranceCarriers,
  estimatedLiveDates,
  minEstimatedLiveDate,
  maxEstimatedLiveDate,

  viewDateRangeStart,
  viewDateRangeEnd,

  handleRangeChange,
  handleViewChange,

  mobileView,
  calendars,
  view,
  timezone,
  scrollToTime,

  patientMessages,
  patientsById,

  carriersById,
  freezeReasonsByUser,
}: CalendarProps) => {
  // calendar state
  const [isDetailOpen, setIsDetailOpen] = React.useState(
    !initialIsConfirmDetailsOpen
  );
  const [isConfirmDetailsOpen, setIsConfirmDetailsOpen] = React.useState(
    initialIsConfirmDetailsOpen
  );
  const [isProgressNoteOnly, setIsProgressNoteOnly] = React.useState(
    initialIsProgressNoteOnly
  );
  const [isCancelOpen, setIsCancelOpen] = React.useState(initialIsCancelOpen);
  const [isScheduleOpen, setIsScheduleOpen] = React.useState(
    initialIsRescheduling
  );
  const [isRescheduling, setIsRescheduling] = React.useState(
    initialIsRescheduling
  );
  const [isAddingProgressNote, setIsAddingProgressNote] = React.useState(false);
  const [isAppointmentToIntakeCallOpen, setIsAppointmentToIntakeCallOpen] =
    React.useState(initialIsAppointmentToIntakeCallOpen);
  const [isRecurringConfirmOpen, setIsRecurringConfirmOpen] =
    React.useState(false);
  const [
    isDuplicateAppointmentWarningModalOpen,
    setIsDuplicateAppointmentWarningModalOpen,
  ] = React.useState(false);
  const [isNotifyPatientOpen, setIsNotifyPatientOpen] = React.useState(false);

  const [isSelectingEventType, setIsSelectingEventType] = React.useState(false);
  const [isUndoingConfirmedSession, setIsUndoingConfirmedSession] =
    React.useState(false);
  const [isAppointmentFeedbackModalOpen, setIsAppointmentFeedbackModalOpen] =
    React.useState(false);
  const [isBlockDragAndDropModalOpen, setIsBlockDragAndDropModalOpen] =
    React.useState(false);
  const [selectedAppointmentFeedbackSurvey] = React.useState<
    FeedbackSystemQuestionnaireRead | undefined
  >(undefined);
  const [
    isTreatmentPlanAdoptionModalOpen,
    setIsTreatmentPlanAdoptionModalOpen,
  ] = React.useState(false);
  const [isIntakeSession, setIsIntakeSession] = React.useState(false);

  const [isInitialEventHandled, setIsInitialEventHandled] =
    React.useState(false);

  // calendar filters
  const [hideCancelledAppointments, setHideCancelledAppointments] =
    React.useState<boolean>(localStorageHideCancelledAppointments);
  const [excludedCalendars, setExcludedCalendars] = React.useState<number[]>(
    localStorageCalendarExclusions
  );

  // date range and event info
  const [selectedDateIsValid, setSelectedDateIsValid] = React.useState(true);
  const [selectedDateRangeStart, setSelectedDateRangeStart] =
    React.useState<Date>(initialEventToDisplay?.startDate ?? new Date());
  let conflictedOpenings = new Set();

  // calendar interaction values
  const [calendarSlot, setCalendarSlot] = React.useState<
    CalendarSlot | undefined
  >(undefined);
  const [updateValues, setUpdateValues] = React.useState<
    ProviderEventUpdate | undefined
  >(undefined);

  // modal rendering data
  const [selectMenuTop, setSelectMenuTop] = React.useState<number | undefined>(
    undefined
  );
  const [selectMenuLeft, setSelectMenuLeft] = React.useState<
    number | undefined
  >(undefined);

  // event side affects
  const [messageType, setMessageType] = React.useState<
    keyof typeof Message.Type | undefined
  >(undefined);
  const [additionalMessageFields, setAdditionalMessageFields] =
    React.useState<any>(undefined);
  const [confirmationType, setConfirmationType] = React.useState<
    RecurringUpdateType | undefined
  >(undefined);

  const auth = useAuthStore();
  const ui = useUiStore();

  const providerReferralManagementFlagEnabled = useFlag(
    PROVIDER_REFERRAL_MANAGEMENT,
    false
  );

  const isIroncladBlockAppointmentConfirmation = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );

  const { setSelectedEventVirtualId } = React.useContext(SelectedEventContext);

  const { event: selectedEvent } = useSelectedEvent();

  const providerEventCache = useProviderEventCache();
  const updateEventMutation = useUpdateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useUpdateProviderEventSideEffectsForCalendar()),
  });
  const updateEventAndAllFollowingMutation =
    useUpdateRecurringInstanceAndAllFollowingMutation({
      sideEffects: new SideEffectsBuilder<
        ProviderEventRecurrenceUpdateResponse,
        unknown,
        UpdateRecurringInstanceMutationArgs
      >()
        .add({
          onSuccess: (result) => {
            providerEventCache.set(
              { eventIdOrVirtualId: result.updatedInstance.virtualId },
              result.updatedInstance
            );
          },
        })
        .merge(
          useUpdateRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar()
        ),
    });
  const deleteEventMutation = useDeleteProviderEventMutation({
    sideEffects: new SideEffectsBuilder<{}, unknown, string | number>()
      .add({
        onSuccess: (data, virtualId) => {
          if (selectedEvent?.virtualId === virtualId) {
            setSelectedEventVirtualId(undefined);
          }
          providerEventCache.set({ eventIdOrVirtualId: virtualId }, undefined);
        },
      })
      .merge(useDeleteProviderEventMutationSideEffectsForCalendar()),
  });

  const deleteRecurringInstanceAndAllFollowingMutation =
    useDeleteRecurringInstanceAndAllFollowingMutation({
      sideEffects: new SideEffectsBuilder<
        ConcreteProviderEventRead,
        unknown,
        string
      >()
        .add({
          onSuccess: (data, virtualId) => {
            if (selectedEvent?.virtualId === virtualId) {
              setSelectedEventVirtualId(undefined);
            }
            providerEventCache.set(
              { eventIdOrVirtualId: virtualId },
              undefined
            );
          },
        })
        .merge(
          useDeleteRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar()
        ),
    });

  React.useEffect(() => {
    if (!isInitialEventHandled && initialEventToDisplay) {
      const selectedEvent = expandedEvents.find((event) => {
        return (
          (event.originalRecurringEventId === initialEventToDisplay.eventId ||
            event.id === initialEventToDisplay.eventId) &&
          new Date(event.startDate!).getTime() ===
            initialEventToDisplay.startDate.getTime()
        );
      });
      if (!selectedEvent) {
        return;
      }

      setSelectedEventVirtualId(selectedEvent.virtualId);
      setIsInitialEventHandled(true);
    }
  }, [
    expandedEvents,
    isInitialEventHandled,
    initialEventToDisplay,
    initialIsConfirmDetailsOpen,
    initialIsAppointmentToIntakeCallOpen,
    initialIsCancelOpen,
    initialIsRescheduling,
    setSelectedEventVirtualId,
  ]);

  const handleUpdateCheckDuplicate = async (
    event: ProviderEventRead,
    eventValues: ProviderEventUpdate
  ) => {
    if (!event) {
      return false;
    }

    const updatedEvent = await updateEventMutation.mutateAsync({
      eventIdOrVirtualId: event.virtualId,
      update: eventValues,
    });

    if (!updatedEvent) {
      return false;
    }

    if (isDuplicateAppointment(expandedEvents, updatedEvent)) {
      setIsDuplicateAppointmentWarningModalOpen(true);
    }
    return true;
  };

  const isDateValid = async (
    eventDate: Date,
    eventType: any,
    patient: UserRead | null,
    event: ProviderCalendarEvent | null
  ) => {
    const comparableStartDate: Date = new Date(
      new Date(eventDate).setHours(0, 0, 0, 0)
    );
    let matchingProviderFrontEndCarrier: ProviderFrontEndCarrierRead | null =
      null;
    if (patient && patient.activeUserInsurance) {
      matchingProviderFrontEndCarrier =
        await ProviderApi.getMatchingProviderFrontEndCarrier(provider.id, {
          patient_user_id: patient.id,
          appointment_id: event?.providerAppointment?.id,
        });
    }
    const minLiveOnDate = getMinScheduleDate(
      eventType,
      patient ?? undefined,
      auth.provider,
      stateInsuranceCarriers,
      estimatedLiveDates,
      minEstimatedLiveDate,
      matchingProviderFrontEndCarrier
    );

    if (!minLiveOnDate) {
      return false;
    }

    const comparableMinEstimatedLiveOnDate = new Date(
      new Date(minLiveOnDate).setHours(0, 0, 0, 0)
    );
    const earliestActiveLiveOn = auth.provider.earliestActiveLiveOn;

    return earliestActiveLiveOn
      ? new Date(earliestActiveLiveOn) <= comparableStartDate
      : comparableMinEstimatedLiveOnDate <= comparableStartDate;
  };

  const getAssumedDurationForEventType = (type: ProviderEventType) => {
    // assume intake calls are 15 minutes, sessions are 45 minutes, and availabilities are the provider's intake session length prefrence
    return type === ProviderEventType.INTAKE_CALL
      ? 15
      : type === ProviderEventType.BUSY
      ? 60
      : type === ProviderEventType.AVAILABILITY
      ? auth.provider.intakeSessionLengthMinutes
      : 45;
  };

  const handleSelectEvent = (event: ProviderCalendarEvent) => {
    if (
      (isExternalEvent(event) && event.type !== ProviderEventType.BUSY) ||
      isAvailability(event)
    ) {
      return;
    }

    setSelectedEventVirtualId(event.virtualId);
    setIsDetailOpen(true);
  };

  const handleCreateEventClick = (type: ProviderEventType) => {
    /**
     * type: event type like availability/ appointment
     */
    const eventDate = moment();
    const startDate = calendarSlot
      ? calendarSlot.startDate
      : eventDate
          .minute(5 * Math.ceil(moment().minute() / 5))
          .startOf('minute')
          .toDate();
    const assumedDuration = getAssumedDurationForEventType(type);
    const endDate = moment(startDate).add(assumedDuration, 'minutes').toDate();

    setCalendarSlot({
      startDate,
      endDate,
      type,
    });
    setIsScheduleOpen(true);
    setIsRescheduling(false);
  };

  const handleSlotSelect = async ({
    action,
    box,
    bounds,
    start,
    end,
    ...rest
  }: any) => {
    // box is defined for slot clicks, bounds is defined for slot drags
    const selectMenuTop = box ? box.y : bounds.bottom;
    const selectMenuLeft = box ? box.x : bounds.left;

    const calendarSlot: CalendarSlot = {
      startDate: start,
      endDate: box ? moment(start).add(1, 'hours').toDate() : end,
    };

    setCalendarSlot(calendarSlot);
    setIsSelectingEventType(true);
    setSelectMenuTop(selectMenuTop);
    setSelectMenuLeft(selectMenuLeft);
    const isStartDateValid = await isDateValid(
      start,
      ProviderEventType.AVAILABILITY,
      null,
      null
    );
    setSelectedDateIsValid(isStartDateValid);
  };

  const handleEventTypeClick = (type: ProviderEventType) => {
    if (!calendarSlot) {
      return;
    }

    const assumedDuration = getAssumedDurationForEventType(type);
    const endDate = moment(calendarSlot.startDate)
      .add(assumedDuration, 'minutes')
      .toDate();

    setCalendarSlot({
      ...calendarSlot,
      endDate,
      type,
    });
    setIsScheduleOpen(true);
    setIsRecurringConfirmOpen(false);
  };

  const handleScheduleEventFromDetail = (
    eventType: ProviderEventType,
    patientUserId: number,
    startDate: Date
  ) => {
    const assumedDuration = getAssumedDurationForEventType(eventType);

    setCalendarSlot({
      type: eventType,
      patientUserId: patientUserId,
      startDate,
      endDate: moment(startDate).add(assumedDuration, 'minutes').toDate(),
    });

    setIsScheduleOpen(true);
    setIsRescheduling(false);
  };

  const handleCalendarUpdateEvent = async ({
    event,
    start,
    end,
  }: {
    event: ProviderCalendarEvent;
    start: stringOrDate;
    end: stringOrDate;
  }) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    //  don't allow updating an appointment outside of 30 days
    //  if providers have ironclad agreements
    const shouldBlockProvider =
      shouldBlockProviderWithIroncladAgreement(
        isIroncladBlockAppointmentConfirmation,
        startDate
      ) && isAppointment(event);

    if (shouldBlockProvider) {
      setIsBlockDragAndDropModalOpen(true);
      return;
    }

    // don't allow updating canceled or confirmed events via the calendar
    if (
      event.providerAppointment?.status ===
        ProviderAppointmentStatus.CANCELED ||
      event.providerAppointment?.status ===
        ProviderAppointmentStatus.DETAILS_CONFIRMED ||
      (new Date(event.startDate!).getTime() === startDate.getTime() &&
        new Date(event.endDate!).getTime() === endDate.getTime())
    ) {
      return;
    }

    // Check if the date is valid for the event,
    // not worrying about BUSY events.
    if (
      !(event.type === ProviderEventType.BUSY) &&
      !(await isDateValid(
        startDate,
        event.type,
        event.patientUserId ? patientsById[event.patientUserId] : null,
        event
      ))
    ) {
      return;
    }

    setSelectedEventVirtualId(event.virtualId);
    const updateValues = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    } as ProviderEventUpdate;
    checkRecurringAndUpdate(updateValues, event);
  };

  const handleNavigateEvent = (newDate: Date) => {
    setSelectedDateRangeStart(moment(newDate).toDate());
  };

  const handleClose = () => {
    setIsCancelOpen(false);
    setIsScheduleOpen(false);
    setIsRescheduling(false);
    setIsAppointmentToIntakeCallOpen(false);
    setIsNotifyPatientOpen(false);
    setIsConfirmDetailsOpen(false);
    setIsProgressNoteOnly(false);
    setIsUndoingConfirmedSession(false);
    setIsBlockDragAndDropModalOpen(false);
    setCalendarSlot(undefined);
    setUpdateValues(undefined);

    // remove paste event from ProgressNotesForm
    document.removeEventListener('paste', () => {});
  };

  const handleDetailClose = () => setIsDetailOpen(false);

  const handleConfirmDetailsClick = () => {
    setIsConfirmDetailsOpen(true);
    setIsAddingProgressNote(false);
  };

  const handleAddProgressNoteClick = () => {
    setIsConfirmDetailsOpen(true);
    setIsAddingProgressNote(true);
  };

  const handleRescheduleClick = () => {
    setIsScheduleOpen(true);
    setIsRescheduling(true);
  };

  const handleCancelClick = () => setIsCancelOpen(true);

  const handleAppointmentToIntakeCallClick = () => {
    setIsAppointmentToIntakeCallOpen(true);
  };

  const handleUndoConfirmedSessionClick = () => {
    setIsUndoingConfirmedSession(true);
  };

  const isSelectedEventInView = () => {
    const isSelectedEventNotCancelled = !(
      hideCancelledAppointments &&
      selectedEvent?.providerAppointment?.status ===
        ProviderAppointmentStatus.CANCELED
    );
    return (
      isSelectedEventNotCancelled &&
      !!selectedEvent &&
      viewDateRangeStart.getTime() <
        new Date(selectedEvent.startDate!).getTime() &&
      new Date(selectedEvent.endDate!).getTime() < viewDateRangeEnd.getTime()
    );
  };

  const checkNotify = (messageType: string, additionalMessageFields: any) => {
    // don't ask custom billers if they want to notify patients
    if (auth.provider.customBilling) {
      return;
    }

    setIsNotifyPatientOpen(true);
    setMessageType(messageType as keyof typeof Message.Type);
    setAdditionalMessageFields(additionalMessageFields);
  };

  const notifyForEvent = async (
    event: ProviderEventRead | undefined,
    content: string,
    type: keyof typeof Message.Type,
    additionalFields: { is_recurring_appointment_cancellation: boolean }
  ) => {
    if (!event) {
      return;
    }

    // don't notify patients of custom billers
    if (auth.provider.customBilling) {
      return;
    }
    try {
      await MessagesApi.create({
        patient_user_id: event.patientUserId,
        provider_id: event.providerId,
        message: {
          type,
          to_provider: false,
          content,
          special: false,
          provider_event_id: event.id,
        },
        additional_fields: additionalFields,
      });
    } catch (error: AnyTS4TryCatchUnknownError) {
      ui.showWarningSnackbar(error.toString());
    }
  };

  const notifyForSelectedEvent = async (
    content: string,
    type: keyof typeof Message.Type,
    additionalFields: any
  ) => {
    return notifyForEvent(selectedEvent, content, type, additionalFields);
  };

  const checkRecurringAndUpdate = async (
    updateValues: ProviderEventUpdate,
    originalEvent: ProviderEventRead
  ) => {
    // if this is a recurring event confirm what we're editing
    if (originalEvent.recurrence) {
      setIsRecurringConfirmOpen(true);
      setConfirmationType(RecurringUpdateType.UPDATE);
      setUpdateValues(updateValues);
    } else {
      const oldStartDate = originalEvent.startDate;
      const oldTimeZone = originalEvent.timeZone;

      const shouldNotify = await handleUpdateCheckDuplicate(
        originalEvent,
        updateValues
      );
      if (
        shouldNotify &&
        (isAppointment(originalEvent) || isIntakeCall(originalEvent))
      ) {
        checkNotify(Message.Type.PROVIDER_RESCHEDULE, {
          old_start_date: oldStartDate,
          old_time_zone: oldTimeZone,
        });
      }
    }
  };

  const checkRecurringAndDelete = async () => {
    // if this is a recurring event, confirm what we're editing
    if (selectedEvent?.recurrence) {
      setIsRecurringConfirmOpen(true);
      setConfirmationType(RecurringUpdateType.DELETE);
    } else {
      if (selectedEvent) {
        await deleteEventMutation.mutateAsync(selectedEvent.virtualId);
      }
    }
  };

  const handleRecurringEventConfirmation = async ({
    recurringUpdateApplyTo,
  }: {
    recurringUpdateApplyTo: RecurringUpdateApplyTo;
  }) => {
    const oldStartDate = selectedEvent?.startDate;
    const oldTimeZone = selectedEvent?.timeZone;
    let shouldNotify;

    if (selectedEvent) {
      if (confirmationType === RecurringUpdateType.UPDATE) {
        if (updateValues) {
          if (recurringUpdateApplyTo === RecurringUpdateApplyTo.THIS_EVENT) {
            shouldNotify = await updateEventMutation.mutateAsync({
              eventIdOrVirtualId: selectedEvent.virtualId,
              update: updateValues,
            });
          } else {
            shouldNotify = await updateEventAndAllFollowingMutation.mutateAsync(
              {
                virtualId: selectedEvent.virtualId,
                update: updateValues,
              }
            );
          }
        }
      } else {
        if (recurringUpdateApplyTo === RecurringUpdateApplyTo.THIS_EVENT) {
          shouldNotify = await deleteEventMutation.mutateAsync(
            selectedEvent.virtualId
          );
        } else {
          shouldNotify =
            await deleteRecurringInstanceAndAllFollowingMutation.mutateAsync(
              selectedEvent.virtualId
            );
        }
      }
    }

    setIsRecurringConfirmOpen(false);

    if (
      shouldNotify &&
      selectedEvent &&
      (isAppointment(selectedEvent) || isIntakeCall(selectedEvent))
    ) {
      checkNotify(Message.Type.PROVIDER_RESCHEDULE, {
        old_start_date: oldStartDate,
        old_time_zone: oldTimeZone,
      });
    }
  };

  const handleOpenContactFormInsuranceIssues = () => {
    setIsConfirmDetailsOpen(false);
    window.open(
      verifyInsuranceBenefitsProviderRedirectUrl +
        `&clientId=${selectedEvent?.patientUserId}`,
      '_blank'
    );
  };

  const handleToggleCancelledAppointments = () => {
    const toggledValue = !hideCancelledAppointments;
    window.localStorage.setItem(
      HIDE_CANCELLED_FILTER_LS_KEY,
      toggledValue.toString()
    );
    setHideCancelledAppointments(
      (hideCancelledAppointments) => !hideCancelledAppointments
    );
  };

  const Toolbar = (props: any) => {
    const { setSelectedEventVirtualId } =
      React.useContext(SelectedEventContext);

    const handleTaskConfirmDetailsClick = (event: ProviderEventRead) => {
      setSelectedEventVirtualId(event.virtualId);
      setIsConfirmDetailsOpen(true);
    };

    const handleTaskCancelClick = (event: ProviderEventRead) => {
      setSelectedEventVirtualId(event.virtualId);
      setIsCancelOpen(true);
    };

    const handleIntakeCallCancelClick = (event: ProviderEventRead) => {
      setSelectedEventVirtualId(event.virtualId);
      setIsCancelOpen(true);
    };

    const handleNotificationAppointmentToIntakeCallClick = (
      event: ProviderEventRead
    ) => {
      setSelectedEventVirtualId(event.virtualId);
      setIsAppointmentToIntakeCallOpen(true);
    };

    return (
      <CalendarToolbar
        {...props}
        handleCreateEventClick={handleCreateEventClick}
        handleIntakeCallClick={handleNotificationAppointmentToIntakeCallClick}
        handleIntakeCallCancelClick={handleIntakeCallCancelClick}
        handleTaskConfirmDetailsClick={handleTaskConfirmDetailsClick}
        handleTaskCancelClick={handleTaskCancelClick}
        handleDetailClose={handleDetailClose}
        handleToggleCancelledAppointments={handleToggleCancelledAppointments}
        onFilterByCalendar={(exclusions) => {
          setExcludedCalendars(exclusions);
          window.localStorage.setItem(
            CALENDAR_EXCLUSIONS_FILTER_LS_KEY,
            JSON.stringify(exclusions)
          );
        }}
        carriersById={carriersById}
      />
    );
  };

  const CalendarEventWrapper = ({
    event,
    ...props
  }: {
    event: ProviderCalendarEvent;
  }) => {
    const calendarContext = React.useContext(CalendarContext);
    const {
      freezeReasonsByUser,
      eventIdsToMatchingProviderFrontEndCarriers,
      isProviderFrontendCarriersLoading,
    } = calendarContext;
    return (
      <CalendarEvent
        {...props}
        event={event}
        freezeReasonsByUser={freezeReasonsByUser}
        eventIdsToMatchingProviderFrontEndCarriers={
          eventIdsToMatchingProviderFrontEndCarriers
        }
        isProviderFrontendCarriersLoading={isProviderFrontendCarriersLoading}
      />
    );
  };

  let eventsToRender: ProviderCalendarEvent[];
  let workingHours: ProviderCalendarEvent[];

  const filters = {
    APPOINTMENT_STATUS: {
      hideCanceled: hideCancelledAppointments,
    },
    CALENDAR_VIEW: {
      calendarView: view,
    },
    EXCLUDE_PROVIDER_CALENDARS: {
      providerCalendarIds: excludedCalendars,
    },
  };

  ({ workingHours, events: eventsToRender } = deriveHWMAEvents(
    expandedEvents,
    filters
  ));

  const provider = auth.provider;
  const selectedPatient =
    selectedEvent?.patientUserId && patientsById
      ? patientsById[selectedEvent.patientUserId]
      : undefined;

  const {
    eventIdsToMatchingProviderFrontEndCarriers,
    isLoading: isProviderFrontendCarriersLoading,
  } = useMatchingProviderFrontendCarriersForEvents(provider.id, eventsToRender);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_ID) {
    throw new Error('REACT_APP_GOOGLE_MAPS_API_ID expected');
  }

  return (
    <React.Fragment>
      <Global styles={[calendarStyles, managedAvailabilityStyle]} />
      <CalendarContext.Provider
        value={{
          filters: {
            hideCancelledAppointments,
            excludedCalendars,
          },
          calendars,
          view,
          mobileView,
          patientMessages,
          conflictedOpenings,
          carriersById,
          freezeReasonsByUser,
          eventIdsToMatchingProviderFrontEndCarriers,
          isProviderFrontendCarriersLoading,
        }}
      >
        <DragAndDropCalendar
          date={selectedDateRangeStart}
          selectable={true}
          selected={selectedEvent}
          resizable={view !== 'month'}
          step={15}
          timeslots={4}
          localizer={localizer}
          enableAutoScroll={false}
          scrollToTime={scrollToTime}
          events={eventsToRender}
          backgroundEvents={workingHours}
          onSelectSlot={handleSlotSelect}
          onSelectEvent={handleSelectEvent}
          onEventResize={handleCalendarUpdateEvent}
          onEventDrop={handleCalendarUpdateEvent}
          onNavigate={handleNavigateEvent}
          onRangeChange={handleRangeChange}
          onView={handleViewChange}
          eventPropGetter={(event: ProviderEventRead) =>
            eventPropGetter(event, providerReferralManagementFlagEnabled)
          }
          components={{
            event: CalendarEventWrapper,
            week: {
              header: CalendarWeekHeader,
            },
            work_week: {
              header: CalendarWeekHeader,
            },
            month: {
              dateHeader: CalendarMonthDateHeader as any,
            },
            toolbar: Toolbar,
            timeGutterHeader: () =>
              CalendarTimeGutterHeader({ timeZone: timezone }) as any,
          }}
          startAccessor={(event: ProviderCalendarEvent) =>
            new Date(event.startDate!)
          }
          endAccessor={(event: ProviderCalendarEvent) =>
            new Date(event.endDate!)
          }
          defaultView={view}
          resizableAccessor={(e) => {
            return !isExternalEvent(e);
          }}
          draggableAccessor={(e) => {
            return !isExternalEvent(e);
          }}
          views={{
            month: true,
            week: (mobileView ? CalendarThreeDayWeek : true) as any,
            work_week: true,
            day: true,
            agenda: false,
          }}
          className={`rbc-view-${view}`}
          css={{
            [theme.media.medium]: {
              padding: `0 ${theme.space.base}`,
            },
          }}
        />
      </CalendarContext.Provider>
      <ScheduleEventMenu
        open={isSelectingEventType}
        top={selectMenuTop}
        left={selectMenuLeft}
        onClose={() => setIsSelectingEventType(false)}
        handleEventTypeClick={handleEventTypeClick}
        provider={provider}
        selectedDateIsValid={selectedDateIsValid}
        minEstimatedLiveDate={minEstimatedLiveDate}
        patient={undefined}
      />
      <RecurringEventConfirmationModal
        open={isRecurringConfirmOpen}
        onClose={() => setIsRecurringConfirmOpen(false)}
        confirmationType={confirmationType}
        eventTypeLabel={
          isAppointment(selectedEvent)
            ? { single: 'session', plural: 'sessions' }
            : isIntakeCall(selectedEvent)
            ? { single: 'phone consultation', plural: 'phone consultations' }
            : isUnavailability(selectedEvent)
            ? { single: 'unavailability', plural: 'unavailabilities' }
            : { single: 'opening', plural: 'openings' }
        }
        onSubmit={handleRecurringEventConfirmation}
      />
      <NotifyPatientConfirmationModal
        open={isNotifyPatientOpen}
        messageType={messageType}
        additionalMessageFields={additionalMessageFields}
        notifyForSelectedEvent={notifyForSelectedEvent}
        onClose={() => setIsNotifyPatientOpen(false)}
      />
      <ScheduleUnavailabilityModal
        isOpen={
          isScheduleOpen &&
          [ProviderEventType.BUSY].includes(
            isRescheduling ? selectedEvent?.type : calendarSlot?.type
          )
        }
        onClose={handleClose}
        calendarSlot={calendarSlot}
        isRescheduling={isRescheduling}
        timeZone={timezone}
      />
      <UnavailabilityDetail
        isOpen={
          isDetailOpen &&
          selectedEvent?.type === ProviderEventType.BUSY &&
          selectedEvent?.channel !== ProviderEventChannel.EXTERNAL_CALENDAR
        }
        onClose={handleDetailClose}
        checkRecurringAndDelete={checkRecurringAndDelete}
        handleRescheduleClick={handleRescheduleClick}
      />
      <ExternalUnavailabilityDetail
        isOpen={
          isDetailOpen &&
          isSelectedEventInView() &&
          selectedEvent?.type === ProviderEventType.BUSY &&
          selectedEvent?.channel === ProviderEventChannel.EXTERNAL_CALENDAR
        }
        onClose={handleDetailClose}
      />
      <AppointmentDetail
        open={
          isDetailOpen &&
          isSelectedEventInView() &&
          (selectedEvent?.type === ProviderEventType.APPOINTMENT ||
            selectedEvent?.type === ProviderEventType.INTAKE_CALL) &&
          !isConfirmDetailsOpen
        }
        onClose={handleDetailClose}
        handleRescheduleClick={handleRescheduleClick}
        handleConfirmDetailsClick={handleConfirmDetailsClick}
        handleCancelClick={handleCancelClick}
        handleAddProgressNoteClick={handleAddProgressNoteClick}
        handleScheduleEventClick={handleScheduleEventFromDetail}
        handleIntakeCallClick={handleAppointmentToIntakeCallClick}
        handleUndoConfirmedSessionClick={handleUndoConfirmedSessionClick}
        eventIdsToMatchingProviderFrontEndCarriers={
          eventIdsToMatchingProviderFrontEndCarriers
        }
      />
      <AppointmentScheduleModal
        open={
          isScheduleOpen &&
          [
            ProviderEventType.APPOINTMENT,
            ProviderEventType.INTAKE_CALL,
          ].includes(isRescheduling ? selectedEvent?.type : calendarSlot?.type)
        }
        onClose={handleClose}
        calendarSlot={calendarSlot}
        isRescheduling={isRescheduling}
        timeZone={timezone}
        checkNotify={checkNotify}
        minEstimatedLiveDate={minEstimatedLiveDate}
        maxEstimatedLiveDate={maxEstimatedLiveDate}
        estimatedLiveDates={estimatedLiveDates}
        stateInsuranceCarriers={stateInsuranceCarriers}
        eventIdsToMatchingProviderFrontEndCarriers={
          eventIdsToMatchingProviderFrontEndCarriers
        }
      />
      {selectedEvent && selectedPatient && isConfirmDetailsOpen && (
        <AppointmentConfirmation
          open={isConfirmDetailsOpen}
          onClose={handleClose}
          eventVirtualId={selectedEvent.virtualId}
          patientId={selectedPatient.id}
          progressNoteOnly={isAddingProgressNote || isProgressNoteOnly}
          onOpenContactFormInsuranceIssues={
            handleOpenContactFormInsuranceIssues
          }
          openTreatmentPlanAdoptionModal={(isIntakeSession) => {
            setIsTreatmentPlanAdoptionModalOpen(true);
            setIsIntakeSession(isIntakeSession);
          }}
        />
      )}
      <AppointmentFeedbackModal
        open={isAppointmentFeedbackModalOpen}
        selectedAppointmentFeedbackSurvey={selectedAppointmentFeedbackSurvey}
        onClose={() => setIsAppointmentFeedbackModalOpen(false)}
      />
      <AppointmentCancelModal
        open={
          isCancelOpen &&
          (selectedEvent?.type === ProviderEventType.APPOINTMENT ||
            selectedEvent?.type === ProviderEventType.INTAKE_CALL)
        }
        onClose={handleClose}
        notifyForEvent={notifyForEvent}
        handleDetailClose={handleDetailClose}
        hideCancelledAppointments={hideCancelledAppointments}
      />
      <AppointmentToIntakeCallModal
        open={
          isAppointmentToIntakeCallOpen &&
          selectedEvent?.type === ProviderEventType.APPOINTMENT
        }
        onClose={handleClose}
        notifyForSelectedEvent={notifyForSelectedEvent}
      />
      <AppointmentUndoConfirmedSessionModal
        open={
          isUndoingConfirmedSession &&
          selectedEvent?.type === ProviderEventType.APPOINTMENT
        }
        onClose={handleClose}
      />
      <DuplicateAppointmentWarningModal
        open={isDuplicateAppointmentWarningModalOpen}
        onClose={() => setIsDuplicateAppointmentWarningModalOpen(false)}
      />
      <PreventDragDropAppointmentModal
        open={isBlockDragAndDropModalOpen}
        onClose={handleClose}
      />
      {selectedEvent?.patientUserId && !provider.optOutTreatmentPlanUpsell && (
        <TreatmentPlanAdoptionModal
          open={isTreatmentPlanAdoptionModalOpen}
          onClose={() => setIsTreatmentPlanAdoptionModalOpen(false)}
          clientId={selectedEvent.patientUserId}
          isIntakeSession={isIntakeSession}
          provider={provider}
        />
      )}
    </React.Fragment>
  );
};
