import { CloseTwoTone } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogProps,
  Grow,
  GrowProps,
  IconButton,
} from '@mui/material';
import { useProviderPatient } from 'hooks';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { MambaApiError } from '@headway/api/axios.config';
import { CalendarEventType } from '@headway/api/models/CalendarEventType';
import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import {
  ConfirmSessionButtonClickedEvent,
  SignNoteButtonClickedEvent,
} from '@headway/avo';
import { Button } from '@headway/helix/Button';
import { IconCheck } from '@headway/helix/icons/Check';
import { Modal as HelixModal, ModalContent } from '@headway/helix/Modal';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';
import {
  MULTI_STATE_CREDENTIALING_BETA,
  PROMS_ONLY_RATE_BOOST,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag, useFlagsClient } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { isStatusInNetwork } from '@headway/shared/utils/insuranceUtils';
import { toaster } from '@headway/ui/ToastManager';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey } from 'hooks/usePaginatedProviderDocumentRequestsAndRemediations';
import { usePatientAssessmentRecurrenceSchedules } from 'hooks/usePatientAssessmentRecurrenceSchedules';
import { useProviderEvent } from 'hooks/useProviderEvent';
import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import {
  doesAppointmentHaveCrisisCode,
  insuranceStatusAllowedWithCrisisCodeException,
  isDetailsConfirmed,
  isPast,
} from 'views/Calendar/events/util/events';
import { useDocumentationRequirement } from 'views/Calendar/utils/documentationRequirement';
import { useTreatmentPlanRequirements } from 'views/Clients/TreatmentPlan/Requirements/TreatmentPlanRequirements';
import { PAGE_LIMIT } from 'views/Home/ChartReviewCombinedCard';

import { ConfirmSessionOnDraftModal } from './ConfirmSessionOnDraftModal';
import { NoteJson } from './Forms/ProgressNotes/templateView/schemaRenderer/versions/types';
import { GroupPracticeAdminBanner } from './GroupPracticeAdminBanner';
import {
  AppointmentContext,
  AppointmentState,
} from './stores/AppointmentContext';
import {
  ProgressNoteContext,
  ProgressNotePermissions,
  ProgressNoteState,
} from './stores/ProgressNotesContext';
import { SubmitAddendumWithoutChangesForm } from './SubmitAddendumWithoutChangesForm';
import {
  TreatmentPlanRequirementWarningBanner,
  useTreatmentPlanRequirementWarning,
} from './TreatmentPlanRequirementWarning';

const GrowTransition = React.forwardRef(function Transition(
  props: GrowProps,
  ref
) {
  return <Grow ref={ref} {...props} />;
});

interface ModalBaseProps {
  onModalClose: () => void;
  onSubmit?: () => void;
  isSavingInProgress?: boolean;
  isSavingAndClosing?: boolean;
  isSubmitting?: boolean;
  eventVirtualId: string;
  checkAttestation: () => Promise<boolean>;
  resetAttestation: () => Promise<void>;
  provider: ProviderRead;
  patient: UserRead;
  onOpenTreatmentPlanAdoptionModal?: (isIntakeSession: boolean) => void;
  onOpenTreatmentPlanModal?: (isIntakeSession: boolean) => void;
  setOnCloseTreatmentPlanModal: Dispatch<
    SetStateAction<(() => void) | undefined>
  >;
  setIsSwoopUpsellModalOpened: (isSwoopUpsellModalOpened: boolean) => void;
}

export type ModalProps = Omit<DialogProps, 'title'> &
  Pick<DialogContentProps, 'dividers'> &
  ModalBaseProps;

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  open,
  onModalClose,
  isSavingInProgress,
  children,
  eventVirtualId,
  checkAttestation,
  resetAttestation,
  provider,
  patient,
  onOpenTreatmentPlanAdoptionModal,
  onOpenTreatmentPlanModal,
  setOnCloseTreatmentPlanModal,
  setIsSwoopUpsellModalOpened,
}) => {
  const { user } = useAuthStore();
  const LDClient = useFlagsClient();
  const queryClient = useQueryClient();

  const {
    progressNoteState,
    updateProgressNoteState,
    progressNoteType,
    updateNoteToEmptyIfNeeded,
    progressNote,
    metadataInfo,
    selectedTemplate,
    notePermissions,
    documentRemediation,
  } = useContext(ProgressNoteContext);

  const {
    appointmentState,
    onSessionDetailsConfirm,
    updateAttachments,
    insuranceStatus,
    isInsuranceAppointment,
    selectedCptCodes,
  } = useContext(AppointmentContext);

  const isIntakeSession = selectedCptCodes?.includes('90791');

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);
  const { isMSCGuardrailRestriction } = useMSCGuardrail();
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });

  const [actionTaken, setActionTaken] = useState(false);
  const [openSubmitWithoutChanges, setOpenSubmitWithoutChanges] =
    useState(false);
  const updateActionState = React.useCallback(
    async (action: () => void) => {
      setActionTaken(true);
      await action();
      setActionTaken(false);
    },
    [setActionTaken]
  );
  const { data: providerPatient } = useProviderPatient({
    providerId: event?.providerAppointment?.providerId,
    patientId: event?.patientUserId,
  });
  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });
  const { isRequired: isDocumentationRequired } = useDocumentationRequirement(
    provider,
    patient,
    event?.providerAppointment
  );
  const { data: providerEnrollmentSummary } =
    useProviderIncentiveProgramEnrollment({
      providerId: provider.id,
    });

  // Get active recurrence schedules
  const { data: patientAssessmentRecurrenceSchedules } =
    usePatientAssessmentRecurrenceSchedules({
      providerPatientId: providerPatient?.id,
    });

  const patientHasTreatmentPlan = (treatmentPlans?.length || 0) > 0;

  const [providerClosedConsiderHeadwayTemplateCard] = useLocalStorage(
    'providerClosedConsiderHeadwayTemplateCard'
  );

  const [
    isConfirmSessionOnDraftModalOpened,
    setIsConfirmSessionOnDraftModalOpened,
  ] = useState(false);

  const showTreatmentPlanRequirementWarning =
    useTreatmentPlanRequirementWarning(patient);
  const { isTreatmentPlanRequired } = useTreatmentPlanRequirements(patient?.id);

  const isHeadwayTemplateCardDisplayed =
    providerClosedConsiderHeadwayTemplateCard !== null
      ? !providerClosedConsiderHeadwayTemplateCard
      : progressNoteType !== ProgressNoteType.TEMPLATE;

  const openTreatmentPlanAdoptionModal = async () => {
    if (!onOpenTreatmentPlanAdoptionModal || patientHasTreatmentPlan) {
      return;
    }

    if (isIntakeSession) {
      onOpenTreatmentPlanAdoptionModal(true);
      return;
    }

    const { data: confirmedSessions } = await ProviderEventApi.getEvents({
      provider_id: provider.id,
      patient_user_id: patient.id,
      event_types: [ProviderEventType.APPOINTMENT],
      appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
      expand_estimated_prices: false,
      order_by: 'start_date',
      order: 'desc',
    });
    const isFirstConfirmedSession = confirmedSessions.length === 1;

    if (isFirstConfirmedSession) {
      onOpenTreatmentPlanAdoptionModal(false);
    }
  };

  const openTreatmentPlanModal = async () => {
    if (!onOpenTreatmentPlanModal) {
      return;
    }

    onOpenTreatmentPlanModal(isIntakeSession);
  };

  const [
    showCancellationConfirmationModal,
    setShowCancellationConfirmationModal,
  ] = useState(false);
  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId
  );
  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  if (!event || !event.providerAppointment) {
    return null;
  }

  // if insurance out of network but has crisis codes, allow confirming for MSC providers
  const shouldAppointmentBeCrisisCodeExempt =
    insuranceStatusAllowedWithCrisisCodeException.includes(insuranceStatus) &&
    doesAppointmentHaveCrisisCode(selectedCptCodes);

  const isInsurancePatientOutOfNetwork =
    isMSCEnabled || isMSCGuardrailRestriction
      ? !!isInsuranceAppointment &&
        !isStatusInNetwork(insuranceStatus) &&
        !shouldAppointmentBeCrisisCodeExempt
      : false;

  let primaryCTA: string | undefined;
  let primaryCTACallback = () => {};
  let primaryDisabled = false;

  let secondaryCTA;
  let secondaryCTACallback = () => {};
  let secondaryDisabled = false;

  const trackButtonClicked = (
    name:
      | 'Confirm Session Button Clicked'
      | 'Confirm Session Detail Page Exited'
      | 'Modify Session Button Clicked'
      | 'Progress Note Submit Addendum Button Clicked'
      | 'Save and Close Button Clicked'
      | 'Save and Complete Later Button Clicked'
      | 'Sign Note Button Clicked'
  ) => {
    const properties = {
      providerId: event.providerAppointment!.providerId!,
      patientUserId: event.patientUserId!,
      providerAppointmentId: event.providerAppointment!.id,
      prefillSelected: !!metadataInfo?.noteJsonPrefilledFrom,
      progressNoteId: progressNote?.id,
      progressNoteRadio: progressNoteType,
      selectedTemplate: (progressNote?.noteJson as NoteJson)?.templateInfo
        ?.name,
    };

    if (name === 'Confirm Session Button Clicked') {
      const fullProperties: ConfirmSessionButtonClickedEvent['properties'] = {
        ...properties,
        nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
        selectedTemplate:
          progressNoteType === ProgressNoteType.TEMPLATE
            ? (progressNote?.noteJson as NoteJson)?.templateInfo?.name
            : undefined,
        telehealthAttestation:
          !!event.providerAppointment?.telehealthAttestation,
      };
      trackEvent({
        name,
        properties: fullProperties,
      });
    } else if (name === 'Sign Note Button Clicked') {
      const fullProperties: SignNoteButtonClickedEvent['properties'] = {
        ...properties,
        nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
      };
      trackEvent({
        name,
        properties: fullProperties,
      });
    } else {
      trackEvent({
        name,
        properties,
      });
    }
  };

  const trackLDClient = (metricKey: string) => {
    if (LDClient) {
      LDClient.track(metricKey);
    }
  };

  if (isPast(event)) {
    if (progressNoteType === ProgressNoteType.UPLOAD) {
      switch (progressNoteState) {
        case ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT:
          primaryCTA = 'Sign and submit addendum';
          primaryCTACallback = async () => {
            let signSuccessful = false;
            signSuccessful = await updateProgressNoteState(
              ProgressNoteState.SIGN_REQUESTED
            );

            trackEvent({
              name: 'Sign and Submit Addendum Button Clicked',
              properties: {
                providerId: event.providerId,
                patientUserId: event.patientUserId!,
                providerAppointmentId: event.providerAppointment!.id,
                errorEvent: !signSuccessful,
              },
            });
          };

          if (
            documentRemediation &&
            documentRemediation.status ===
              DocumentRemediationStatus.NEEDS_REVIEW
          ) {
            secondaryCTA = 'Submit without changes';
            secondaryCTACallback = async () => {
              trackEvent({
                name: 'Submit Without Changes Addendum Button Clicked',
                properties: {
                  providerId: event.providerId,
                  patientUserId: event.patientUserId!,
                  providerAppointmentId: event.providerAppointment!.id,
                  addendumRadioSelection: '',
                  errorEvent: false,
                  pageHeader: 'Confirm Session Details',
                },
              });
              setOpenSubmitWithoutChanges(true);
            };
          } else {
            secondaryCTA = 'Cancel';
            secondaryCTACallback = async () => {
              await updateProgressNoteState(ProgressNoteState.SIGNED);
              resetAttestation();
            };
          }
          break;

        default:
          primaryCTA = isDetailsConfirmed(event)
            ? 'Modify session'
            : 'Confirm session';
          primaryCTACallback = async () => {
            if (canUpdateSessionDetails) {
              if (!(await checkAttestation())) {
                return;
              }

              const successfulSessionDetailsSubmit =
                await onSessionDetailsConfirm(eventVirtualId);

              if (successfulSessionDetailsSubmit) {
                await updateNoteToEmptyIfNeeded();
                if (isDetailsConfirmed(event)) {
                  trackButtonClicked(`Modify Session Button Clicked`);
                } else {
                  trackButtonClicked(`Confirm Session Button Clicked`);
                  trackLDClient('confirm-session-button-clicked');
                }
                if (
                  providerEnrollmentSummary?.isProviderEnrolled &&
                  patientAssessmentRecurrenceSchedules?.length === 0 &&
                  !isDetailsConfirmed(event)
                ) {
                  setIsSwoopUpsellModalOpened(true);
                } else {
                  onModalClose();
                  resetAttestation();
                }
              }
            } else {
              openTreatmentPlanAdoptionModal();
              await updateAttachments(eventVirtualId);
              onModalClose();
            }
          };
          const isAddingFirstUpload = !event.providerAppointment.attachments;
          primaryDisabled =
            (!canUpdateSessionDetails &&
              !isDocumentationRequired &&
              !isAddingFirstUpload) ||
            isHeadwayTemplateCardDisplayed ||
            isInsurancePatientOutOfNetwork;

          break;
      }
    } else if (progressNoteType === ProgressNoteType.NONE) {
      if (!canUpdateSessionDetails && !isDocumentationRequired) {
        primaryCTA = undefined;
      } else {
        primaryCTA = isDetailsConfirmed(event)
          ? 'Modify session'
          : 'Confirm session';
        primaryCTACallback = async () => {
          if (!(await checkAttestation())) {
            return;
          }

          const successfulSessionDetailsSubmit =
            await onSessionDetailsConfirm(eventVirtualId);
          await updateNoteToEmptyIfNeeded();

          if (successfulSessionDetailsSubmit) {
            if (isDetailsConfirmed(event)) {
              trackButtonClicked(`Modify Session Button Clicked`);
            } else {
              trackButtonClicked(`Confirm Session Button Clicked`);
            }
            if (
              promsOnlyRateBoostEnabled &&
              providerEnrollmentSummary?.isProviderEnrolled &&
              patientAssessmentRecurrenceSchedules?.length === 0 &&
              !isDetailsConfirmed(event)
            ) {
              setIsSwoopUpsellModalOpened(true);
            } else {
              onModalClose();
              resetAttestation();
              openTreatmentPlanAdoptionModal();
            }
          }
        };
        primaryDisabled =
          !canUpdateSessionDetails ||
          isHeadwayTemplateCardDisplayed ||
          isInsurancePatientOutOfNetwork;
      }
    } else {
      switch (progressNoteState) {
        case ProgressNoteState.ADDENDUM_EDITING:
          primaryCTA = 'Submit addendum';
          primaryCTACallback = async () => {
            const successfulSignNoteRequested = await updateProgressNoteState(
              ProgressNoteState.SIGN_REQUESTED
            );

            if (successfulSignNoteRequested) {
              trackButtonClicked(
                'Progress Note Submit Addendum Button Clicked'
              );
            }
          };
          secondaryCTA = 'Cancel';
          secondaryCTACallback = async () => {
            await updateProgressNoteState(ProgressNoteState.SIGNED);
            resetAttestation();
          };
          break;
        case ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT:
          primaryCTA = 'Sign and submit addendum';
          primaryCTACallback = async () => {
            const signSuccessful = await updateProgressNoteState(
              ProgressNoteState.SIGN_REQUESTED
            );

            if (signSuccessful) {
              queryClient.invalidateQueries(
                getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey({
                  providerId: provider.id,
                  offset: 0,
                  limit: PAGE_LIMIT,
                })
              );
            }

            trackEvent({
              name: 'Sign and Submit Addendum Button Clicked',
              properties: {
                providerId: event.providerId!,
                patientUserId: event.patientUserId!,
                providerAppointmentId: event.providerAppointment!.id,
                errorEvent: !signSuccessful,
              },
            });
          };

          if (
            documentRemediation &&
            documentRemediation.status ===
              DocumentRemediationStatus.NEEDS_REVIEW
          ) {
            secondaryCTA = 'Submit without changes';
            secondaryCTACallback = async () => {
              setOpenSubmitWithoutChanges(true);
            };
          } else {
            secondaryCTA = 'Cancel';
            secondaryCTACallback = async () => {
              await updateProgressNoteState(ProgressNoteState.SIGNED);
              resetAttestation();
            };
          }

          break;
        case ProgressNoteState.EDITING:
          primaryCTA = 'Sign and submit';

          // Want to disable primary CTA for note helper M0 if is a free text template
          const isFreeTextTemplate =
            !!selectedTemplate &&
            selectedTemplate.name.toLowerCase().includes('free');

          primaryDisabled =
            !selectedTemplate ||
            (isHeadwayTemplateCardDisplayed && isFreeTextTemplate);
          primaryCTACallback = async () => {
            const successfulSignNoteRequested = await updateProgressNoteState(
              ProgressNoteState.SIGN_REQUESTED
            );

            if (successfulSignNoteRequested) {
              trackButtonClicked('Sign Note Button Clicked');
            }
          };
          secondaryCTA = 'Save draft';
          secondaryCTACallback = async () => {
            await updateProgressNoteState(ProgressNoteState.SAVED_FOR_LATER);

            if (!canUpdateSessionDetails) {
              trackButtonClicked('Save and Complete Later Button Clicked');
              onModalClose();
            }
          };
          secondaryDisabled =
            isDocumentationRequired ||
            !selectedTemplate ||
            isHeadwayTemplateCardDisplayed;
          break;

        case ProgressNoteState.SAVED_FOR_LATER:
          primaryCTA = isDetailsConfirmed(event)
            ? 'Modify session'
            : 'Confirm session';
          primaryCTACallback = async () => {
            if (!(await checkAttestation())) {
              return;
            }

            if (isTreatmentPlanRequired) {
              setIsConfirmSessionOnDraftModalOpened(true);
            } else {
              const successfulSessionDetailsSubmit =
                await onSessionDetailsConfirm(eventVirtualId);

              if (successfulSessionDetailsSubmit) {
                if (isDetailsConfirmed(event)) {
                  trackButtonClicked(`Modify Session Button Clicked`);
                } else {
                  trackButtonClicked(`Confirm Session Button Clicked`);
                  trackLDClient('note-sign-button-clicked');
                }
                if (
                  promsOnlyRateBoostEnabled &&
                  providerEnrollmentSummary?.isProviderEnrolled &&
                  patientAssessmentRecurrenceSchedules?.length === 0 &&
                  !isDetailsConfirmed(event)
                ) {
                  setIsSwoopUpsellModalOpened(true);
                } else {
                  onModalClose();
                  resetAttestation();
                  openTreatmentPlanAdoptionModal();
                }
              }
            }
          };
          primaryDisabled =
            !canUpdateSessionDetails || isInsurancePatientOutOfNetwork;
          break;

        case ProgressNoteState.SIGNED:
          primaryCTA = isDetailsConfirmed(event)
            ? 'Modify session'
            : 'Confirm session';
          primaryCTACallback = async () => {
            if (!(await checkAttestation())) {
              return;
            }

            const successfulSessionDetailsSubmit =
              await onSessionDetailsConfirm(eventVirtualId);

            if (successfulSessionDetailsSubmit) {
              if (isDetailsConfirmed(event)) {
                trackButtonClicked(`Modify Session Button Clicked`);
              } else {
                trackButtonClicked(`Confirm Session Button Clicked`);
                trackLDClient('note-sign-button-clicked');
              }
              if (
                isTreatmentPlanRequired &&
                isIntakeSession &&
                onOpenTreatmentPlanModal
              ) {
                onOpenTreatmentPlanModal(isIntakeSession);
                setOnCloseTreatmentPlanModal(() => () => {
                  onModalClose();
                });
              } else if (
                !showTreatmentPlanRequirementWarning &&
                providerEnrollmentSummary?.isProviderEnrolled &&
                patientAssessmentRecurrenceSchedules?.length === 0 &&
                !isDetailsConfirmed(event)
              ) {
                setIsSwoopUpsellModalOpened(true);
              } else {
                onModalClose();
                openTreatmentPlanAdoptionModal();
              }
              resetAttestation();
            }
          };
          if (showTreatmentPlanRequirementWarning) {
            secondaryCTA = 'Create treatment plan';
            secondaryCTACallback = () => {
              openTreatmentPlanModal();
            };
          }
          primaryDisabled =
            !canUpdateSessionDetails ||
            isInsurancePatientOutOfNetwork ||
            showTreatmentPlanRequirementWarning;

          break;
      }
    }
  } else {
    switch (progressNoteState) {
      case ProgressNoteState.EDITING:
        if (progressNoteType === ProgressNoteType.NONE) {
          primaryCTA = 'Close and confirm later';
        } else {
          primaryCTA = 'Save draft and close';
        }
        primaryCTACallback = async () => {
          await updateProgressNoteState(ProgressNoteState.SAVED_FOR_LATER);
          trackButtonClicked('Save and Close Button Clicked');
          if (!canUpdateSessionDetails) {
            onModalClose();
          }
        };
        break;
      default:
        break;
    }
  }

  const handleClose = () => {
    if (progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT) {
      setShowCancellationConfirmationModal(true);
    } else {
      trackButtonClicked('Confirm Session Detail Page Exited');
      onModalClose();
    }
  };

  const handleCloseSubmittingWithoutChangeModal = () => {
    setOpenSubmitWithoutChanges(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={GrowTransition}
        scroll="paper"
        onClose={handleClose}
        fullScreen={true}
        disableEnforceFocus
        sx={{ zIndex: theme.layers.modal - 2 }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: theme.spacing.x3,
          }}
        >
          <div css={{ marginLeft: theme.spacing.x3 }}>
            <PageHeader>
              {isPast(event) ? 'Confirm Session Details' : 'Session Details'}
            </PageHeader>
          </div>

          <IconButton
            onClick={handleClose}
            sx={{
              margin: theme.spacing.x1,
              zIndex: 1005,
            }}
            size="large"
          >
            <CloseTwoTone />
            <VisuallyHidden>Close dialog</VisuallyHidden>
          </IconButton>
        </div>
        {isGroupAdminImpersonatingProvider(provider, user) && (
          <div>
            <GroupPracticeAdminBanner />
          </div>
        )}
        <TreatmentPlanRequirementWarningBanner
          patient={patient}
          onOpenTreatmentPlanModal={openTreatmentPlanModal}
        />
        <DialogContent
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {children}
        </DialogContent>
        <div
          css={{
            minHeight: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingLeft: theme.spacing.x4,
            paddingRight: theme.spacing.x4,
          }}
        >
          <div css={{ marginTop: theme.spacing.x1 }}>
            {appointmentState !== AppointmentState.DETAILS_CONFIRMED &&
              notePermissions.includes(ProgressNotePermissions.WRITE) && (
                <div css={{ display: 'flex', flexDirection: 'row' }}>
                  {isSavingInProgress ? (
                    <>
                      <CircularProgress
                        css={{
                          color: theme.color.system.gray,
                          marginTop: '1px',
                        }}
                        size={16}
                      />
                      <p css={{ marginLeft: theme.spacing.x2 }}>Saving...</p>
                    </>
                  ) : (
                    <>
                      <IconCheck />
                      <p css={{ marginLeft: theme.spacing.x2 }}>Saved</p>
                    </>
                  )}
                </div>
              )}
          </div>
          <div
            css={{
              display: 'flex',
              gap: theme.spacing.x1,
            }}
          >
            {secondaryCTA ? (
              <Button
                variant="secondary"
                onPress={async () =>
                  await updateActionState(secondaryCTACallback)
                }
                disabled={secondaryDisabled || actionTaken}
                data-testid="secondaryModalCta"
              >
                {secondaryCTA}
              </Button>
            ) : null}
            {primaryCTA ? (
              <Button
                variant="primary"
                onPress={async () => {
                  try {
                    await updateActionState(primaryCTACallback);
                  } catch (e) {
                    if (e instanceof MambaApiError) {
                      // Since we don't have discrete information on what action was taken
                      // we use the content of the CTA to infer what happened
                      if (primaryCTA?.toLowerCase().includes('confirm')) {
                        toaster.error(
                          'Session could not be confirmed. Please double check session details.'
                        );
                      } else {
                        toaster.error(
                          primaryCTA + ' failed. ',
                          e.response?.data.detail
                        );
                      }
                    }

                    setActionTaken(false);
                  }
                }}
                disabled={primaryDisabled || actionTaken}
                data-testid="primaryModalCta"
              >
                {primaryCTA}
              </Button>
            ) : null}
          </div>
        </div>
      </Dialog>
      <SubmitAddendumWithoutChangesForm
        open={openSubmitWithoutChanges}
        closeOnCancel={handleCloseSubmittingWithoutChangeModal}
        closeOnSubmit={onModalClose}
        clientId={event.patientUserId!}
      />
      <HelixModal
        isOpen={showCancellationConfirmationModal}
        onDismiss={() => {
          setShowCancellationConfirmationModal(false);
        }}
        title="Discard unsaved changes?"
      >
        <ModalContent>
          <div
            css={{
              display: 'flex',
              gap: theme.spacing.x2,
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="secondary"
              onPress={() => setShowCancellationConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button onPress={onModalClose}>Discard</Button>
          </div>
        </ModalContent>
      </HelixModal>
      {isTreatmentPlanRequired && (
        <ConfirmSessionOnDraftModal
          open={isConfirmSessionOnDraftModalOpened}
          onClose={() => setIsConfirmSessionOnDraftModalOpened(false)}
          onEdit={() => {
            updateProgressNoteState(ProgressNoteState.EDITING);
            setIsConfirmSessionOnDraftModalOpened(false);
          }}
        />
      )}
    </>
  );
};
