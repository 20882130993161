import { SvgIconComponent } from '@mui/icons-material';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Skeleton from '@mui/material/Skeleton';
import { useProvider } from 'hooks';
import { compact, zip } from 'lodash';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { PatientMissingSchedulingInfoType } from '@headway/api/models/PatientMissingSchedulingInfoType';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { Item } from '@headway/helix/collections';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TabList, TabPanels, Tabs } from '@headway/helix/Tabs';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useUserList } from '@headway/shared/hooks/useUser';
import { useQuery } from '@headway/shared/react-query';
import { isPatientInNoDataPrelimPricing } from '@headway/shared/utils/prelimPricing';

import { useBillingAccountForBillingSettings } from 'hooks/useBillingAccount';
import { useCanConfirmSessionDetailsList } from 'hooks/useCanConfirmSessionDetails';
import { useAuthStore } from 'stores/AuthStore';
import {
  hasUnmetStripeAccountRequirements,
  shouldShowBankAccountComponents,
  shouldShowW9Components,
} from 'utils/billing';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';
import { PaginatedConcreteProviderEventRead } from 'utils/types';

import { BankAccountTask } from './BankAccountTask';
import { ClientTask } from './ClientTask';
import { MultiFactorSetupTask } from './MultiFactorSetupTask';
import { SessionTask } from './SessionTask';
import { StripeRequirementsTask } from './StripeRequirementsTask';
import { BillingTask } from './W9FormTask';

export const TasksToDo = () => {
  const provider = useProvider();
  const user = useAuthStore().user;
  const [currentTab, setCurrentTab] = useState<React.Key>('all');
  const [expandedButton, setExpandedButton] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const { billingAccountInfo } = useBillingAccountForBillingSettings();
  const { height: listHeight } = useResizeObserver<HTMLUListElement>({
    ref: listRef,
  });

  const expandCurrentTabTasks = () => {
    setExpandedButton(!expandedButton);
  };

  useEffect(() => {
    const listEl = listRef.current;

    if (!listEl) {
      return;
    }

    const firstItem = listEl?.children[0] as HTMLElement | undefined;
    firstItem?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [currentTab]);

  const fetchUpcomingAppointmentsWithinTwoDays = useQuery(
    ['fetchUpcomingAppointmentsWithinTwoDays', provider.id],
    async () => {
      const upcomingAppointments = (await ProviderEventApi.getEvents({
        provider_id: provider.id,
        date_range_start: moment().toISOString(),
        date_range_end: moment().add(48, 'hours').toISOString(),
        event_types: [ProviderEventType.APPOINTMENT],
        appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
        expand_estimated_prices: false,
      })) as PaginatedConcreteProviderEventRead;

      const clientIdsWithAppointments = uniq(
        upcomingAppointments.data.map(
          (appointment: ConcreteProviderEventRead) => {
            return appointment.patientUserId;
          }
        )
      );

      return compact(clientIdsWithAppointments);
    }
  );

  /**
   *
   *  if client has received an email less than 3 days ago OR
   *  client has an upcoming appointment within 48 hours but last email was sent less than 24 hours ago OR
   *  client is in no data prelim pricing and only missing insurance + address
   *  filter out client from task list
   *
   * @param patient
   * @param patientIdsWithUpcomingAppointments
   * @returns boolean
   */
  const shouldDisplayClient = (
    patient: UserRead,
    patientIdsWithUpcomingAppointments: number[] = []
  ) => {
    const { id, patientAccountInviteCommunications } = patient;
    if (!patientAccountInviteCommunications) {
      return true;
    }

    const patientHasUpcomingAppointment =
      patientIdsWithUpcomingAppointments.includes(id);

    const lastCommunicationSentOn =
      patientAccountInviteCommunications[
        patientAccountInviteCommunications.length - 1
      ].sentOn;

    const wasEmailSentLessThan24HoursAgo = moment(
      lastCommunicationSentOn
    ).isAfter(moment().subtract(24, 'hours'));

    const wasEmailSentLessThan3DaysAgo = moment(
      lastCommunicationSentOn
    ).isAfter(moment().subtract(3, 'days'));

    // do not show client if already sent email within 24 hours even though there is an appt
    if (patientHasUpcomingAppointment && wasEmailSentLessThan24HoursAgo) {
      return false;
      // do not show client if email was sent within 3 days without appt
    } else if (!patientHasUpcomingAppointment && wasEmailSentLessThan3DaysAgo) {
      return false;
    } else if (
      isPatientInNoDataPrelimPricing(patient.activeUserInsurance) &&
      !patient.missingSchedulingInfoTypes?.includes(
        PatientMissingSchedulingInfoType.BILLING
      ) &&
      !patient.missingSchedulingInfoTypes?.includes(
        PatientMissingSchedulingInfoType.FORMS
      )
    ) {
      return false;
    }
    return true;
  };

  // Setting the staleTime to be around ~10 seconds to avoid constant refetches
  const fetchClientMissingInfo = useQuery(
    ['patientMissingSchedulingInfoForProvider', provider.id],
    async () =>
      ProviderApi.getPatientsMissingSchedulingInfoForProvider(provider?.id),
    { staleTime: 10 * 1000 }
  );

  const filterFetchedClientTasks = useMemo(() => {
    const filteredClientsNeedsEmail = fetchClientMissingInfo?.data?.filter(
      (patient: UserRead) =>
        !patient.providerPatients?.find(
          (prov) => prov.providerId === provider.id
        )?.hidden &&
        (patient.patientAccountInviteCommunications.length === 0 ||
          shouldDisplayClient(
            patient,
            fetchUpcomingAppointmentsWithinTwoDays.data
          ))
    );
    return filteredClientsNeedsEmail;
  }, [
    fetchClientMissingInfo.data,
    fetchUpcomingAppointmentsWithinTwoDays.data,
    provider.id,
  ]);

  const allClientTasks = filterFetchedClientTasks || [];

  const isIroncladBlockAppointmentConfirmationEnabled = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );
  const shouldUseShorterSessionWindow =
    shouldBlockProviderWithIroncladAgreement(
      isIroncladBlockAppointmentConfirmationEnabled
    );

  const shouldUseShorterSessionWindowKey = `shouldUseShorterSessionWindow-${shouldUseShorterSessionWindow}`;
  const sessionStartDate = shouldUseShorterSessionWindow
    ? moment().subtract(30, 'days').toISOString()
    : moment().subtract(90, 'days').toISOString();
  const fetchUnfilteredSessions = useQuery(
    ['sessionsToBeConfirmed', shouldUseShorterSessionWindowKey],
    async () => {
      const sessionDateRangeStart = sessionStartDate;
      const sessionDateRangeEnd = moment().toISOString();
      const potentialSessions = await ProviderEventApi.getEvents({
        provider_id: provider.id,
        event_types: [ProviderEventType.APPOINTMENT],
        appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
        date_range_start: sessionDateRangeStart,
        date_range_end: sessionDateRangeEnd,
        expand_estimated_prices: false,
        order_by: 'start_date',
        order: 'asc',
        expand: true,
      });
      return potentialSessions.data;
    }
  );
  const sessionConfirmabilityQueries = useCanConfirmSessionDetailsList(
    fetchUnfilteredSessions.data || []
  );
  const isSessionConfirmabilityLoading = sessionConfirmabilityQueries.some(
    (query) => query.isLoading
  );
  const allSessionTasks: ProviderEventRead[] = [];
  const patientIdsWithConfirmableSessions: Set<number> = new Set();
  for (const [session, confirmabilityQuery] of zip(
    fetchUnfilteredSessions.data || [],
    sessionConfirmabilityQueries
  )) {
    if (confirmabilityQuery?.canConfirmSessionDetails) {
      allSessionTasks.push(session!);
      patientIdsWithConfirmableSessions.add(session!.patientUserId!);
    }
  }
  allSessionTasks.sort(
    (eventA: ProviderEventRead, eventB: ProviderEventRead) => {
      return (
        new Date(eventA.startDate!).getTime() -
        new Date(eventB.startDate!).getTime()
      );
    }
  );
  const patientsWithConfirmableSessionsQueries = useUserList(
    [...patientIdsWithConfirmableSessions].map((userId) => ({
      queryKeyArgs: { userId },
    }))
  );
  const arePatientsLoading = patientsWithConfirmableSessionsQueries.some(
    ({ isLoading }) => isLoading
  );
  const patientsWithConfirmableSessions: UserRead[] =
    patientsWithConfirmableSessionsQueries
      .map((query) => query.data!)
      .filter((data) => !!data);
  const patientsById = keyBy(
    [...patientsWithConfirmableSessions, ...allClientTasks],
    'id'
  );

  const shouldCollectIndividualW9FromGroup = useFlag(
    'shouldCollectIndividualW9FromGroup'
  );

  const shouldShowW9Task = shouldShowW9Components(
    provider?.groupPracticeId,
    shouldCollectIndividualW9FromGroup,
    billingAccountInfo?.stripeAccount
  );

  const clientTasks = expandedButton
    ? allClientTasks
    : allClientTasks.slice(0, 3);

  const sessionTasks = expandedButton
    ? allSessionTasks
    : allSessionTasks.slice(0, 3);

  const allTasks = !!Object.keys(patientsById).length
    ? allSessionTasks
        .map((session: ProviderEventRead, key: number) => (
          <SessionTask
            key={key}
            session={session}
            patientsById={patientsById}
          />
        ))
        .concat(
          allClientTasks.map((user: UserRead, key: number) => (
            <ClientTask user={user} key={key + allSessionTasks.length} />
          ))
        )
    : [];

  if (shouldShowW9Task) {
    // Add to the top of the list
    allTasks.unshift(
      <BillingTask
        key="BillingTask"
        stripeOnboardingLink={billingAccountInfo?.stripeOnboardingLink}
      />
    );
  }

  const showStripeAccountTasksEnabled = useFlag(
    'showStripeAccountTasksInSigmund',
    false
  );
  if (showStripeAccountTasksEnabled) {
    const shouldShowBankAccountTask = shouldShowBankAccountComponents(
      billingAccountInfo!
    );
    const shouldShowStripeRequirementsTask = hasUnmetStripeAccountRequirements(
      billingAccountInfo?.stripeAccount
    );

    if (shouldShowBankAccountTask) {
      allTasks.unshift(<BankAccountTask key="BankAccountTask" />);
    }

    if (shouldShowStripeRequirementsTask) {
      allTasks.unshift(
        <StripeRequirementsTask
          key="StripeRequirementsTask"
          stripeOnboardingLink={billingAccountInfo?.stripeOnboardingLink}
        />
      );
    }
  }

  const shouldShowEnrollinMFATask = useFlag('shouldShowEnrollInMfaTask');

  if (
    shouldShowEnrollinMFATask &&
    (user?.use_mfa === false || user?.use_mfa === null)
  ) {
    // Add the enroll in MFA task at the top of the list if the user is opted in and
    // the feature flag is enabled
    allTasks.unshift(<MultiFactorSetupTask key="MultiFactorSetupTask" />);
  }

  const allTasksDisplayed = allTasks.slice(0, expandedButton ? undefined : 3);
  const shouldShowMoreTaskTab = currentTab === 'all' && allTasks.length > 3;
  const shouldShowMoreClientTab =
    currentTab === 'clients' && allClientTasks.length > 3;
  const shouldShowMoreSessionTab =
    currentTab === 'sessions' && allSessionTasks.length > 3;
  const shouldShowMoreButton =
    shouldShowMoreClientTab ||
    shouldShowMoreSessionTab ||
    shouldShowMoreTaskTab;

  // This margin prevents the sticky header from going past the third-to-last item in the list.
  const stickyMargin = useMemo(
    () => {
      const listEl = listRef.current;
      if (!listEl) {
        return 0;
      }
      const thirdToLastItem = listEl.children[
        Math.max(0, listEl.childElementCount - 3)
      ] as HTMLElement | undefined;
      if (!thirdToLastItem) {
        return 0;
      }
      return listEl.clientHeight - thirdToLastItem.offsetTop;
    },
    // Even though these aren't used directly in the calculation, a change indicates that the margin
    // position should be recalculated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listHeight, currentTab]
  );
  const shouldBeSticky = shouldShowMoreButton && expandedButton;

  const isLoading =
    isSessionConfirmabilityLoading ||
    !fetchUnfilteredSessions.isFetchedAfterMount ||
    arePatientsLoading;

  return (
    <PageSection>
      <div>
        <Tabs selectedKey={currentTab} onSelectionChange={setCurrentTab}>
          <div
            css={{
              backgroundColor: theme.color.system.white,
              position: shouldBeSticky ? 'sticky' : 'static',
              // Account for header height when sticking
              top: 50,
              marginBottom: shouldBeSticky ? stickyMargin : 0,
              zIndex: 1,
            }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: theme.spacing.x4,
              }}
            >
              <SectionHeader>To Do</SectionHeader>
              {shouldShowMoreButton && (
                <Button variant="link" onPress={expandCurrentTabTasks}>
                  {expandedButton ? 'Show less' : 'Show more'}
                </Button>
              )}
            </div>
            <TabList>
              <Item key="all" textValue="all">
                All
                {allTasks.length > 0 && !isLoading && (
                  <TaskTabBadge count={allTasks.length} />
                )}
              </Item>
              <Item key="sessions" textValue="sessions">
                Sessions
                {allSessionTasks.length > 0 && !isLoading && (
                  <TaskTabBadge count={allSessionTasks.length} />
                )}
              </Item>
              <Item key="clients" textValue="clients">
                Clients
                {allClientTasks.length > 0 && !isLoading && (
                  <TaskTabBadge count={allClientTasks.length} />
                )}
              </Item>
            </TabList>
          </div>
          <TabPanels>
            <Item key="all" textValue="all">
              {isLoading ? (
                <TaskLoadingState />
              ) : allTasks.length === 0 ? (
                <TaskEmptyState
                  Icon={CheckCircleOutlineIcon}
                  sectionHeader="All done!"
                  sectionDescription="You don't have anything on your To Do list. Enjoy your day!"
                />
              ) : (
                <ul
                  css={{
                    margin: 0,
                    padding: 0,
                    marginTop: shouldBeSticky ? -stickyMargin : 0,
                    position: 'relative',
                  }}
                  ref={listRef}
                >
                  {allTasksDisplayed}
                </ul>
              )}
            </Item>
            <Item key="sessions" textValue="sessions">
              {isLoading ? (
                <TaskLoadingState />
              ) : allSessionTasks?.length === 0 ? (
                <TaskEmptyState
                  Icon={AssignmentTurnedInOutlinedIcon}
                  sectionHeader="All done!"
                  sectionDescription="You don’t have any sessions to confirm."
                />
              ) : (
                <ul
                  css={{
                    padding: 0,
                    margin: 0,
                    marginTop: shouldBeSticky ? -stickyMargin : 0,
                    position: 'relative',
                  }}
                  ref={listRef}
                >
                  {sessionTasks.map(
                    (session: ProviderEventRead, key: number) => (
                      <SessionTask
                        key={key}
                        session={session}
                        patientsById={patientsById}
                      />
                    )
                  )}
                </ul>
              )}
            </Item>
            <Item key="clients" textValue="clients">
              {isLoading ? (
                <TaskLoadingState />
              ) : allClientTasks?.length === 0 ? (
                <TaskEmptyState
                  Icon={PersonOutlinedIcon}
                  sectionHeader="All done!"
                  sectionDescription="All of your clients’ accounts are complete. Impressive!"
                />
              ) : (
                <ul
                  css={{
                    margin: 0,
                    padding: 0,
                    marginTop: shouldBeSticky ? -stickyMargin : 0,
                    position: 'relative',
                  }}
                  ref={listRef}
                >
                  {clientTasks &&
                    clientTasks.map((user: UserRead, key: number) => (
                      <ClientTask key={key} user={user} />
                    ))}
                </ul>
              )}
            </Item>
          </TabPanels>
        </Tabs>
      </div>
    </PageSection>
  );
};

interface TaskEmptyStateProps {
  Icon: SvgIconComponent;
  sectionHeader: string;
  sectionDescription: string;
}

export const TaskLoadingState = () => (
  <div css={{ padding: theme.spacing.x4 }}>
    <Skeleton variant="rectangular" height={180} />
  </div>
);

const TaskEmptyState = ({
  Icon,
  sectionHeader,
  sectionDescription,
}: TaskEmptyStateProps) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing.x4,
      }}
    >
      <Icon
        css={{
          margin: theme.spacing.x4,
          backgroundColor: theme.color.hue.lightGreen,
          padding: theme.spacing.x3,
          borderRadius: '50%',
          fontSize: '50px',
        }}
        color="primary"
      />
      <SectionHeader>{sectionHeader}</SectionHeader>
      <span
        css={{ padding: theme.spacing.x2, color: theme.color.system.textBlack }}
      >
        {sectionDescription}
      </span>
    </div>
  );
};

interface TaskTabBadgeProps {
  count: number;
}

export const TaskTabBadge = ({ count }: TaskTabBadgeProps) => {
  return (
    <div css={{ paddingLeft: theme.spacing.x2 }}>
      <Badge variant="neutral">{count}</Badge>
    </div>
  );
};
