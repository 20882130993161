import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { Banner } from '@headway/helix/Banner';
import { FormControl } from '@headway/helix/FormControl';
import { Link } from '@headway/helix/Link';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { lateEntryReasonDisplayNames } from '@headway/shared/constants/lateEntryReasonDisplayNames';

type ProgressNotesLateEntryInputProps = {
  disabled: boolean;
  showBanner: boolean;
  isMedicareOrMedicaid: boolean;
};

const UNAVAILABLE_LATE_ENTRY_REASONS = new Set([
  // some late entry reasons should not be an option for newly-submitted notes,
  // but we still need to support them if previously-submitted notes are
  // accessed, so we can't remove them completely
  ProviderProgressNoteLateEntryReason.OTHER,
]);

const compareLateEntryReasonDisplayNames = (
  reason1: ProviderProgressNoteLateEntryReason,
  reason2: ProviderProgressNoteLateEntryReason
) =>
  lateEntryReasonDisplayNames[reason1].localeCompare(
    lateEntryReasonDisplayNames[reason2]
  );

function LateEntryBanner({
  isMedicareOrMedicaid,
}: {
  isMedicareOrMedicaid: boolean;
}) {
  return (
    <Banner variant="warning">
      {isMedicareOrMedicaid
        ? `Medicare and Medicaid notes must be signed within 48 hours`
        : `Notes must be signed within 72 hours`}{' '}
      of each session to meet compliance standards. Over time, late notes can
      lead to payment delays or additional claim reviews, though it’s better to
      sign late than not sign a note at all.{' '}
      <Link
        href="https://help.headway.co/hc/en-us/articles/25175689760532-Compliance-deadlines-for-clinical-documentation-and-why-it-matters"
        target="_blank"
        rel="noreferrer"
      >
        Learn more about note deadlines.
      </Link>
      <p css={{ marginTop: theme.spacing.x6 }}>
        Already signed your note somewhere else? Switch to “My note is saved
        elsewhere” above.
      </p>
    </Banner>
  );
}

function LateEntryOtherReasonInput({ disabled }: { disabled: boolean }) {
  const [, , { setValue: setLateEntryOtherReason }] = useField(
    'lateEntryOtherReason'
  );

  const handleChange = useCallback(
    (value: string) => {
      setLateEntryOtherReason(value);
    },
    [setLateEntryOtherReason]
  );

  return (
    <FormControl
      component={TextField}
      name="lateEntryOtherReason"
      label="Describe your reason for late submission"
      disabled={disabled}
      onChange={handleChange}
      helpText="This will be included in your signed note"
    />
  );
}

export function ProgressNotesLateEntryInput({
  disabled,
  showBanner,
  isMedicareOrMedicaid,
}: ProgressNotesLateEntryInputProps) {
  const [{ value: lateEntryReason }] = useField('lateEntryReason');

  const showOtherInput =
    lateEntryReason === ProviderProgressNoteLateEntryReason.OTHER;

  const reasonOptions = useMemo(() => {
    const availableReasons = new Set(
      Object.values(ProviderProgressNoteLateEntryReason).filter(
        (reason) => !UNAVAILABLE_LATE_ENTRY_REASONS.has(reason)
      )
    );

    // ensure selected option still renders even if it's no longer available
    if (lateEntryReason in ProviderProgressNoteLateEntryReason) {
      availableReasons.add(lateEntryReason);
    }

    return [...availableReasons].sort(compareLateEntryReasonDisplayNames);
  }, [lateEntryReason]);

  return (
    <>
      {showBanner && (
        <LateEntryBanner isMedicareOrMedicaid={isMedicareOrMedicaid} />
      )}
      <FormControl
        component={Select}
        name="lateEntryReason"
        label="Reason for late submission"
        selectionMode="single"
        menuWidth="stretch"
        disabled={disabled}
      >
        {reasonOptions.map((reason) => (
          <Item key={reason}>{lateEntryReasonDisplayNames[reason]}</Item>
        ))}
      </FormControl>
      {showOtherInput && <LateEntryOtherReasonInput disabled={disabled} />}
    </>
  );
}
