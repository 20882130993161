import { UseQueryResult } from '@tanstack/react-query';
import zip from 'lodash/zip';
import { useRef } from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { CalendarEventType } from '@headway/api/models/CalendarEventType';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { SessionDetailsConfirmabilityResponse } from '@headway/api/models/SessionDetailsConfirmabilityResponse';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { SessionUnconfirmableReason } from '@headway/api/models/SessionUnconfirmableReason';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useProviderUserFreezes } from '@headway/shared/hooks/useProviderUserFreezes';
import { useUserList } from '@headway/shared/hooks/useUser';
import { logException } from '@headway/shared/utils/sentry';

import { useMatchingProviderFrontendCarriersForEvents } from 'hooks/useMatchingProviderFrontendCarriersForEvents';
import { isPastW9Deadline, shouldShowW9Components } from 'utils/billing';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';
import {
  isPatientMissingRequiredInfo,
  patientCanBookForBillingType,
  patientCompletedRequiredForms,
} from 'utils/patient';
import {
  doesAppointmentHaveCrisisCode,
  isAppointmentDateValidForPFEC,
  isIntakeCall,
} from 'views/Calendar/events/util/events';

import { useBillingAccountForPayment } from './useBillingAccount';
import { useClaimReadinessList } from './useClaimReadiness';
import { useProvider } from './useProvider';
import { useSessionDetailsConfirmabilityList } from './useSessionDetailsConfirmability';
import { useSessionDetailsEditabilityList } from './useSessionDetailsEditability';

export interface UseConfirmSessionDetailsResult {
  canConfirmSessionDetails?: boolean;
  isLoading: boolean;
  reasonsCannotConfirm?: SessionUnconfirmableReason[];
}

/**
 * Returns `true` if we should allow the provider to open the session confirmation modal.
 * This hooks fails closed, so if it is still waiting on async data to determine the final result,
 * it returns `false`.
 */
export const useCanConfirmSessionDetails = (
  providerEvent: ProviderEventRead
): UseConfirmSessionDetailsResult => {
  return useCanConfirmSessionDetailsList([providerEvent])[0];
};

/**
 * Equivalent to `useCanConfirmSessionDetails` for a dynamic number of appointments.
 */
export const useCanConfirmSessionDetailsList = (
  providerEvents: Array<ProviderEventRead>
): UseConfirmSessionDetailsResult[] => {
  const firstLoadedEventIds = useRef(new Set());
  const isStandardPatientFormsEnabled = useFlag('standardPatientForms', true);
  const isIroncladBlockAppointmentConfirmationEnabled = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );
  const shouldCollectIndividualW9FromGroup = useFlag(
    'shouldCollectIndividualW9FromGroup',
    false
  );
  const { billingAccountInfo } = useBillingAccountForPayment();
  const usersQueries = useUserList(
    providerEvents.map((event) => ({
      queryKeyArgs: { userId: event.patientUserId || NaN },
    }))
  );
  const sessionDetailsEditabilityQueries = useSessionDetailsEditabilityList(
    providerEvents.map((providerEvent) => ({
      providerEventVirtualId: providerEvent.virtualId,
      options: {
        refetchOnWindowFocus: false,
      },
    }))
  );
  const claimReadinessQueries = useClaimReadinessList(
    zip(providerEvents, usersQueries).map(([event, userQuery]) => {
      const { data: patient } = userQuery!;
      return {
        queryKeyArgs: { patientUser: patient },
        options: {
          enabled:
            !!patient?.activeUserInsuranceId &&
            event?.providerAppointment?.billingType === BillingType.INSURANCE,
        },
      };
    })
  );
  const sessionDetailsConfirmabilityApiQueries =
    useSessionDetailsConfirmabilityList(
      providerEvents.map((providerEvent) => ({
        queryKeyArgs: {
          providerEventVirtualId: providerEvent.virtualId,
        },
        options: {
          refetchOnWindowFocus: false,
        },
      }))
    );

  const provider = useProvider();
  const { freezeReasonsByUser, isLoading: isProviderUserFreezesLoading } =
    useProviderUserFreezes(provider.id);

  const {
    eventIdsToMatchingProviderFrontEndCarriers,
    isLoading: isLoadingMatchingProviderFrontEndCarriers,
  } = useMatchingProviderFrontendCarriersForEvents(provider.id, providerEvents);

  // lodash.zip can only take up to five arguments before it requires all arguments to have the same exact type, hence the as recasting that happens a few lines down.
  return zip<any>(
    providerEvents,
    usersQueries,
    sessionDetailsEditabilityQueries,
    claimReadinessQueries,
    sessionDetailsConfirmabilityApiQueries
  ).map(
    ([
      event,
      userQuery,
      sessionDetailsEditabilityQuery,
      claimReadinessQuery,
      sessionDetailsConfirmabilityApiQuery,
    ]) => {
      const { data: patient, isLoading: isUserQueryLoading } =
        userQuery! as UseQueryResult<UserRead, unknown>;
      const {
        data: sessionDetailsEditability,
        isLoading: isSessionDetailsEditabilityQueryLoading,
      } = sessionDetailsEditabilityQuery! as UseQueryResult<
        SessionDetailsEditabilityStatus,
        unknown
      >;
      const { data: claimReadiness, isLoading: isClaimReadinessLoading } =
        claimReadinessQuery! as UseQueryResult<
          UserClaimReadinessResponse,
          unknown
        >;
      const {
        data: sessionDetailsConfirmabilityApiCheck,
        isLoading: isSessionDetailsConfirmabilityApiCheckLoading,
      } = sessionDetailsConfirmabilityApiQuery! as UseQueryResult<
        SessionDetailsConfirmabilityResponse,
        unknown
      >;

      const isLoading =
        isUserQueryLoading ||
        isSessionDetailsEditabilityQueryLoading ||
        isClaimReadinessLoading ||
        isProviderUserFreezesLoading ||
        isSessionDetailsConfirmabilityApiCheckLoading ||
        isLoadingMatchingProviderFrontEndCarriers ||
        !billingAccountInfo ||
        billingAccountInfo.isLoadingStripeOnboardingLink;

      if (isLoading) {
        return {
          canConfirmSessionDetails: undefined,
          isLoading: true,
        };
      }

      // We used to raise errors here but it seems like the get request is returning 4xx
      // Going to just return false here since that was the original behavior
      // and that we plan on moving all of this to the BE soon anyways
      // https://therapymatch.slack.com/archives/C07JHPJ5QAK/p1727461939498529
      if (!patient) {
        return {
          canConfirmSessionDetails: false,
          isLoading: false,
        };
      }

      if (event.type != ProviderEventType.APPOINTMENT) {
        return {
          canConfirmSessionDetails: false,
          isLoading: false,
          unconfirmableReasons: [SessionUnconfirmableReason.NOT_AN_APPOINTMENT],
        };
      }

      const feUnconfirmableReasons: SessionUnconfirmableReason[] = [];

      // The method shouldBlockProviderWithIroncladAgreement is essentially checking whether
      // they're past the deadline and not anything ironclad related at this point
      if (
        !!event?.startDate &&
        shouldBlockProviderWithIroncladAgreement(
          isIroncladBlockAppointmentConfirmationEnabled,
          new Date(event.startDate)
        )
      ) {
        feUnconfirmableReasons.push(
          SessionUnconfirmableReason.PAST_DEADLINE_TO_CONFIRM
        );
      }

      if (
        sessionDetailsEditability !== SessionDetailsEditabilityStatus.ALLOWED
      ) {
        feUnconfirmableReasons.push(
          SessionUnconfirmableReason.SESSION_NOT_ALLOWED_TO_BE_EDITED
        );
      }

      if (
        !patientCanBookForBillingType(
          patient,
          event.providerAppointment.billingType!
        )
      ) {
        feUnconfirmableReasons.push(
          SessionUnconfirmableReason.PATIENT_CANNOT_BOOK_FOR_BILLING_TYPE
        );
      }

      const isMissingRequiredPatientInfo = isPatientMissingRequiredInfo(
        patient,
        event.providerAppointment?.billingType!,
        claimReadiness
      );
      if (
        isMissingRequiredPatientInfo &&
        isStandardPatientFormsEnabled &&
        !isIntakeCall(event)
      ) {
        if (!patientCompletedRequiredForms(patient)) {
          feUnconfirmableReasons.push(
            SessionUnconfirmableReason.PATIENT_MISSING_REQUIRED_FORMS
          );
        }

        feUnconfirmableReasons.push(
          SessionUnconfirmableReason.PATIENT_MISSING_REQUIRED_INFO
        );
      }

      if (event.providerAppointment.billingType === BillingType.INSURANCE) {
        if (
          !patient.activeUserInsuranceId || // claimReadiness will be undefined when user doesnt have any active insurance
          (claimReadiness &&
            !claimReadiness.ok &&
            !(
              claimReadiness.requirements?.length === 1 &&
              claimReadiness.requirements?.includes(
                UserClaimReadinessCheck.PATIENT_ADDRESS
              )
            ))
        ) {
          feUnconfirmableReasons.push(
            SessionUnconfirmableReason.PATIENT_NOT_CLAIM_READY
          );
        }

        const nonBlockingFreezeReasons = [
          UserFreezeReason.AWAITING_AUTOPAY_CX_ACTION,
        ];
        if (
          freezeReasonsByUser[patient.id]?.some(
            (reason) => !nonBlockingFreezeReasons.includes(reason)
          )
        ) {
          feUnconfirmableReasons.push(
            SessionUnconfirmableReason.PATIENT_HAS_BLOCKING_FREEZE
          );
        }

        const appointmentCptCodes = event.providerAppointment?.cptCodes || [];
        const hasCrisisCodes =
          doesAppointmentHaveCrisisCode(appointmentCptCodes);
        const matchingPFEC = event?.id
          ? eventIdsToMatchingProviderFrontEndCarriers.get(event.id)
          : undefined;
        const acceptsPatientInsurance =
          !!matchingPFEC &&
          isAppointmentDateValidForPFEC(matchingPFEC, event.startDate);

        if (!acceptsPatientInsurance && !hasCrisisCodes) {
          feUnconfirmableReasons.push(
            SessionUnconfirmableReason.DOES_NOT_ACCEPT_PATIENT_INSURANCE
          );
        }
      }

      if (
        shouldShowW9Components(
          provider.groupPracticeId,
          shouldCollectIndividualW9FromGroup,
          billingAccountInfo?.stripeAccount
        ) &&
        isPastW9Deadline(provider.providerLicenseState.state)
      ) {
        feUnconfirmableReasons.push(SessionUnconfirmableReason.INCOMPLETE_W9);
      }

      if (
        !billingAccountInfo ||
        (billingAccountInfo?.isGroupPracticeBillingAccount &&
          !billingAccountInfo.isVerified) ||
        // isVerified is the more accurate check for both GP and non-GP, but our demo account is a non-GP account
        // that isn't fully verified but has a bank account attached. bankAccount was our original check which should
        // be okay since our UI only allows bankAccount to be added after stripe is already verified. see also
        // https://therapymatch.slack.com/archives/C0452GCE8D8/p1698353826326909
        (!billingAccountInfo?.isGroupPracticeBillingAccount &&
          !billingAccountInfo?.bankAccount)
      ) {
        feUnconfirmableReasons.push(
          SessionUnconfirmableReason.BILLING_ACCOUNT_NOT_VERIFIED
        );
      }

      if (!firstLoadedEventIds.current.has(event.virtualId)) {
        _checkAndLogMatchingUnconfirmableReasons(
          event,
          sessionDetailsConfirmabilityApiCheck?.unconfirmableReasons || [],
          feUnconfirmableReasons
        );
        firstLoadedEventIds.current.add(event.virtualId);
      }

      // These are the list of reasons that are only returned by the BE now
      const beReasonsToInclude = [
        SessionUnconfirmableReason.BILLING_TYPE_NOT_ELIGIBLE,
        SessionUnconfirmableReason.EXPIRED_LICENSE,
        SessionUnconfirmableReason.NO_LICENSE_FOUND_FOR_STATE,
        SessionUnconfirmableReason.NO_ANTHEM_EAP_FOUND,
        SessionUnconfirmableReason.ALL_PAYMENT_METHODS_HAVE_INVALID_PRE_AUTHS,
        SessionUnconfirmableReason.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE,
      ];

      const beIntersectionReasons = (
        sessionDetailsConfirmabilityApiCheck?.unconfirmableReasons || []
      ).filter((reason) => beReasonsToInclude.includes(reason));
      const uniqReasons = feUnconfirmableReasons.concat(beIntersectionReasons);
      return {
        canConfirmSessionDetails: uniqReasons.length === 0,
        isLoading: false,
        reasonsCannotConfirm: uniqReasons,
      };
    }
  );
};

const _checkAndLogMatchingUnconfirmableReasons = (
  event: ProviderEventRead,
  apiReasons: SessionUnconfirmableReason[],
  feReasons: SessionUnconfirmableReason[]
) => {
  const reasonsToCheck = [
    SessionUnconfirmableReason.PAST_DEADLINE_TO_CONFIRM,
    SessionUnconfirmableReason.SESSION_NOT_ALLOWED_TO_BE_EDITED,
    SessionUnconfirmableReason.PATIENT_CANNOT_BOOK_FOR_BILLING_TYPE,
    SessionUnconfirmableReason.PATIENT_MISSING_REQUIRED_FORMS,
    SessionUnconfirmableReason.PATIENT_NOT_CLAIM_READY,
    SessionUnconfirmableReason.PATIENT_HAS_BLOCKING_FREEZE,
  ];

  for (const reason of reasonsToCheck) {
    const foundInApi = apiReasons.includes(reason);
    const foundInFe = feReasons.includes(reason);
    if ((foundInApi && !foundInFe) || (!foundInApi && foundInFe)) {
      logException(
        new Error(`Mismatching unconfirmable reasons between FE and API`),
        {
          extra: {
            reason: reason,
            foundInApi: foundInApi,
            foundInFe: foundInFe,
            providerEventId: event.id,
            providerEventVirtualId: event.virtualId,
          },
        }
      );
      // TODO: Remove this within 3 weeks
      console.log(
        `Mismatching unconfirmable reasons between FE and API: ${reason}`
      );
      console.log({
        reason: reason,
        foundInApi: foundInApi,
        foundInFe: foundInFe,
        providerEventId: event.id,
        providerEventVirtualId: event.virtualId,
      });
    }
  }
};
