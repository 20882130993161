import { PdfPage } from '~/legacy/pdf/components/layout';
import { PdfText } from '~/legacy/pdf/components/typography';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';

import {
  AssessmentResultPdfHeader,
  AssessmentResultPdfHeaderProps,
} from './AssessmentResultPdfHeader';

type AssessmentResultPdfPageProps = AssessmentResultPdfHeaderProps & {
  assessment: PatientAssessmentRead;
};

export const AssessmentResultPdfPage = ({
  assessment,
  ...headerProps
}: AssessmentResultPdfPageProps) => (
  <PdfPage>
    <AssessmentResultPdfHeader {...headerProps} />
    <PdfText style={{ fontWeight: 'light' }}>
      TODO Assessment results go here
    </PdfText>
  </PdfPage>
);
