import { inject } from 'mobx-react';
import React from 'react';

import { FlagsConsumer } from '@headway/shared/FeatureFlags/react';
import { ReferProvider } from '@headway/ui/providers/ReferProvider';
import { ReferralProgram } from '@headway/ui/providers/ReferralProgram';

const ReferralAccountComponent = () => {
  return <></>;
};

interface ReferAProviderProps {
  AuthStore?: any;
}

export const ReferAProvider: React.FC<
  React.PropsWithChildren<ReferAProviderProps>
> = inject('AuthStore')(({ AuthStore }) => {
  return (
    <>
      <FlagsConsumer flagRequests={{ extolePhase2ReferralProgram: false }}>
        {({ extolePhase2ReferralProgram }) =>
          extolePhase2ReferralProgram ? (
            <ReferralProgram
              AuthStore={AuthStore}
              ReferralAccountComponent={ReferralAccountComponent}
            />
          ) : (
            <ReferProvider AuthStore={AuthStore} />
          )
        }
      </FlagsConsumer>
    </>
  );
});
