import { Formik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageHeader } from '@headway/helix/PageHeader';
import { TextField } from '@headway/helix/TextField';
import { getApiErrorMessage } from '@headway/shared/utils/api';

import SigmundAuthApi from 'api/AuthApi';
import { useAuthStore } from 'stores/AuthStore';

import { useTailwindGlobals } from '../../utils/css';

interface FormValues {
  email: string;
  password: string;
}

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Email is required.'),
  password: Yup.string().required('Password is required.'),
});

const LoginPage = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const initialValues: FormValues = {
    email: '',
    password: '',
  };

  const [error, setError] = useState<string | undefined>();
  const clearError = () => setError(undefined);

  const onSubmit = async (values: FormValues) => {
    clearError();
    try {
      await AuthApi.loginUser({
        email: values.email,
        password: values.password,
      });
      const response = await SigmundAuthApi.me();
      await authStore.setUser(response.data);
      navigate(location.state?.from || { pathname: '/' }, { replace: true });
    } catch (error: AnyTS4TryCatchUnknownError) {
      setError(getApiErrorMessage(error));
    }
  };

  useTailwindGlobals();

  return (
    <div className="mx-auto w-full px-4 tablet:w-[970px] desktop:w-[1020px]">
      <div className="rounded-sm mx-auto max-w-lg self-center justify-self-center bg-system-white px-6 py-5 shadow-medium">
        <h1>
          <PageHeader>Log in</PageHeader>
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {error ? (
                <GuidanceCard variant="error">
                  <BodyText>{error}</BodyText>
                </GuidanceCard>
              ) : null}
              <FormControl
                name="email"
                component={TextField}
                label="Email"
                autoComplete="email"
                data-testid="email-input"
              />
              <FormControl
                name="password"
                component={TextField}
                label="Password"
                type="password"
                autoComplete="current-password"
                data-testid="password-input"
                disabled={isSubmitting}
              />{' '}
              <Button
                type="submit"
                size="large"
                variant="primary"
                data-testid="login-button"
                disabled={isSubmitting}
              >
                Submit
              </Button>
              <div className="flex flex-col items-start gap-2">
                <LinkButton
                  component={RouterLink}
                  elementType="a"
                  variant="link"
                  to="/auth/forgot-password"
                >
                  Forgot your password?
                </LinkButton>

                <BodyText>
                  Interested in becoming a Headway provider?{' '}
                  <Link href="https://headway.co/for-providers#get-started">
                    Talk to one of our practice consultants.
                  </Link>
                </BodyText>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginPage;
