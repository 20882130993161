import { useFindProviderEvents } from '~/legacy/hooks/useFindProviderEvents';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';

import {
  isCancelledReferralAppointment,
  isPendingReferralAppointment,
  isReferralAppointment,
  patientBecameReadyForReferredAppointment,
} from '../views/Calendar/events/util/events';
import { UseFindProviderEventsQueryKeyArgs } from './useFindProviderEvents';

export const getLastCreatedAppointmentQueryKeyArgs = ({
  providerId,
  clientId,
}: {
  providerId: number;
  clientId: number;
}): UseFindProviderEventsQueryKeyArgs => {
  return {
    expand_estimated_prices: false,
    event_types: [ProviderEventType.APPOINTMENT],
    order_by: 'created_on',
    order: 'desc',
    provider_id: providerId,
    patient_user_id: clientId,
    limit: 1,
  };
};

export enum ProviderAppointmentReferralStatus {
  NOT_A_REFERRAL = 'NOT_A_REFERRAL',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  BECAME_APPOINTMENT_READY = 'BECAME_APPOINTMENT_READY',
}

export const getProviderAppointmentReferralStatusFromProviderEvent = (
  event: ProviderEventRead
) => {
  if (!isReferralAppointment(event)) {
    return ProviderAppointmentReferralStatus.NOT_A_REFERRAL;
  } else if (isPendingReferralAppointment(event)) {
    return ProviderAppointmentReferralStatus.PENDING;
  } else if (isCancelledReferralAppointment(event)) {
    return ProviderAppointmentReferralStatus.CANCELED;
  } else if (patientBecameReadyForReferredAppointment(event)) {
    return ProviderAppointmentReferralStatus.BECAME_APPOINTMENT_READY;
  } else {
    return null;
  }
};

/**
 * This hook finds the referral status for a given client based on their most recently created appointment.
 * It is a wrapper around the `useFindProviderEvents` hook. It fetches the most recently created appointment
 * for a given provider and patient, and uses that data to determine the client's referral status
 *
 * @returns ReferralStatus enum value
 *  */
export const useClientReferralStatus = ({
  providerId,
  clientId,
}: {
  providerId: number;
  clientId: number;
}) => {
  const lastCreatedApptQueryKeyArgs = getLastCreatedAppointmentQueryKeyArgs({
    providerId,
    clientId,
  });
  const patientReferralStatusQuery = useFindProviderEvents(
    lastCreatedApptQueryKeyArgs,
    {
      select: (response): ProviderAppointmentReferralStatus | null => {
        const event = response.data[0];
        if (!event) {
          return null;
        }

        return getProviderAppointmentReferralStatusFromProviderEvent(event);
      },
    }
  );

  return patientReferralStatusQuery;
};
