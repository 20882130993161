import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { IdentifyFlagsUser } from '@headway/shared/FeatureFlags/react';
import { trackEvent } from '@headway/shared/utils/analytics';
import { MarketProvider } from '@headway/ui/providers/MarketProvider';

import { useAuthStore } from 'stores/AuthStore';
import { PROVIDER_VIEW_STORAGE_KEY } from 'utils/contstants';
import {
  PROVIDER_REMINDER_SOURCE_MAP,
  ProviderReminderQueryParam,
} from 'utils/providerSmsReminderSource';

import { getLDAttributes } from './utils/analytics';
import { RemoveInitialLoadStyles } from './utils/RemoveInitialLoadStyles';

export const AuthRequired = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const AuthStore = useAuthStore();
  const location = useLocation();

  const trackSmsProviderReminder = (ref: string | string[]) => {
    if (
      !Array.isArray(ref) &&
      Object.values(ProviderReminderQueryParam).includes(
        ref as ProviderReminderQueryParam
      )
    ) {
      trackEvent({
        name: 'Provider Reminders Link Clicked',
        properties: {
          source:
            PROVIDER_REMINDER_SOURCE_MAP[ref as ProviderReminderQueryParam],
        },
      });
    }
  };

  useEffect(
    () => {
      const init = async () => {
        try {
          const params = queryString.parse(location?.search || '');
          if (params.ref) {
            trackSmsProviderReminder(params.ref);
          }

          if (params.provider_id) {
            window.localStorage.setItem(
              PROVIDER_VIEW_STORAGE_KEY,
              typeof params.provider_id === 'string'
                ? params.provider_id
                : params.provider_id[0]
            );
          }

          await AuthStore.fetchMe();
        } catch (e) {
          setIsLoading(false);
        }
        setIsLoading(false);
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const ldUserAttributes = getLDAttributes(
    AuthStore.user,
    AuthStore.provider,
    AuthStore.latestProviderQuestionnaireId,
    AuthStore.impersonatingUser?.id
  );

  if (isLoading) {
    return null;
  }

  const renderBody = !AuthStore.user?.id ? (
    <Navigate replace state={{ from: location }} to="/auth/login" />
  ) : (
    <div>
      <RemoveInitialLoadStyles />
      {children}
    </div>
  );

  return (
    <MarketProvider>
      <IdentifyFlagsUser
        user={AuthStore.user}
        userAttributes={ldUserAttributes}
      />
      {renderBody}
    </MarketProvider>
  );
};
