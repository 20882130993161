import { useProvider } from 'hooks';
import keyBy from 'lodash/keyBy';
import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { PriceTableRow } from '@headway/api/models/PriceTableRow';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useEligibilityLookup } from 'hooks/useEligibilityLookup';
import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useProviderFeeSchedule } from 'hooks/useProviderFeeSchedule';
import { useProviderPatient } from 'hooks/useProviderPatient';
import { useProviderPriceTable } from 'hooks/useProviderPriceTable';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';

interface PricingInfoTableProps {
  clientId: number;
  hasPriceChanged?: boolean;
}

export const PricingInfoTable = ({
  clientId,
  hasPriceChanged = false,
}: PricingInfoTableProps) => {
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { data: client } = useUser({ userId: clientId });
  const authStore = useAuthStore();
  const provider = useProvider();
  const insurance = client?.activeUserInsurance;
  const { insuranceStatus } = useInsuranceStatus(client, insurance);
  const { data: latestEligibilityLookup } = useEligibilityLookup({
    eligibilityLookupId: insurance?.latestEligibilityLookup?.id,
  });

  const { data: providerPatient } = useProviderPatient({
    patientId: clientId,
    providerId: provider.id,
  });

  const shouldShowAnthemEAPExperience = useShouldShowAnthemEAPExperience(
    clientId,
    provider.id,
    undefined
  );

  const { data: priceTableResponse } = useProviderPriceTable({
    provider,
    patient: client,
    insuranceStatus,
    latestEligibilityLookup,
    isAnthemEAPUser: shouldShowAnthemEAPExperience.data,
  });
  const {
    isLoading: isLoadingMatchingProviderFrontEndCarrier,
    data: matchingProviderFrontEndCarrier,
  } = useMatchingProviderFrontEndCarrierQuery({
    providerId: provider.id,
    patientFrontEndCarrierId: shouldShowAnthemEAPExperience.data
      ? FrontEndCarrierIdentifier.ANTHEM_EAP
      : insurance?.billingFrontEndCarrierId,
    patientInsuranceMemberId: shouldShowAnthemEAPExperience.data
      ? undefined
      : insurance?.memberId,
    patientUserId: shouldShowAnthemEAPExperience.data ? clientId : undefined,
  });

  const { data: schedules } = useProviderFeeSchedule(
    {
      providerId: provider.id,
      frontEndCarrierId: matchingProviderFrontEndCarrier?.frontEndCarrierId,
      eligibilityLookup: latestEligibilityLookup,
    },
    { enabled: !isLoadingMatchingProviderFrontEndCarrier }
  );
  const providerEarnings = keyBy(schedules, (schedule) => schedule.cpt_code);
  const areRatesViewable =
    !isMSCEnabled && hasRateAccess(provider, authStore.user);
  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;
  const isInOldDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;

  if (
    !client ||
    (!insurance && !shouldShowAnthemEAPExperience.data) ||
    providerPatient?.billingTypeDefault === BillingType.SELF_PAY ||
    (!latestEligibilityLookup &&
      !isInNoDataPrelimPricing &&
      !isInOldDataPrelimPricing &&
      !shouldShowAnthemEAPExperience.data) ||
    (latestEligibilityLookup?.outOfNetwork &&
      !shouldShowAnthemEAPExperience.data)
  ) {
    return null;
  }
  const priceTable = priceTableResponse?.priceTable;

  const formattedPatientName = formatPatientName(client, {
    firstNameOnly: true,
  });

  return (
    <>
      {priceTable && priceTable.length > 0 && (
        <Table aria-label="pricingTable" data-testid="pricingTable">
          <TableHeader>
            {[
              <Column key="cptCode">CPT Code</Column>,
              <Column key="responsibility" width="33.33%">
                <span className="flex flex-wrap gap-2">
                  {formattedPatientName} will pay{' '}
                  {hasPriceChanged && (
                    <span className="hlx-badge" data-hlx-variant="info">
                      Price changed
                    </span>
                  )}
                </span>
              </Column>,
              <Column key="earnings">You will earn</Column>,
            ].slice(0, areRatesViewable ? 3 : 2)}
          </TableHeader>
          <TableBody>
            {priceTable.map((row: PriceTableRow, idx: number) => (
              <Row key={idx}>
                {[
                  <Cell key="cptCode">{row.cptCode}</Cell>,
                  <Cell key="responsibility">
                    {row.patientResponsibility !== undefined
                      ? `$${row.patientResponsibility.toFixed(2)}`
                      : 'Unknown'}
                  </Cell>,
                  <Cell key="earnings">
                    {providerEarnings[row.cptCode] !== undefined
                      ? `$${providerEarnings[row.cptCode].amount.toFixed(2)}`
                      : 'Unknown'}
                  </Cell>,
                ].slice(0, areRatesViewable ? 3 : 2)}
              </Row>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
