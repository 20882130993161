import React, { useEffect, useRef } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { IconChevronDown } from '@headway/helix/icons/ChevronDown';
import { IconChevronUp } from '@headway/helix/icons/ChevronUp';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { BigPlus } from '@headway/icons/dist/provider/BigPlus';
import { Notes } from '@headway/icons/dist/provider/Notes';
import { Pencil } from '@headway/icons/dist/provider/Pencil';
import { Question } from '@headway/icons/dist/provider/Question';
import { PROMS_ONLY_RATE_BOOST } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { trackEvent } from '@headway/shared/utils/analytics';

import TherapistImage from 'assets/img/therapist-study.png';
import WalkthroughVideoThumbnail from 'assets/img/walkthrough-video-thumbnail.png';
import { PanelLayout } from 'layouts/PanelLayout';
import { INCENTIVE_BONUS_AMOUNT_DOLLARS } from 'views/Incentives/constants';

interface DetailContentsProps {
  details?: {
    label: string;
    body: React.ReactNode;
  }[];
  orientation: 'horizontal' | 'vertical';
}
export const DetailContents = ({
  details,
  orientation,
}: DetailContentsProps) => {
  if (!details) return null;

  return (
    <div
      className={`flex ${
        orientation === 'horizontal' ? 'flex-row' : 'flex-col'
      }`}
    >
      {details.map(({ label, body }, idx) => (
        <div
          key={idx}
          className={`border-collapse border-0 ${
            orientation === 'horizontal' ? 'border-r-[1px]' : 'border-b-[0px]'
          } border-solid border-[#4d4d4d] p-4 ${
            idx === details.length - 1
              ? orientation === 'horizontal'
                ? 'border-r-[0px]'
                : 'border-b-[0px]'
              : ''
          }`}
        >
          <CaptionText>
            <span className="text-primary-blue">
              <strong>{label}</strong>
            </span>
          </CaptionText>
          <div>{body}</div>
        </div>
      ))}
    </div>
  );
};

interface DetailCardProps {
  title?: string;
  icon?: 'pencil' | 'notes';
  details?: {
    label: string;
    body: React.ReactNode;
  }[];
  orientation: 'horizontal' | 'vertical';
}
const DetailCard = ({ title, details, icon, orientation }: DetailCardProps) => {
  return (
    <div className="flex basis-full flex-col rounded border border-solid">
      {!!title && (
        <div className="border-black flex gap-2 rounded-t border-[0px] border-b-[1px] border-solid bg-hue-lightBlue p-4">
          {icon === 'pencil' ? (
            <Pencil className="text-primary-blue" fontSize={20} />
          ) : (
            <Notes className="text-primary-blue" fontSize={20} />
          )}

          <BodyText>
            <span className="text-primary-blue">
              <strong>{title}</strong>
            </span>
          </BodyText>
        </div>
      )}

      <DetailContents details={details} orientation={orientation} />
    </div>
  );
};

interface CollapsableListItem {
  label: string;
  body: React.ReactNode;
}
interface CollapsableListProps {
  items: CollapsableListItem[];
}
const CollapsableList = ({ items }: CollapsableListProps) => {
  const [open, setOpen] = React.useState(items.map(() => false));

  const toggleOpen = (index: number) => {
    const openCopy = [...open];
    openCopy[index] = !openCopy[index];
    setOpen(openCopy);
  };

  return (
    <div className="flex flex-col">
      {items.map(({ label, body }, index) => (
        <div
          key={index}
          className="flex flex-col border-0 border-b border-solid border-system-borderGray"
        >
          <div
            className="flex gap-3 py-4 hover:cursor-pointer"
            onClick={() => toggleOpen(index)}
          >
            {open[index] ? (
              <IconChevronUp fontSize={20} />
            ) : (
              <IconChevronDown fontSize={20} />
            )}
            <BodyText>
              <strong>{label}</strong>
            </BodyText>
          </div>
          {open[index] && <div className="pb-4 ps-8 pt-2">{body}</div>}
        </div>
      ))}
    </div>
  );
};

const FAQList = () => {
  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);
  let items = [
    {
      label: promsOnlyRateBoostEnabled
        ? 'How will my assessments data be used?'
        : 'How will your notes and assessments data be used?',
      body: (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col">
            {promsOnlyRateBoostEnabled ? (
              <BodyText>
                Some Michigan insurers will use high-level assessment scores to
                demonstrate the quality of their network to customers.
              </BodyText>
            ) : (
              <>
                <BodyText>
                  <strong>Assessments</strong>
                </BodyText>
                <BodyText>
                  For assessments, some Michigan insurers will use high-level
                  assessment scores to demonstrate the quality of their network
                  to customers.
                </BodyText>
              </>
            )}
          </div>
          <BodyText>
            Insurers understand assessments are just one piece of a larger
            clinical picture. Still, sharing these results helps them better
            represent care quality across their provider network.
          </BodyText>
          {!promsOnlyRateBoostEnabled && (
            <div className="flex flex-col">
              <BodyText>
                <strong>Notes</strong>
              </BodyText>
              <BodyText>
                Headway collects notes purely for compliance purposes in the
                rare case of an insurer audit. Even in the event of these
                audits, Headway is usually able to resolve any questions
                independently, but may reach out to you if needed.
              </BodyText>
            </div>
          )}
        </div>
      ),
    },
    {
      label: 'What else should I know about assessment data?',
      body: (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col">
            <BodyText>
              <strong>On identifiable data</strong>
            </BodyText>
            <BodyText>
              Please note, as a partner with the Michigan Health Information
              Network Services — a state-designated non-profit designed to help
              improve the measure of care across the state — these insurers will
              periodically validate high-level assessments results with data on
              an individual client level, but will not share this info publicly.
            </BodyText>
          </div>
          <div className="flex flex-col">
            <BodyText>
              <strong>On access to care</strong>
            </BodyText>
            <BodyText>
              Insurers will use assessment info to confirm a record of ongoing
              care and demonstrate the quality of care to potential customers,
              not to limit your clients' assess to care or assess your
              eligibility to use Headway.
            </BodyText>
          </div>
        </div>
      ),
    },
    {
      label: 'Where can I find my payments?',
      body: (
        <BodyText>
          From your <strong>Payments</strong> page, click on the{' '}
          <strong>History</strong> tab to see past payments, with rate boosts
          listed as separate line items from standard payments. You'll receive
          boosts every 15 days for all sessions that meet the requirements.
        </BodyText>
      ),
    },
  ];

  if (promsOnlyRateBoostEnabled) {
    const prependedItems = [
      {
        label: 'What are assessments and what is the clinical benefit?',
        body: (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col">
              <BodyText>
                Assessments are simple questionnaires your clients take on a
                regular basis, such as weekly or monthly. Widely used by
                providers across both medical and mental health, they can help
                you detect issues early, assess treatment effectiveness and give
                your client a better sense of progress.
              </BodyText>
            </div>
            <BodyText>
              What’s more, as part of a special program in Michigan, they can
              also unlock a rate boost for every session when your client
              completes at least one every 30 days.
            </BodyText>
          </div>
        ),
      },
      {
        label:
          'How often does my client need to complete assessments for me to get the maximum boost benefit?',
        body: (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col">
              <BodyText>
                Your client must simply complete one assessment every 30 days
                for you to receive the boost for{' '}
                <strong>each and every session</strong> with the client as part
                of this program.
              </BodyText>
            </div>
          </div>
        ),
      },
      {
        label: 'What happens if my client stops taking assessments?',
        body: (
          <BodyText>
            You’ll be paid a rate boost for all sessions within 30 days of the
            last date your client completed an assessment. After this 30-day
            period, you’ll no longer receive a boost. However, you’ll start
            receiving the boost again the next time that client completes an
            assessment.
          </BodyText>
        ),
      },
    ];

    items = [...prependedItems, ...items];
  }

  return <CollapsableList items={items} />;
};

export const RateBoost = () => {
  const rate = INCENTIVE_BONUS_AMOUNT_DOLLARS;

  const faqRef = useRef<HTMLDivElement>(null);
  const isBelowTabletMedia = useMediaQuery(theme.__futureMedia.below('tablet'));

  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  useEffect(() => {
    trackEvent({ name: 'Rate Boost Splash Page Viewed' });
  }, []);

  return (
    <PanelLayout>
      <div className="flex flex-col gap-12 pb-[60px] pt-[60px] tablet:flex-row">
        <div id="left" className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <span
              className="hlx-typography-brand-section"
              style={{ fontSize: '36px', lineHeight: '43px', width: '91%' }}
            >
              Earn a ${rate} rate boost for sessions with clients in Michigan
            </span>
            {promsOnlyRateBoostEnabled ? (
              <BodyText>
                Have your client complete an assessment once every 30 days and
                earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} for{' '}
                <strong>each and every session</strong> with that client — on
                top of your standard rate.
              </BodyText>
            ) : (
              <BodyText>
                Starting today, if you meet the notes and assessments
                requirements, you'll get a ${rate} rate boost per session.
              </BodyText>
            )}
            <div className="flex gap-1">
              <div className="shrink-0 p-1">
                <Question className="text-primary-blue" fontSize={20} />
              </div>
              <div className="flex flex-col">
                <BodyText>
                  <span className="text-primary-blue">
                    <strong>Why we're offering this</strong>
                  </span>
                </BodyText>
                <span>
                  <BodyText>
                    We want to get you the best rates possible. Some insurers
                    will pay more when you can demonstrate care quality through
                    assessments.{' '}
                  </BodyText>
                  <LinkButton
                    variant="link"
                    onPress={() => {
                      faqRef?.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Read more
                  </LinkButton>
                </span>
              </div>
            </div>
          </div>
          {promsOnlyRateBoostEnabled ? (
            <DetailCard
              title="Earn rate boosts when clients complete assessments"
              icon="pencil"
              orientation="horizontal"
              details={[
                {
                  label: 'CRITERIA',
                  body: (
                    <div className="mt-3">
                      <BodyText>
                        Your client completes any assessment of your choosing on
                        Headway, once every 30 days
                      </BodyText>
                    </div>
                  ),
                },
                {
                  label: 'HOW TO',
                  body: (
                    <NumberedList>
                      <NumberedListItem>
                        <div>
                          Choose a client from the{' '}
                          <Link href={'/clients'} target="_blank">
                            directory
                          </Link>
                          , then click on the <strong>Assessments tab.</strong>
                        </div>
                      </NumberedListItem>
                      <NumberedListItem>
                        Select at least one assessment to send every 30 days.
                      </NumberedListItem>
                      <NumberedListItem>
                        Remind your client to complete their assessments during
                        your next session.
                      </NumberedListItem>
                    </NumberedList>
                  ),
                },
              ]}
            />
          ) : (
            <>
              <div className="flex flex-col gap-4">
                <span
                  className="hlx-typography-brand-section"
                  style={{ fontSize: '28px', lineHeight: '34px' }}
                >
                  Criteria:
                </span>

                <div
                  className={`flex gap-4 ${
                    isBelowTabletMedia ? 'flex-col' : 'flex-row'
                  }`}
                >
                  <DetailCard
                    title="Notes"
                    icon="notes"
                    orientation="vertical"
                    details={[
                      {
                        label: 'CRITERIA',
                        body: (
                          <ul className="m-0">
                            <li>
                              <BodyText>
                                Upload a copy of your notes OR use Headway
                                templates for session notes.
                              </BodyText>
                            </li>
                          </ul>
                        ),
                      },
                    ]}
                  />
                  <div
                    className={`flex justify-center ${
                      isBelowTabletMedia ? '' : 'mt-3'
                    }`}
                  >
                    <BigPlus fontSize={48} />
                  </div>
                  <DetailCard
                    title="Assessments"
                    icon="pencil"
                    orientation="vertical"
                    details={[
                      {
                        label: 'CRITERIA',
                        body: (
                          <ul className="m-0">
                            <li>
                              <BodyText>
                                Your client completes any assessment of your
                                choosing on Headway 30 days before or after the
                                session.
                              </BodyText>
                            </li>
                          </ul>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <span
                  className="hlx-typography-brand-section"
                  style={{ fontSize: '28px', lineHeight: '34px' }}
                >
                  How to get started for each client
                </span>
                <div
                  className={`flex gap-4 ${
                    isBelowTabletMedia ? 'flex-col' : 'flex-row'
                  }`}
                >
                  <DetailCard
                    orientation="horizontal"
                    details={[
                      {
                        label: 'NOTES',
                        body: (
                          <div className="mt-4 flex flex-col gap-4">
                            <BodyText>
                              When confirming session details, either{' '}
                              <strong>Fill out a template</strong> OR{' '}
                              <strong>upload existing note</strong>
                            </BodyText>
                            <BodyText>
                              Notes data will not be shared with payers unless
                              in the rare case of an audit.
                            </BodyText>
                          </div>
                        ),
                      },
                      {
                        label: 'ASSESSMENTS',
                        body: (
                          <NumberedList>
                            <NumberedListItem>
                              <div>
                                Choose a client from the{' '}
                                <Link href={'/clients'} target="_blank">
                                  directory
                                </Link>
                                , then click on the{' '}
                                <strong>Assessments tab.</strong>
                              </div>
                            </NumberedListItem>
                            <NumberedListItem>
                              Select at least one assessment to send every 30
                              days.
                            </NumberedListItem>
                            <NumberedListItem>
                              Remind your client to complete their assessments
                              during your next session.
                            </NumberedListItem>
                          </NumberedList>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </>
          )}
          <div className="flex gap-4">
            <Link
              href={
                promsOnlyRateBoostEnabled
                  ? 'https://www.youtube.com/watch?v=M88GUTOzxzE'
                  : 'https://youtu.be/QpjPTmXygz0'
              }
              target="_blank"
            >
              <div
                className={`flex h-[72px] w-[118px] items-center justify-center rounded px-10`}
                css={{
                  backgroundImage: `url(${WalkthroughVideoThumbnail})`,
                  backgroundSize: `cover`,
                }}
              >
                <div
                  className={`flex h-[32px] w-[32px] items-center justify-center rounded-full border-2 
                  border-solid border-system-borderGray bg-primary-blue`}
                >
                  <div
                    className="border-transparent ml-1 h-0 w-0 border-solid"
                    style={{
                      borderWidth: '8px 0 8px 12px',
                      borderColor:
                        'transparent transparent transparent #FFFFFF',
                    }}
                  ></div>
                </div>
              </div>
            </Link>
            <div className="flex flex-col gap-1">
              <CaptionText>
                <span className="text-primary-blue">
                  <strong>TUTORIAL</strong>
                </span>
              </CaptionText>
              <BodyText>
                <Link
                  href={
                    promsOnlyRateBoostEnabled
                      ? 'https://www.youtube.com/watch?v=M88GUTOzxzE'
                      : 'https://youtu.be/QpjPTmXygz0'
                  }
                  target="_blank"
                >
                  Watch a 3 minute walkthrough
                </Link>{' '}
                from Headway’s clinical team for a step by step guide on how to
                unlock rate boosts.
              </BodyText>
            </div>
          </div>
          <div ref={faqRef} className="flex flex-col gap-8">
            <span
              className="hlx-typography-brand-section"
              style={{ fontSize: '28px', lineHeight: '34px' }}
            >
              Frequently asked questions
            </span>
            <FAQList />
          </div>
        </div>
        <div
          id="right"
          className="invisible flex-none tablet:visible"
          style={{ width: 'calc(30vw - (100vw - 100%)/2)' }}
        >
          <img
            src={TherapistImage}
            alt="therapist"
            className="fixed right-0 h-auto w-full max-w-[30vw]"
          />
        </div>
      </div>
    </PanelLayout>
  );
};
