import { FormikProps } from 'formik';
import { useProvider } from 'hooks';
import merge from 'lodash/merge';
import moment from 'moment';
import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ValidationError } from 'yup';

import { BillingType } from '@headway/api/models/BillingType';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  CONTROLLED_SUBSTANCE_DATA_COLLECTION,
  PRESCRIBER_PSYCHOTHERAPY_TIMES,
  PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
  TELEHEALTH_LOCATIONS_SIGMUND,
} from '@headway/shared/FeatureFlags/flagNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useQueryClient } from '@headway/shared/react-query';
import { CptCodeOption } from '@headway/ui/CPTCodeInput';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddresses } from 'hooks/usePatientAddresses';
import {
  useProviderEvent,
  useProviderEventCache,
} from 'hooks/useProviderEvent';
import {
  UpdateProviderEventMutationArgs,
  useUpdateProviderEventMutation,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';
import { isPast } from 'views/Calendar/events/util/events';
import { useDocumentationRequirement } from 'views/Calendar/utils/documentationRequirement';
import { useUpdateProviderEventSideEffectsForCalendar } from 'views/Calendar/utils/queries';
import { Attachment } from 'views/Patients/AttachmentsList';

import { convertAttachmentFormValues } from '../Forms/AppointmentAttachmentForm';
import { fetchMiscodingResults } from '../Forms/SessionDetails/FormValidation/miscoding';
import { validationSchema } from '../Forms/SessionDetails/FormValidation/validationSchema';
import {
  convertFormValuesToProviderEventUpdate,
  isSessionDetailsFormValueTelehealth,
  SessionDetailsFormValues,
} from '../Forms/SessionDetails/SessionDetailsForm';
import { isBillingAddOnPsychotherapy } from '../util';
import { ProgressNoteContext } from './ProgressNotesContext';

export enum AppointmentState {
  UNKNOWN,
  PRE_APPOINTMENT,
  POST_APPOINTMENT,
  DETAILS_CONFIRM_REQUEST,
  DETAILS_CONFIRMED,
}

export type AppointmentContextType = {
  appointmentState: AppointmentState;
  updateAppointment: ({
    eventIdOrVirtualId,
    update,
  }: {
    eventIdOrVirtualId: string | number;
    update: ProviderEventUpdate;
  }) => Promise<void>;
  updateAttachments: (eventIdOrVirtualId: string | number) => Promise<void>;
  confirmSessionDetailsAttempted: boolean;
  onSessionDetailsConfirm: (
    eventIdOrVirtualId: string | number
  ) => Promise<boolean>;
  selectedCptCodes: string[];
  insuranceStatus: PatientInsuranceOrEAPStatus;
  insuranceStatusWithCheckState: PatientInsuranceOrEAPStatus;
  appointmentAddressState: UnitedStates | undefined;
  isInsuranceAppointment?: boolean;
  updateAppointmentAddressState: (
    providerAddressId?: number | null,
    appointmentLocationPatientAddressId?: number | null
  ) => void;
};

export const AppointmentContext = React.createContext<AppointmentContextType>({
  appointmentState: AppointmentState.PRE_APPOINTMENT,
  updateAppointment: () => Promise.resolve(),
  updateAttachments: () => Promise.resolve(),
  confirmSessionDetailsAttempted: false,
  onSessionDetailsConfirm: () => new Promise(() => false),
  selectedCptCodes: [],
  insuranceStatus: PatientInsuranceOrEAPStatus.IN_NETWORK, // We assume they are in network until told otherwise
  insuranceStatusWithCheckState: PatientInsuranceOrEAPStatus.IN_NETWORK,
  appointmentAddressState: undefined,
  isInsuranceAppointment: undefined,
  updateAppointmentAddressState: () => {},
});

export const AppointmentContextProvider = ({
  children,
  onProgressNoteUpdate,
  updateSavingState,
  sessionDetailsRef,
  sessionDetailsErrorRef,
  attachmentRef,
  eventVirtualId,
  patient,
  setSelectedEvent,
}: {
  children: ReactNode;
  onProgressNoteUpdate?: () => void;
  sessionDetailsErrorRef: MutableRefObject<FormikProps<any> | undefined>;
  sessionDetailsRef: MutableRefObject<
    FormikProps<SessionDetailsFormValues> | undefined
  >;
  attachmentRef: MutableRefObject<
    FormikProps<{ attachments: Attachment<string>[] }> | undefined
  >;

  updateSavingState: (changeTo: boolean) => void;
  eventVirtualId: string;
  patient: UserRead;
  setSelectedEvent?: (event: ConcreteProviderEventRead) => void;
}) => {
  const provider = useProvider();
  const { providerAddresses } = useContext(ProviderAddressContext);
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const queryClient = useQueryClient();
  const [appointmentState, setAppointmentState] = useState<AppointmentState>(
    AppointmentState.UNKNOWN
  );
  const [triedSubmittingDetails, setTriedSubmittingDetails] = useState(false);
  const { isRequired: isDocumentationRequired } = useDocumentationRequirement(
    provider,
    patient,
    event?.providerAppointment
  );
  const { progressNoteType, selectedTemplate } =
    useContext(ProgressNoteContext);

  const [selectedCptCodes, setSelectedCptCodes] = useState<string[]>(
    event?.providerAppointment?.cptCodes ?? []
  );
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction } =
    useMSCGuardrail();
  const isTelehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );
  const isTelehealthAppointment = event?.telehealth;
  const isControlledSubstanceDataCollectionEnabled = useFlag(
    CONTROLLED_SUBSTANCE_DATA_COLLECTION,
    false
  );

  const prescriberPsychotherapyTimesCarriers = useFlag(
    PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
    []
  );

  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: provider?.id!,
        patientUserId: patient?.id!,
        appointmentId: event?.providerAppointment?.id,
      },
      { enabled: !!provider && !!patient }
    );

  const matchingCarrierId = matchingProviderFrontEndCarrier?.frontEndCarrierId;
  const isPrescriberPsychotherapyTimesEnabled =
    prescriberPsychotherapyTimesCarriers?.includes(matchingCarrierId);

  const shouldRequirePrescriberPsychotherapyTimes =
    isPrescriberPsychotherapyTimesEnabled &&
    provider.isPrescriber &&
    isBillingAddOnPsychotherapy(selectedCptCodes);

  const { data: patientAddresses } = usePatientAddresses({
    patientId: patient?.id,
  });

  const [appointmentAddressState, setAppointmentAddressState] =
    useState<UnitedStates>();
  const [
    sessionDetailsFormValueTelehealth,
    setSessionDetailsFormValueTelehealth,
  ] = useState(false);

  const updateAppointmentAddressState = (
    providerAddressId?: number | null,
    appointmentLocationPatientAddressId?: number | null
  ) => {
    if (isSessionDetailsFormValueTelehealth(providerAddressId)) {
      setSessionDetailsFormValueTelehealth(true);
      if (appointmentLocationPatientAddressId && patientAddresses) {
        setAppointmentAddressState(
          patientAddresses.find(
            (address) => address.id === appointmentLocationPatientAddressId
          )?.state
        );
      }
    } else {
      if (providerAddressId) {
        const providerAddressState = providerAddresses.find(
          (address) => address.id === providerAddressId
        )?.state;
        if (
          providerAddressState &&
          providerAddressState in abbreviationToStateEnum
        ) {
          setAppointmentAddressState(
            abbreviationToStateEnum[providerAddressState] as UnitedStates
          );
        }
      }
    }
  };

  // Determines if the patient is network or not with the provider
  // using the given appointment details.
  const { insuranceStatus, isLoading: isInsuranceStatusLoading } =
    useInsuranceStatus(
      patient,
      patient.activeUserInsurance,
      sessionDetailsFormValueTelehealth,
      appointmentAddressState
    );

  const {
    insuranceStatus: insuranceStatusWithCheckState,
    isLoading: isInsuranceStatusLoadingWithCheckState,
  } = useInsuranceStatus(
    patient,
    patient.activeUserInsurance,
    sessionDetailsFormValueTelehealth,
    appointmentAddressState,
    !!isMSCGuardrailWarning
  );

  const providerEventCache = useProviderEventCache();
  const updateEventMutation = useUpdateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useUpdateProviderEventSideEffectsForCalendar()),
  });

  const onUpdate = useCallback(
    async ({
      eventIdOrVirtualId,
      update,
    }: {
      eventIdOrVirtualId: string | number;
      update: ProviderEventUpdate;
    }) => {
      updateSavingState(true);

      const updatedEvent = await updateEventMutation.mutateAsync({
        eventIdOrVirtualId,
        update,
      });

      setSelectedCptCodes(updatedEvent?.providerAppointment?.cptCodes ?? []);

      if (setSelectedEvent && updatedEvent) {
        setSelectedEvent(updatedEvent);
      }

      updateSavingState(false);
      if (onProgressNoteUpdate) onProgressNoteUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [event]
  );

  useEffect(() => {
    if (!event?.providerAppointment) {
      return;
    }

    if (
      event.providerAppointment?.status ===
      ProviderAppointmentStatus.DETAILS_CONFIRMED
    ) {
      setAppointmentState(AppointmentState.DETAILS_CONFIRMED);
      return;
    }

    if (moment(event.endDate).isAfter(moment())) {
      setAppointmentState(AppointmentState.PRE_APPOINTMENT);
      return;
    }

    if (moment(event.endDate).isBefore(moment())) {
      setAppointmentState(AppointmentState.POST_APPOINTMENT);
      return;
    }
  }, [event]);

  const onDetailsConfirmRequest = async (
    eventIdOrVirtualId: string | number
  ): Promise<boolean> => {
    setTriedSubmittingDetails(true);

    let sessionDetailsIsValid = false;

    if (!sessionDetailsRef.current) {
      return false;
    }

    if (!event) {
      return false;
    }

    const {
      cptCodes,
      diagnosisCodes,
      exactStartTime,
      exactEndTime,
      prescriberPsychotherapyStartTime,
      prescriberPsychotherapyEndTime,
    } = sessionDetailsRef.current.values;
    const { errors: codingErrors } = await fetchMiscodingResults(
      queryClient,
      cptCodes || [],
      diagnosisCodes || [],
      exactStartTime,
      exactEndTime,
      patient,
      provider,
      event,
      prescriberPsychotherapyStartTime,
      prescriberPsychotherapyEndTime
    );

    const hasCodingErrors = codingErrors.length > 0;

    // Touch the submit form fields since we validate through yup below
    const { setFieldTouched, values } = sessionDetailsRef.current;
    Array.from(Object.keys(values)).forEach((field) => setFieldTouched(field));

    // Since the form may not have updated with the new validation schema yet, we must manually
    // validate.
    const schema = validationSchema(
      provider,
      progressNoteType,
      selectedTemplate,
      isDocumentationRequired,
      (isMSCEnabled || isMSCGuardrailRestriction) && isTelehealthAppointment,
      isTelehealthLocationsEnabled,
      isControlledSubstanceDataCollectionEnabled,
      shouldRequirePrescriberPsychotherapyTimes
    );
    try {
      await schema.validate(sessionDetailsRef.current!.values, {
        abortEarly: false,
      });
    } catch (error: any) {
      if (!(error instanceof ValidationError)) {
        throw error;
      }
      for (const { path, message } of error.inner) {
        sessionDetailsRef.current?.setFieldError(path, message);
      }
    }

    const isConflictingSessionError = codingErrors.find(
      (error) => error.preferredLocation === 'SESSION_TIME'
    );

    if (sessionDetailsRef.current.isValid) {
      sessionDetailsIsValid = true;
    } else {
      if (sessionDetailsErrorRef.current) {
        (sessionDetailsErrorRef.current as any).scrollIntoView();
      }
      return false;
    }

    if (attachmentRef.current) {
      await attachmentRef.current.validateForm();
      if (!attachmentRef.current.isValid) {
        return false;
      }
    }

    if (!sessionDetailsIsValid || hasCodingErrors) {
      //  if there is overlapping appointment error, scroll to error banner
      if (isConflictingSessionError) {
        (sessionDetailsErrorRef.current as any).scrollIntoView({
          block: 'center',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
      if (isPast(event)) {
        setAppointmentState(AppointmentState.POST_APPOINTMENT);
        return false;
      }
    }

    const convertedSessionDetailValues: ProviderEventUpdate =
      convertFormValuesToProviderEventUpdate(sessionDetailsRef.current.values);

    const update = merge(convertedSessionDetailValues, {
      providerAppointment: {
        status: ProviderAppointmentStatus.DETAILS_CONFIRMED,
        attachments: attachmentRef.current?.values.attachments
          ? convertAttachmentFormValues(
              attachmentRef.current?.values.attachments
            )
          : undefined,
      },
    });

    await onUpdate({ eventIdOrVirtualId, update });
    setAppointmentState(AppointmentState.DETAILS_CONFIRMED);
    return true;
  };

  const updateAttachments = async (eventIdOrVirtualId: string | number) => {
    if (attachmentRef.current) {
      await attachmentRef.current.validateForm();
      if (!attachmentRef.current.isValid) {
        return;
      }
    }

    if (
      !attachmentRef.current?.values.attachments ||
      attachmentRef.current?.values.attachments.length === 0
    ) {
      return;
    }

    const update = {
      providerAppointment: {
        attachments: attachmentRef.current?.values.attachments
          ? convertAttachmentFormValues(
              attachmentRef.current?.values.attachments
            )
          : undefined,
      },
    };

    await onUpdate({ eventIdOrVirtualId, update });
  };

  const isInsuranceAppointment =
    event?.providerAppointment?.billingType === BillingType.INSURANCE;

  const value = {
    appointmentState,
    updateAppointment: onUpdate,
    updateAttachments,
    onSessionDetailsConfirm: onDetailsConfirmRequest,
    confirmSessionDetailsAttempted: triedSubmittingDetails,
    selectedCptCodes,
    insuranceStatus: isInsuranceStatusLoading
      ? PatientInsuranceOrEAPStatus.IN_NETWORK
      : insuranceStatus,
    insuranceStatusWithCheckState: isInsuranceStatusLoadingWithCheckState
      ? PatientInsuranceOrEAPStatus.IN_NETWORK
      : insuranceStatusWithCheckState,
    appointmentAddressState,
    isInsuranceAppointment,
    updateAppointmentAddressState,
  };

  return (
    <AppointmentContext.Provider value={value}>
      {children}
    </AppointmentContext.Provider>
  );
};
