import { useFormikContext } from 'formik';
import DOMPurify from 'isomorphic-dompurify';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';

import { ComponentMap, FormFile, FormValues } from '../schema/schema.types';
import {
  Component,
  Condition,
  Form,
  InfoComponentTypes,
  Section,
} from '../schema/schema.types';
import {
  doesComponentExpectAnArrayResponse,
  doesDataHaveRequiredStateProperties,
  isElementASection,
} from '../schema/utils';
import {
  addRuntimeErrorToSet,
  removeRuntimeErrorFromSet,
  RuntimeErrorType,
} from '../view/errorMessage';
import { CollapsibleSection } from './components/CollapsibleSection';

/*

TODO: Refactor progress notes specific stuff

import { DoAndDont } from '../../../../components/DoAndDont';
import { InlineTreatmentPlan } from '../../../../components/InlineTreatmentPlan';
import { TemplateAnalyticsNames } from '../../../../ProgressNotesForm';
*/

type RendererProps = {
  form: FormFile;
  injectedData: Object;
  disabled?: boolean;
  readOnly?: boolean;

  setRuntimeErrors?: (errors: Set<RuntimeErrorType>) => void;

  runtimeErrors?: Set<RuntimeErrorType>;

  // TODO: Refactor progress notes specific stuff
  event?: any;
  patient?: any;
  providerPatient?: ProviderPatientRead;
  sendTemplateAnalytics?: (trackingEventName: any) => void;
};

export function getInitialTemplateValues(template?: Form, values?: FormValues) {
  const setInitialValue = (obj: any, component: Component) => {
    let initialValue: string | string[] = '';
    if (values?.[component.id]) {
      initialValue = values[component.id];
    } else if (doesComponentExpectAnArrayResponse(component)) {
      initialValue = [];
    }
    return (obj[component.id] = initialValue);
  };

  const obj: any = {};

  if (template) {
    for (let i = 0; i < template.length; i++) {
      const element: any = template[i];

      if (isElementASection(element)) {
        (element.components as Component[]).forEach((component: Component) => {
          if (!component.readonly) setInitialValue(obj, component);
        });
      } else setInitialValue(obj, element);
    }
  }

  return obj;
}

export const passesConditionCheck = (
  formValues: FormValues,
  conditions?: Condition[]
) => {
  if (!conditions) {
    return true;
  }

  let passesConditionalCheck = true;
  if (conditions) {
    conditions.forEach((condition) => {
      let componentValue: string | string[] | undefined = get(
        formValues,
        condition.componentId
      );

      let responseValue = condition.responseValue;

      if (
        Array.isArray(condition.responseValue) &&
        Array.isArray(componentValue)
      ) {
        componentValue = [...componentValue].sort();
        responseValue = [...responseValue].sort();
      }

      if (!isEqual(componentValue, condition.responseValue)) {
        passesConditionalCheck = false;
      }
    });
  }

  return passesConditionalCheck;
};

const renderComponent = (
  component: Component,
  isOptional: boolean,
  form: Form,
  injectedData: Object,
  disabled: boolean,
  readOnly: boolean
) => {
  const { values } = useFormikContext<any>();

  if (!passesConditionCheck(values, component.conditions)) {
    return null;
  }

  return React.createElement(
    ComponentMap[component.type as keyof typeof ComponentMap],
    {
      key: component.id,
      isOptional,
      form,
      disabled,
      readOnly,
      element: component,
      injectedData,
    }
  );
};

export function Renderer({
  form,
  disabled = false,
  readOnly = false,
  injectedData,
  runtimeErrors,
  setRuntimeErrors,
}: RendererProps) {
  const { values } = useFormikContext<any>();
  const [isWaitingOnRequiredData, setIsWaitingOnRequiredData] = useState(false);

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });

  useEffect(() => {
    if (!form.state || Object.keys(form.state).length === 0) {
      return;
    }

    const doesntHaveRequiredStateProperties =
      !doesDataHaveRequiredStateProperties(form.state, injectedData);

    if (doesntHaveRequiredStateProperties) {
      setRuntimeErrors &&
        setRuntimeErrors(
          addRuntimeErrorToSet(
            runtimeErrors,
            RuntimeErrorType.MISSING_REQUIRED_INJECTED_DATA
          )
        );
    } else {
      setRuntimeErrors &&
        setRuntimeErrors(
          removeRuntimeErrorFromSet(
            runtimeErrors,
            RuntimeErrorType.MISSING_REQUIRED_INJECTED_DATA
          )
        );
    }

    setIsWaitingOnRequiredData(doesntHaveRequiredStateProperties);
  }, [injectedData]);

  if (isWaitingOnRequiredData) {
    return (
      <div css={{ alignSelf: 'center' }}>
        <BodyText>Waiting for required form data...</BodyText>
      </div>
    );
  }

  return (
    <>
      {form.form.map((element: Section | Component, i: number) => {
        if (isElementASection(element)) {
          const section = element as Section;

          if (!passesConditionCheck(values, section.conditions)) {
            return null;
          }

          /*
          TODO: Progress Notes specific

          if (
            section.shouldDisplay &&
            !callFunctions<'isPatientAgeInInclusiveRange'>(
              { patient, event },
              section.shouldDisplay
            )
          ) {
            return null;
          }
          */

          let sectionGapSpacing;

          if (section.style?.spacing) {
            switch (section.style?.spacing) {
              case 'regular':
                sectionGapSpacing = theme.spacing.x4;
                break;
              case 'small':
                sectionGapSpacing = theme.spacing.x1;
                break;
              default:
                sectionGapSpacing = theme.spacing.x4;
                break;
            }
          } else {
            sectionGapSpacing = theme.spacing.x4;
          }

          const sectionContent = (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: sectionGapSpacing,
                marginTop: theme.spacing.x4,
              }}
            >
              {section.infoComponent && section.infoComponent.data ? (
                section.infoComponent.type === InfoComponentTypes.doAndDont ? (
                  <>
                    {/*
                    TODO: Refactor progress notes specific stuff

                    <DoAndDont
                      data={section.infoComponent.data as DoAndDontData}
                    />
                  */}
                  </>
                ) : section.infoComponent.type ===
                  InfoComponentTypes.infoList ? (
                  <></>
                ) : null
              ) : null}
              {(section.components as Component[]).map(
                (component: Component) => {
                  const isOptional = false;
                  /*
                  TODO: Refactor progress notes specific stuff

                  const isOptional = !isComponentRequired(
                    event,
                    component as any
                  );*/
                  const showInlineTreatmentPlan = (component as any).metadata
                    ?.showInlineTreatmentPlan;

                  /*
                  TODO: Refactor progress notes specific stuff

                  if (
                    component.shouldDisplay &&
                    !callFunctions<'isPatientAgeInInclusiveRange'>(
                      { patient },
                      component.shouldDisplay
                    )
                  ) {
                    return null;
                  }*/

                  return (
                    <>
                      {renderComponent(
                        component,
                        isOptional,
                        form.form,
                        injectedData,
                        disabled,
                        readOnly
                      )}
                      {showInlineTreatmentPlan && !disabled && (
                        <>
                          {/*
                          TODO: Refactor progress notes specific stuff
                        <InlineTreatmentPlan
                          patient={patient}
                          providerPatient={providerPatient}
                          sendTemplateAnalytics={sendTemplateAnalytics}
                      />*/}
                        </>
                      )}
                    </>
                  );
                }
              )}
            </div>
          );

          return section.collapsible ? (
            <CollapsibleSection
              disabled={disabled}
              header={section.header}
              subHeader={section.subHeader}
              isCollapsibleOpen={section.collapsible?.defaultOpen}
              defaultOptionBehavior={section.defaultOptionBehavior}
              components={section.components as Component[]}
            >
              {sectionContent}
            </CollapsibleSection>
          ) : (
            <div
              key={i}
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  width: 'fit-content',
                  flexDirection: 'column',
                }}
              >
                <span
                  css={[
                    theme.typography.sectionHeader,
                    {
                      color: disabled
                        ? theme.color.system.disabledGray
                        : theme.color.system.textBlack,
                    },
                  ]}
                >
                  {section.header}
                </span>
                {section.subHeader && (
                  <span
                    css={[
                      theme.typography.subbody.regular,
                      {
                        color: disabled
                          ? theme.color.system.disabledGray
                          : theme.color.system.textBlack,
                      },
                    ]}
                  >
                    {section.subHeader}
                  </span>
                )}
              </div>
              {sectionContent}
            </div>
          );
        } else {
          const component = element as Component;
          const isOptional = false;

          /*
            TODO: Refactor progress notes specific stuff

            const isOptional = !isComponentRequired(event, element as any);
          */
          return renderComponent(
            component,
            isOptional,
            form.form,
            injectedData,
            disabled,
            readOnly
          );
        }
      })}
    </>
  );
}
