import { View } from '@react-pdf/renderer';
import { PdfText } from '~/legacy/pdf/components/typography';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { theme } from '@headway/helix/theme';
import { formatPatientName } from '@headway/shared/utils/patient';

export type AssessmentResultPdfHeaderProps = {
  client: UserRead;
  provider: ProviderRead;
};

export const AssessmentResultPdfHeader = ({
  client,
  provider,
}: AssessmentResultPdfHeaderProps) => {
  const clientName = formatPatientName(client);

  return (
    <View
      style={{
        flexDirection: 'row',
        gap: 24,
        paddingBottom: 12,
        borderBottom: `0.67pt solid ${theme.color.system.borderGray}`,
      }}
    >
      <PdfText>
        Client: <PdfText style={{ fontWeight: 'bold' }}>{clientName}</PdfText>
      </PdfText>
      <PdfText>
        Provider:{' '}
        <PdfText style={{ fontWeight: 'bold' }}>{provider.name}</PdfText>
      </PdfText>
    </View>
  );
};
