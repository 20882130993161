import { MenuTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { HeadwayLogo } from '@headway/helix/HeadwayLogo';
import { theme } from '@headway/helix/theme';
import { VisuallyHidden } from '@headway/ui';
import { theme as legacyTheme } from '@headway/ui/theme';

import { BannersContext } from 'layouts/utils';
import { calculateBannerHeight } from 'layouts/utils';
import { useAuthStore } from 'stores/AuthStore';
import { useUiStore } from 'stores/UiStore';
import { isAdminImpersonatingProviderUser } from 'utils/access';
import { redirectToAuth0Logout, useAuth0Client } from 'utils/auth';

import { AccountMenu } from './AccountMenu';

interface HeaderProps extends HeaderWrapperProps {
  UiStore?: any;
  bannerOffset?: number;
  onLogout: () => void;
}

interface HeaderState {
  isLoading: boolean;
  providers: any[];
  selectedProviderId: number;
}

const HeaderImpl = inject('AuthStore')(
  observer(
    class HeaderImpl extends React.Component<HeaderProps, HeaderState> {
      render() {
        return (
          <div
            css={{
              position: 'fixed',
              background: isAdminImpersonatingProviderUser(
                this.props.AuthStore.user,
                this.props.AuthStore.impersonatingUser
              )
                ? legacyTheme.color.danger
                : legacyTheme.color.white,
              padding: `0 ${legacyTheme.space.base}`,
              height: 50,
              top: 0,
              marginTop: this.props.bannerOffset,
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr max-content 1fr',
              gridTemplateRows: '100%',
              alignItems: 'center',
              zIndex: theme.layers.base + 1,
              borderBottom: `1px solid ${legacyTheme.color.border}`,
            }}
          >
            <div>
              {this.props.startComponent || (
                <Link
                  to="/"
                  css={{
                    alignItems: 'center',
                    display: 'flex',
                    width: 125,
                    [legacyTheme.media.small]: {
                      display: 'none',
                    },
                  }}
                >
                  <HeadwayLogo text="Headway" />
                </Link>
              )}
            </div>
            {!!this.props.providerQuestionnaires?.length &&
            isAdminImpersonatingProviderUser(
              this.props.AuthStore.user,
              this.props.AuthStore.impersonatingUser
            ) ? (
              <div>
                <FormControl fullWidth>
                  <Select
                    value={this.props.selectedQuestionnaire?.id || ''}
                    label="Select Intake Form"
                    onChange={(e) => {
                      const selectedQuestionnaire =
                        this.props.providerQuestionnaires?.find(
                          (pq) => pq.id === e.target.value
                        );
                      // This should always be true because the questionnaire options are
                      // coming directly from this.props.providerQuestionnaires
                      if (
                        selectedQuestionnaire &&
                        this.props.updateSelectedQuestionnaire
                      ) {
                        this.props.updateSelectedQuestionnaire(
                          selectedQuestionnaire
                        );
                      }
                    }}
                  >
                    {this.props.providerQuestionnaires?.map((pq) => (
                      <MenuItem key={pq.id} value={pq.id}>
                        {pq.completedOn
                          ? `${moment(pq.completedOn).format('MM/DD/YYYY')} - ${
                              pq.type
                            }`
                          : `Incomplete - ${pq.type}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <Link
                to="/"
                css={{
                  alignItems: 'center',
                  display: 'none',
                  [legacyTheme.media.small]: {
                    display: 'flex',
                    width: 125,
                  },
                }}
              >
                <HeadwayLogo text="Headway" />
              </Link>
            )}
            <div
              css={{ display: 'flex', justifyContent: 'end', gridColumn: 3 }}
            >
              {this.props.AuthStore.user ? (
                <React.Fragment>
                  <AccountMenu
                    user={this.props.AuthStore.user}
                    impersonatingUser={this.props.AuthStore.impersonatingUser}
                    provider={this.props.AuthStore.provider}
                    logout={this.props.onLogout}
                  />
                  {this.props.handleDrawerToggle ? (
                    <IconButton
                      onClick={this.props.handleDrawerToggle}
                      css={{
                        display: 'block',
                        [legacyTheme.media.medium]: { display: 'none' },
                      }}
                      size="large"
                    >
                      <MenuTwoTone
                        css={{ fontSize: legacyTheme.fontSize.xl }}
                      />
                      <VisuallyHidden>Menu</VisuallyHidden>
                    </IconButton>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
          </div>
        );
      }
    }
  )
);

interface HeaderWrapperProps {
  AuthStore?: any;
  handleDrawerToggle?: () => null;
  startComponent?: React.ReactNode;
  providerQuestionnaires?: ProviderQuestionnaireReadV2[];
  selectedQuestionnaire?: ProviderQuestionnaireReadV2;
  updateSelectedQuestionnaire?: (
    selectedQuestionnaire: ProviderQuestionnaireReadV2
  ) => void;
}

export const Header: React.FC<HeaderWrapperProps> = (props) => {
  const uiStore = useUiStore();
  const numBanners: number = useContext(BannersContext);
  const bannerOffset = calculateBannerHeight(numBanners);

  const auth0Client = useAuth0Client();
  const authStore = useAuthStore();

  return (
    <HeaderImpl
      {...props}
      UiStore={uiStore}
      bannerOffset={bannerOffset}
      onLogout={async () => {
        if (
          isAdminImpersonatingProviderUser(
            authStore.user,
            authStore.impersonatingUser
          )
        ) {
          await AuthApi.logout().finally(async () => {
            await redirectToAuth0Logout(auth0Client);
          });
        } else {
          await redirectToAuth0Logout(auth0Client);
        }
      }}
    />
  );
};
